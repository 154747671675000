import axios from "axios";
import { utils } from "ethers";
import erc20ImgLogo from '../erc20_logo.png';
import testTokenList from '../testTokenList.json';
import { NETWORK } from "../constants";
const DEFAULT_SYMBOL = 'Symbol not available';
const DEFAULT_NAME = 'No name available';
const DEFAULT_TOKENID_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/NFT_Icon.png/640px-NFT_Icon.png";

const { ethers } = require("ethers");

export const fetchSmartVaultDetail = ( 
    vaultId, 
    tx, 
    readContracts, 
    setVaultInfoStatus,
    setVaultInfoBalance,
    setVaultInfoReward,
    setVaultInfoImage,
    setVaultInfoERC20,
    setVaultInfoTrustee,
    setVaultInfoIncentive,
    setVaultInfoBeneficiary,
    setVaultInfoLockDays,
    setVaultInfoModel,
    setVaultInfoNFT,
    selectedChainId,
    signer
    ) => {

  const erc20Abi = [
    "function name() public view returns (string memory)",
    "function symbol() public view returns (string memory)",
  ];

  const getErc20TokenLogo = async(erc20TokenList) => {
    const tokens = [];

    for (const tokenAddress of erc20TokenList) {
      const selectedItem = testTokenList.tokens.find(item => item.address === tokenAddress);
      // let name = DEFAULT_NAME;
      // let symbol = DEFAULT_SYMBOL;
      let contract = new ethers.Contract(tokenAddress, erc20Abi, signer);
      let name = await contract.name();
      let symbol = await contract.symbol();
      let logo = erc20ImgLogo;
  
      if (selectedItem) {
        // name = selectedItem.name;
        // symbol = selectedItem.symbol;
        logo = selectedItem.logoURI;
      };

      tokens.push({
        address: tokenAddress,
        name,
        symbol,
        logo
      })
    }
    setVaultInfoERC20(tokens);
    // return tokens;
  };

  const fetchVaultState = () => {
      return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultState(vaultId))
          .then(state => {
            setVaultInfoStatus(state);
            return state;
          }));
      });
  }

  const fetchVaultBalance = () => {
      return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultRwrdInfo(vaultId))
          .then(balance => {
            setVaultInfoBalance(utils.formatEther(balance[0]));
            return balance;
          }));
      });
  }

  const fetchVaultReward = () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceSmartVault.getVaultRewards(vaultId))
          .then(reward => {
            setVaultInfoReward(utils.formatEther(reward));
            return reward;
          }));
      });
  }

  const fetchVaultImage = () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.tokenURI(vaultId))
          .then(tokenURI => {
            const base64String = tokenURI.toString().replace("data:application/json;base64,","");
            const decoded = Buffer.from(base64String, 'base64').toString();
            setVaultInfoImage(JSON.parse(decoded).image);
            return tokenURI;
          })
        );
      });
  }

  const fetchVaultInfo = async () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultInfo(vaultId))
          .then(result => {
            // setVaultInfoERC20(getErc20TokenLogo(result[0]));
            getErc20TokenLogo(result[0]);
            
            const trustees = result[1].map((item) => {
              return {
                  address: item,
              }
            });
            setVaultInfoTrustee(trustees);
            setVaultInfoLockDays(result[3].toNumber());
            setVaultInfoModel(result[4].toNumber());
            return result;
          })
        );
      });
  }

  const fillNFTList = async (nftList) => {
    const filledNFTList = [];
    for (const item of nftList){
      await axios.get(`https://deep-index.moralis.io/api/v2/nft/${item.address.split("_")[0]}/${item.tokenId}?chain=${NETWORK(selectedChainId).name}&format=decimal`, {
      headers: {
        'accept': 'application/json',
        'X-API-key': process.env.REACT_APP_MORALIS_KEY
      }
    })
    .then((res) => {
      let tokenIdName = DEFAULT_NAME;
      let tokenIdImage = DEFAULT_TOKENID_IMAGE;
      const metadata = JSON.parse(res.data.metadata);
      if (!!metadata){
        !!metadata.name ? tokenIdName = metadata.name : null;

        if(!!metadata.image && metadata.image.includes("ipfs://")) {
          if (metadata.image.includes("ipfs://ipfs/")) {
            tokenIdImage = metadata.image.replace("ipfs://ipfs/", "https://ipfs.io/ipfs/");
          } else {
            tokenIdImage = metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
          }
        } else {
          tokenIdImage = metadata.image;
        }
      }
      filledNFTList.push({
        ...item,
        name: tokenIdName,
        image: tokenIdImage,
        parentSymbol: res.data.symbol
      });
    })
    .catch((error) => {
      filledNFTList.push({
        ...item,
        name: DEFAULT_NAME,
        image: DEFAULT_TOKENID_IMAGE,
        parentSymbol: DEFAULT_SYMBOL
      });
      console.error(error);
    });
  }
    setVaultInfoNFT(filledNFTList);
    return filledNFTList ;
  };

  const fetchVaultsNFT = () => {
    return new Promise((resolve) => {
        
        resolve(tx(readContracts.CarapaceStorage.getNonFungibleAssets(vaultId)).then(result => {
          const erc721Tokens = result[0].map((item,index) => {
            return {
                //address: item + '_' + result[1][index],
                address: item,
                type: 'tokenId',
                tokenId: result[1][index].toString(),
                beneficiary: result[2][index],
            }
          });
          return fillNFTList(erc721Tokens);   
        }));
     
      });
  }

  const fetchTrusteeIncentive = () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultTrusteeIncentive(vaultId))
          .then(incentive => {
            setVaultInfoIncentive(incentive.toNumber());
            return incentive;
          }));
      });
  }

  const fetchBeneficiaries = () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getBeneficiaries(vaultId))
          .then(beneficiary => {
            const beneficiaries = beneficiary[0].map((item,index) => {
              return {
                  address: item,
                  percentage: beneficiary[1][index].toNumber()
              }
            });
            setVaultInfoBeneficiary(beneficiaries);
            return beneficiary;
          }));
      });
  }

  const fetchOwner = () => {
    return new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultOwner(vaultId))
          .then(owner => {
            // setVaultInfoOwner(owner);
            return owner;
          }));
      });
  }

  let statePromise = fetchVaultState();
  let balancePromise = fetchVaultBalance();
  let rewardPromise = fetchVaultReward();
  let imagePromise = fetchVaultImage();
  let vaultInfoPromise = fetchVaultInfo();
  let nftPromise = fetchVaultsNFT();
  let incentivePromise = fetchTrusteeIncentive();
  let beneficiariesPromise = fetchBeneficiaries();
  let ownerPromise = fetchOwner();

  return {
    state: wrapPromise(statePromise),
    balance: wrapPromise(balancePromise),
    reward: wrapPromise(rewardPromise),
    image: wrapPromise(imagePromise),
    vaultInfo: wrapPromise(vaultInfoPromise),
    nft: wrapPromise(nftPromise),
    trusteeIncentive: wrapPromise(incentivePromise),
    beneficiaries: wrapPromise(beneficiariesPromise),
    owner: wrapPromise(ownerPromise)
  };
};

function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}
