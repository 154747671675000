import "antd/dist/antd.css";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Alert } from 'antd';
import "./App.css";
import { ThemeSwitch, Header, NetworkSwitch, CreateSteps, MySmartVaults, TrustedSmartVaults, ReceiveAssets, Contract, Home } from "./components";
import { Web3Context } from "./context/web3Details";
import { NETWORK } from "./constants";


const NETWORKCHECK = true;

function App(props) {

  const { 
    selectedChainId,
    address,
    signer,
    price,
    localChainId,
    targetNetwork,
    logoutOfWeb3Modal,
    networkOptions,
    selectedNetwork,
    setSelectedNetwork,
    localProvider,
    loadWeb3Modal,
    web3Modal,
    contractConfig
    } 
    = useContext(Web3Context);

  const [banner, setBanner] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setBanner(false);
  //   }, 30000);
  
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="App">
      {/* <NetworkDisplay
        NETWORKCHECK={NETWORKCHECK}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
      /> */}
    {/* <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 10}}> */}
      <Header 
        address={address}
        networkOptions={networkOptions}
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        localProvider={localProvider}
        signer={signer}
        price={price}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        blockExplorer={''}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
      />
    {/* </div> */}

    <div className="body-content">
      <Switch>
        <Route exact path="/" >
          {/* <Home 
            address={address}
            loadWeb3Modal={loadWeb3Modal}
          /> */}
          {address 
          ? <Redirect to="/mysmartvaults" />
          : <Home loadWeb3Modal={loadWeb3Modal} />}
        </Route>
        <Route  path="/createsmartvault">
          <CreateSteps />
        </Route>
        <Route path="/mysmartvaults" >
          <MySmartVaults />
        </Route>
        <Route path="/trustedsmartvaults">
          <TrustedSmartVaults />
            {/* <Contract
              name="CarapaceSmartVault"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            />
            <Contract
              name="CarapaceStorage"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            />
            <Contract
              name="CarapaceDeposit"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            />
            <Contract
              name="CarapaceEscrow"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            />
            <Contract
              name="CarapaceTreasury"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            />
            <Contract
              name="CarapaceAccess"
              price={price}
              signer={signer}
              provider={localProvider}
              address={address}
              contractConfig={contractConfig}
            /> */}
        </Route>
        <Route path="/receive" >
          <ReceiveAssets />
        </Route>
      </Switch>
    </div>
    {/* { banner && 
      <div style={{
        left: 0,
        bottom: 0,
        width: '100%',
        textAlign: 'center'
      }}>
        <br/>
        <br/>
        <br/>
        <Alert 
          type="warning" 
          message="Carapace App is on Beta Version."
          description="It has not yet been audited, please use it at your own risk."
          banner 
          closable 
        />
      </div>
    } */}
      <ThemeSwitch />

      {/* <div style={{ position: "fixed", textAlign: "right", right: 0, top: 0, padding: 10 }}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <div style={{ marginRight: 30 }}>
            <NetworkSwitch
              networkOptions={networkOptions}
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={setSelectedNetwork}
            />
          </div>
          <div style={{ marginBottom: 5, marginRight: 10   }}>
            <Account
              address={address}
              localProvider={localProvider}
              signer={signer}
//              mainnetProvider={mainnetProvider}
              price={price}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              blockExplorer={''}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default App;
