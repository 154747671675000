export const fetchSmartVaultTrustee = ( vaultsOfTrustee, tx, readContracts ) => {

  const fetchVaultsState = () => {
    let vaultState = [];
    vaultsOfTrustee.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultState(item)));
      });
      vaultState.push(promise);
    });
    return Promise.all(vaultState)
  }

  const fetchVaultsBalance = () => {
    let vaultsBalance = [];
    vaultsOfTrustee.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultRwrdInfo(item)));
      });
      vaultsBalance.push(promise);
    });
    return Promise.all(vaultsBalance)
  }

  const fetchVaultsReward = () => {
    let vaultsReward = [];
    vaultsOfTrustee.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceSmartVault.getVaultRewards(item)));
      });
      vaultsReward.push(promise);
    });
    return Promise.all(vaultsReward)
  }

  const fetchVaultsImage = () => {
    let vaultsImage = [];
    vaultsOfTrustee.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.tokenURI(item)));
      });
      vaultsImage.push(promise);
    });
    return Promise.all(vaultsImage)
  }

  const fetchVaultsOwner = () => {
    let vaultsOwner = [];
    vaultsOfTrustee.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultOwner(item)));
      });
      vaultsOwner.push(promise);
    });
    return Promise.all(vaultsOwner)
  }

  let statePromise = fetchVaultsState();
  let balancePromise = fetchVaultsBalance();
  let rewardPromise = fetchVaultsReward();
  let imagePromise = fetchVaultsImage();
  let ownerPromise = fetchVaultsOwner();

  return {
    states: wrapPromise(statePromise),
    balance: wrapPromise(balancePromise),
    reward: wrapPromise(rewardPromise),
    image: wrapPromise(imagePromise),
    owner: wrapPromise(ownerPromise)
  };
};

function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}
