import { utils } from "ethers";
import { NETWORK } from "../constants";
import { getNFTDetail } from "../helpers/getNFTDetail";

export const fetchReceiveAssets = ( vaultsOfBeneficiary, tx, readContracts, address, selectedChainId, setAddress ) => {

  const fillBalanceDetails = async (vaultId, balance) => {
    const owner = await tx(readContracts.CarapaceStorage.getSmartVaultOwner(vaultId));
    
    const tokenURI = await tx(readContracts.CarapaceStorage.tokenURI(vaultId));
    const base64String = tokenURI.toString().replace("data:application/json;base64,","");
    const decoded = Buffer.from(base64String, 'base64').toString()
    return {
     vaultNFT: { image: JSON.parse(decoded).image, vaultId },
     owner: owner,
     name: vaultId,
     balance: parseFloat(utils.formatEther(balance)).toFixed(6),
     balanceBigInt: balance
    };
  };

  const fetchVaultsBalance = () => {
    let vaultsBalance = [];
    vaultsOfBeneficiary.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceSmartVault.getVaultBeneficiaryFunds(item, address)).then(balance => {
          if (balance > 0) {
            
            return fillBalanceDetails(item, balance);
          } else {
            return {
              logo: '',
              symbol: '',
              name: item,
              balance: 0
             };
          }
        }));
      });
      vaultsBalance.push(promise);
    });
    return Promise.all(vaultsBalance)
  }

  const fillERC20Details = async (vaultId, owner, decoded, tokenList) => {
    let vaultTokens = [];
    for (const token of tokenList) {
      
      const balance = await tx(readContracts.CarapaceStorage.getFungibleAssetsBalanceOfBenef(vaultId, address, token));

      if (balance > 0) {
        vaultTokens.push({
          token: token,
          logo: '',
          vaultNFT: { type: 'vaultNFT', image: JSON.parse(decoded).image, vaultId },
          owner,
          name: vaultId,
          balance: BigInt(balance)
          });
      }
    };
    return vaultTokens;
  };

  const fetchVaultsERC20 = async () => {
    let vaultsERC20 = [];
    
    for (const vaultId of vaultsOfBeneficiary) {

      const owner = await tx(readContracts.CarapaceStorage.getSmartVaultOwner(vaultId));
      const tokenURI = await tx(readContracts.CarapaceStorage.tokenURI(vaultId));
      const base64String = tokenURI.toString().replace("data:application/json;base64,","");
      const decoded = Buffer.from(base64String, 'base64').toString();

      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultInfo(vaultId)).then(result => {
          return fillERC20Details(vaultId, owner, decoded, result[0]);
        }));
      });
      vaultsERC20.push(promise);
    };
    return Promise.all(vaultsERC20)
  }

  const getBeneficiaryNFT = async (result, vaultId) => {
    let nftDetail = [];
    for (let i = 0; i < result[2].length; i++) {
      if (result[2][i] === address) {
        const args = [
          address,
          [ vaultId, result[0][i], result[1][i] ],
        ];
        const nftAvailableToWithdraw = 
          await tx(readContracts.CarapaceStorage.getSmartVaultBeneficiaryNonFungibleAsset(...args));
        if (nftAvailableToWithdraw) {
          const resp = await getNFTDetail(NETWORK(selectedChainId).name, result[0][i], result[1][i].toString());
          nftDetail.push({
            vaultId,
            address: result[0][i],
            tokenId: result[1][i].toString(),
            owner: resp.owner,
            symbol: resp.symbol,
            name: resp.name,
            image: resp.image,
            isChecked: false,
          }) 
        }  
      }
    }
    return nftDetail;
  }

  const fetchVaultsNFT = () => {
    let vaultsNFT = [];
    vaultsOfBeneficiary.forEach( item => {
      const promise = new Promise((resolve) => {
        
        resolve(tx(readContracts.CarapaceStorage.getNonFungibleAssets(item)).then(result => {
          return getBeneficiaryNFT(result, item);   
        }));
      });
      vaultsNFT.push(promise);
    });
    return Promise.all(vaultsNFT);
  }

  let balancePromise = fetchVaultsBalance();
  let erc20Promise = fetchVaultsERC20();
  let nftPromise = fetchVaultsNFT();

  return {
    fetchAddress: setAddress(address),
    balance: wrapPromise(balancePromise),
    erc20: wrapPromise(erc20Promise),
    nft: wrapPromise(nftPromise)
  };
};

function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}
