import React from "react";
import { Card, Checkbox, Divider, Typography, Image } from "antd";
import "../App.css";
const { Meta } = Card;
const { Text } = Typography;

export default function NFTCard(
  { address, tokenId, vaultId, title, description, additional, src, selector, selectorText, selectorOnChange, isChecked, isAlreadyUsed}) {
  return (
    <Card
      style={{ width: 250 }}
      hoverable
      cover={<Image height={250} alt={title} style={{ height: "100%" }} src={src} />}>  
      <Meta
        style={{flex:1, textAlign: 'left'}}
        title={title}
        description={description}
      />
      <div style={{textAlign: 'left', marginTop:10}}>
        {additional}
      </div>
      { selector && 
        <div style={{textAlign: 'left'}}>
          <Divider />
          <Checkbox 
            defaultChecked={isChecked} 
            checked={isChecked}
            disabled={isAlreadyUsed} 
            onChange={(event) => selectorOnChange(event, vaultId, address, tokenId )}>
          <Text strong>{selectorText}</Text></Checkbox>
        </div>
      }
    </Card>
  );
}
