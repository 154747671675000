import React, { useState, useEffect, Suspense, useContext } from "react";
import { Steps, Button, notification, Spin, Typography, Result, Skeleton, Tooltip, Avatar } from 'antd';
import { ScanAssets, CustomizeSmartVault, ApproveTokens, UpdateSmartVault, CustomizeContacts, SmartVaultCard } from ".";
import { useHistory, useParams, Link } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { fetchSmartVaultInfo }  from "../helpers/fetchSmartVaultInfo";
import testTokenList from '../testTokenList.json';
import erc20ImgLogo from '../erc20_logo.png';
import { NETWORK } from "../constants";
import { checkBridgeConnection } from "../helpers/checkConnectionType";
import { utils } from "ethers";
import "../App.css";
import { Web3Context } from "../context/web3Details";
import { ScreenSize } from "../context/screenSize";
import { functions, db } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";

const { Text } = Typography;

const DEFAULT_SYMBOL = 'Symbol not available';
const DEFAULT_NAME = 'No name available';

export default function EditSteps(
{  owner,
  infoStatus ,
  infoBalance,
  infoReward,
  infoImage ,
  infoERC20,
  infoTrustee,
  infoIncentive,
  infoBeneficiary,
  infoLockDays,
  infoNFT,
  infoModel
}

  ) {

  const { selectedChainId, address, tx, writeContracts, readContracts, signer, activateRefresh, mainnetProvider, selectedNetwork } = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);
  let { vaultId } = useParams();
  const [current, setCurrent] = React.useState(0);
  const [allApproved, setAllApproved] = useState(false);
  const [allFilled, setAllFilled] = useState(false);
  const [ERC20Assets, setERC20Assets] = useState([]);
  const [selectedERC20, setSelectedERC20] = useState([]);
  const [ERC721Assets, setERC721Assets] = useState([]);
  const [selectedERC721, setSelectedERC721] = useState([]);  
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [trustees, setTrustees] = useState([]);
  const [trusteeIncentive, setTrusteeIncentive] = useState(0);
  const [lockDays, setLockDays] = useState(0);
  const [nftCount, setNFTSelected] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [updated, setUpdated] = useState(false);
  const [resource, setResource] = useState(null);

  const [ownerContacts, setOwnerContacts] = useState("");
  const [beneficiariesContacts, setBeneficiariesContacts] = useState([{email: ""}]);
  const [trusteesContacts, setTrusteesContacts] = useState([{email: ""}]);
  const [vaultOwner, setVaultOwner] = useState("");
  const [vaultStatus, setVaultStatus] = useState("");

  const { Step } = Steps;

  const [fetchERC20, setFetchERC20] = useState(false);
  const [fetchNFT, setFetchNFT] = useState(false);
  const [hasContactChanges, setHasContactChanges] = useState(false);
  const [hasContractChanges, setHasContractChanges] = useState(false);
  const history = useHistory();

  useEffect(() => {

    const getInfo = async () => {
      setLoading(true);
      setVaultOwner(owner);
      setVaultStatus(infoStatus);
      setSelectedERC20(infoERC20);
      setBeneficiaries(infoBeneficiary);
      setTrustees(infoTrustee);
      setSelectedERC721(infoNFT);
      setNFTSelected(infoNFT.length);
      setTrusteeIncentive(infoIncentive);
      setLockDays(infoLockDays);
      const initialStateInfo = {
                        erc20Tokens: infoERC20, 
                        beneficiaries: infoBeneficiary.map(item => [item.address, item.percentage]),
                        trustees: infoTrustee.map(item => item.address),
                        erc721Tokens: infoNFT,
                        trusteeIncentive: infoIncentive,
                        lockDays: infoLockDays
                      };
      // console.log("useEffect.initialState", initialStateInfo);
      if(infoBalance === 0) {
        history.push(`/mysmartvaults/${vaultId}`);
      }
      setInitialState(initialStateInfo);
      getContacts(owner);
      setLoading(false);
      } 

      getInfo();
  }, []);


  const doneFetchERC20 = (bool) => {
    setFetchERC20(bool);
  };

  const doneFetchNFT = (bool) => {
    setFetchNFT(bool);
  };

  const scannedERC20 = (assets) => {
    setERC20Assets(assets);
  };

  const scannedERC721 = (assets) => {
    setERC721Assets(assets);
  };

  const selectedERC20Assets = (assets) => {
    setSelectedERC20(assets);
  };

  const selectedERC721Assets = (assets) => {
    setSelectedERC721(assets);
  };

  const selectedBeneficiaries = (value) => {
    setBeneficiaries(value);
  };

  const selectedTrustees = (value) => {
    setTrustees(value);
  };

  const selectedTrusteeIncentive = (value) => {
    setTrusteeIncentive(value);
  };

  const selectedLockDays = (value) => {
    setLockDays(value);
  };

  const checkAllApproved = (bool) => {
    setAllApproved(bool);
  };

  const checkAllFilled = (bool) => {
    setAllFilled(bool);
  };

  const nftSelection = (count) => {
    setNFTSelected(nftCount+count);
  };

  const selectedOwnerContacts = (email) => {
    setOwnerContacts(email);
  }

  const selectedBeneficiariesContacts = (email) => {
    setBeneficiariesContacts(email);
  }

  const selectedTrusteesContacts = (email) => {
    setTrusteesContacts(email);
  }

  const checkContacts = (bool) => {
    setContactsChecked(bool);
  };

  const [contactsChecked, setContactsChecked] = useState(true);

  const scanContent = <ScanAssets
                        erc20Assets={ERC20Assets}
                        erc721Assets={ERC721Assets}
                        scannedERC20={scannedERC20}
                        scannedERC721={scannedERC721}
                        selectedERC20Assets={selectedERC20Assets}
                        selectedERC721Assets={selectedERC721Assets}
                        selectedERC20={selectedERC20}
                        selectedERC721={selectedERC721}
                        vaultEdited={vaultId}
                        nftSelection={nftSelection}
                        fetchERC20={fetchERC20}
                        fetchNFT={fetchNFT}
                        doneFetchERC20={doneFetchERC20}
                        doneFetchNFT={doneFetchNFT}
                      />;

  const customizeContent =  <CustomizeSmartVault 
                              beneficiaries={beneficiaries} 
                              selectedBeneficiaries={selectedBeneficiaries} 
                              trustees={trustees} 
                              selectedTrustees={selectedTrustees}
                              trusteeIncentive={trusteeIncentive}
                              selectedTrusteeIncentive={selectedTrusteeIncentive}
                              lockDays={lockDays}
                              selectedLockDays={selectedLockDays}
                              selectedERC20={selectedERC20} 
                              selectedERC721={selectedERC721}
                              selectedERC721Assets={selectedERC721Assets} 
                              checkAllFilled={checkAllFilled}
                              mainnetProvider={mainnetProvider}
                              infoModel={infoModel}
                              />;

  const approveContent = <ApproveTokens
                              address={address}       
                              selectedERC20={selectedERC20}
                              selectedERC721={selectedERC721}
                              checkAllApproved={checkAllApproved}
                              selectedERC20Assets={selectedERC20Assets} 
                              selectedERC721Assets={selectedERC721Assets} 
                              readContracts={readContracts}
                              signer={signer}
                          />;

  const customizeContacts = <CustomizeContacts
                            ownerContacts={ownerContacts}
                            selectedOwnerContacts={selectedOwnerContacts}
                            beneficiariesContacts={beneficiariesContacts}
                            selectedBeneficiariesContacts={selectedBeneficiariesContacts}
                            trusteesContacts={trusteesContacts}
                            selectedTrusteesContacts={selectedTrusteesContacts}
                            checkContacts={checkContacts}
                          />;

  const updateContent = <UpdateSmartVault       
                              selectedERC20={selectedERC20}
                              selectedERC721={selectedERC721}
                              beneficiaries={beneficiaries}
                              trustees={trustees}
                              trusteeIncentive={trusteeIncentive}
                              lockDays={lockDays}
                          />;

  const updatedContent = <div className="ant-div-carapace">
                          <Result
                            status="success"
                            icon={<CheckCircleOutlined />}
                            title={`Smart Vault #${vaultId} successfully updated!`}
                            subTitle="Your selected assets are protected by Carapace Protocol."
                            extra={[
                              <div key={vaultId}>
                                <ShowSmartVault />
                                <Link to="/mysmartvaults">Manage all Smart Vaults
                                  {/* <Button type="primary" size="large" key={vaultId} icon={<SettingOutlined />}>Go Manage</Button> */}
                                </Link>
                              </div>
                              ]}
                            />
                        </div>;

  const steps = [
    {
      id: 0,
      title: 'Scan',
      content: scanContent,
    },
    {
      id: 1,
      title: 'Customize',
      content: customizeContent
    },
    {
      id: 2,
      title: 'Approve',
      content: approveContent,
    },
    {
      id: 3,
      title: 'Contacts',
      content: customizeContacts,
    },
    {
      id: 4,
      title: 'Update',
      content: !updated ? updateContent : updatedContent,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const checkChanges = () => {
    setCurrent(current + 1);
    checkContractChanges(); 
    checkContactsChanges();
  };

  const isSelected = (item) => {
    return item.isChecked
  };

  const customizeStep = () => {
    if(ERC721Assets.length !== 0) {
      const selectedNFTs = ERC721Assets.filter(isSelected);
      setSelectedERC721(selectedNFTs);
    }
    setCurrent(current + 1);
  };

  const update = async () => {
    if(hasContractChanges) {
      setLoadingUpdate(true);
      checkBridgeConnection(signer);
      // console.log("args.selectedERC20", selectedERC20);
      const args = [
        vaultId,
        selectedERC20.map(item => item.address), // Array tokens ERC20
        beneficiaries.map(item => [item.address, item.percentage]), // Array beneficiaries [address, percentage]
        //selectedERC721.map(item => [item.address.split("_")[0], item.tokenId, item.beneficiary]),  // Array NFTs [NFT address, tokenId, beneficiary address ]
        selectedERC721.map(item => [item.address, item.tokenId, item.beneficiary]),  // Array NFTs [NFT address, tokenId, beneficiary address ]
        trustees.map(item => item.address),  // Array trustees address
        trusteeIncentive,
        lockDays
      ];

      // console.log("....args....", args);

      const result = await tx(writeContracts.CarapaceSmartVault.editSmartVault(...args));

      if (result) {
        await result.wait();
        setResource(fetchSmartVaultInfo([vaultId], tx, readContracts, selectedChainId));
        setUpdated(true);
        activateRefresh(true);
        // message.success(`Vault updated! View tx on block explorer ${NETWORK(selectedChainId).blockExplorer}tx/${result.hash}`);
        
      } else { 
        // message.error(`Error updating Vault!`);
        notification.error({
          message: "Update vault",
          description: "Error updating Vault"
        })
      }
      setLoadingUpdate(false);
    } // else console.log("Nothing to update on blockchain");
  };

  const getContacts = async (vaultOwner) => {
    // console.log("EditSteps getContacts VaultOwner", address, vaultOwner);
    if(address === vaultOwner) {
      const docRef = doc(db, selectedNetwork, vaultId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setOwnerContacts(docSnap.data().owner);
        
        let benefLoadContacts = [];
        if(docSnap.data().beneficiaries === "") {
          benefLoadContacts = [{email: ""}];
        } else
        docSnap.data().beneficiaries.split(';').slice(0, -1).forEach(item => {
          benefLoadContacts.push({email: item});
        });
        setBeneficiariesContacts(benefLoadContacts);

        let trusteeLoadContacts = [];
        if(docSnap.data().trustees === "") {
          trusteeLoadContacts = [{email: ""}];
        } else
        docSnap.data().trustees.split(';').slice(0, -1).forEach(item => {
          trusteeLoadContacts.push({email: item});
        });
        setTrusteesContacts(trusteeLoadContacts);
        
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } else 
      notification.error({
        message: <Text>Error loading contacts</Text>,
        description: <Text>Not the owner</Text>
      })
  } 

  const checkContactsChanges = async () => {
    const docRef = doc(db, selectedNetwork, vaultId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {

      let benefLoadContacts = [];
      if(docSnap.data().beneficiaries === "") {
        benefLoadContacts = [{email: ""}];
      } else
      docSnap.data().beneficiaries.split(';').slice(0, -1).forEach(item => {
        benefLoadContacts.push({email: item});
      });

      let trusteeLoadContacts = [];
      if(docSnap.data().trustees === "") {
        trusteeLoadContacts = [{email: ""}];
      } else
      docSnap.data().trustees.split(';').slice(0, -1).forEach(item => {
        trusteeLoadContacts.push({email: item});
      });

      if(JSON.stringify(docSnap.data().owner) !== JSON.stringify(ownerContacts) ||
          JSON.stringify(benefLoadContacts) !== JSON.stringify(beneficiariesContacts) ||
          JSON.stringify(trusteeLoadContacts) !== JSON.stringify(trusteesContacts)
        ) {
          setHasContactChanges(true);
        } else {
          setHasContactChanges(false);
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such vault ID!");
    }
  } 

  const checkContractChanges = () => {

    if(JSON.stringify(initialState.erc20Tokens.map(item => item.address)) !== JSON.stringify(selectedERC20.map(item => item.address)) ||
      JSON.stringify(initialState.beneficiaries) !== JSON.stringify(beneficiaries.map(item => [item.address, item.percentage])) ||
      JSON.stringify(initialState.erc721Tokens.map(item => [item.address, item.tokenId, item.beneficiary])) !== JSON.stringify(selectedERC721.map(item => [item.address, item.tokenId, item.beneficiary])) ||
      JSON.stringify(initialState.trustees) !== JSON.stringify(trustees.map(item => item.address)) ||
      initialState.trusteeIncentive !== trusteeIncentive ||
      initialState.lockDays !== lockDays
    ) {
      setHasContractChanges(true);
      } else {
      setHasContractChanges(false);
    }
  } 

  const submitContacts = () => {
    if(hasContactChanges) {
      // console.log("Updating Vault Contacts:", selectedNetwork, vaultId, vaultOwner, vaultStatus.toString());
      if(address === vaultOwner){
        if(!hasContractChanges) {
          setLoadingUpdate(true);
        }

        // need to show Smart Vault Card after update
        setResource(fetchSmartVaultInfo([vaultId], tx, readContracts, selectedChainId));

        let beneficiariesContactsList = "";
        let trusteesContactsList = "";
        
        beneficiariesContacts.forEach(element => {
          if(element.email)
            beneficiariesContactsList += element.email + ";";
        });

        trusteesContacts.forEach(element => {
          if(element.email)
            trusteesContactsList += element.email + ";";
        });

        const addVaultContact = httpsCallable(functions, 'addContact');
          
        addVaultContact({
          collection: selectedNetwork, 
          vaultid: vaultId,
          owner: ownerContacts,
          beneficiaries: beneficiariesContactsList,
          trustees: trusteesContactsList,
          status: vaultStatus.toString()
          })
              .then((response) => {
                const { result, error } = response.data;
                if (result) {
                  // console.log('Contacts successfully submitted');
                  if(!hasContractChanges) {
                    setUpdated(true);
                  }
                } else
                  // message.error("error: contacts not submited");
                  notification.error({
                    message: "Error submitting contacts",
                    description: "Contacts not submitted"
                  })
              })
              .catch((error) => {
                  // console.error("Error adding contact: ", error);
                  // message.error('error');
                  notification.error({
                    message: "Error submitting contacts",
                    description: error
                  })
              });
        // if(!hasContractChanges) {
        //   console.log("editSteps.updateContacts.noContractChanges.setNotLoading");
        //   setLoadingUpdate(false);
        // }
      } else // message.error("Contacts not updated: only owner");
        notification.error({
          message: "Error submitting contacts",
          description: "Contacts not submitted: only owner"
        })
    } // else console.log("Nothing to update on contacts");
  }

  const stepDescription = (id, current) => {
    if(id === 0 && current === 0) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Edit the assets to safeguard</Text>
        </div>)
    }
    if(id === 1 && current === 1) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Edit beneficiary and trustee addresses</Text>
        </div>)
    }
    if(id === 2 && current === 2) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Allow asset protection on your behalf</Text>
        </div>)
    }
    if(id === 3 && current === 3) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Optionally add info to get notifications</Text>
        </div>)
    }
    if(id === steps.length - 1 && current === steps.length - 1) {
      return (
        <div>
          <Text type='secondary'>Review and submit changes to vault</Text>
        </div>)
    }
  }

  const stepCreatedStatus = (current) => {
    if(current == steps.length -1 && updated)
      return "finish";
  }

  const formatBalance = (balance) => {
    if (balance == 0){
      return balance;
    } else {
      const decimal = balance.split('.');
      if (decimal[0] != 0){
        return parseFloat(balance).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(balance).toFixed(fixedDecimals);
      }
    }
  }

  function ShowBalance({index}) {
    const balance = resource?.balance.read();
    return <Text type='primary'>{formatBalance(utils.formatEther(balance[index][0]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  const formatReward = (reward) => {
    if (reward == 0){
      return reward;
    } else {
      const decimal = reward.split('.');
      if (decimal[0] != 0){
        return parseFloat(reward).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(reward).toFixed(fixedDecimals);
      }
    }
  }

  function ShowReward({index}) {
    const reward = resource?.reward.read();
    return <Text type='primary'>{formatReward(utils.formatEther(reward[index]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  function ShowImage({index}) {
    let imageUrl = '';
    const image = resource?.image.read();

    if(!!image) {
      const base64String = image[index].toString().replace("data:application/json;base64,","");
      const decoded = Buffer.from(base64String, 'base64').toString();
      imageUrl = JSON.parse(decoded).image;
    }
    return <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>;
  }

  const handleImgError = () => {
    return true;
  };

  const getErc20TokenLogo = (erc20TokenList) => {
    const tokens = [];

    for (const tokenAddress of erc20TokenList) {
      const selectedItem = testTokenList.tokens.find(item => item.address === tokenAddress);
      let name = DEFAULT_NAME;
      let symbol = DEFAULT_SYMBOL;
      let logo = erc20ImgLogo;
  
      if (selectedItem) {
        name = selectedItem.name;
        symbol = selectedItem.symbol;
        logo = selectedItem.logoURI;
      };

      tokens.push({
        address: tokenAddress,
        name,
        symbol,
        logo
      })
    }
    return tokens;
  };

  const erc20Logo = (erc20Tokens) =>  {
    return (
      <Tooltip key={erc20Tokens.address} title={erc20Tokens.symbol} placement="top">
        <Avatar src={erc20Tokens.logo} onError={handleImgError}/>
      </Tooltip>  
    );
  };

  function ShowERC20({index}) {
    const vaultInfo = resource?.vaultInfo.read();

    const erc20TokensLogos = getErc20TokenLogo(vaultInfo[index][0]);

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {erc20TokensLogos.map((subItem) => (erc20Logo(subItem)))}
      </Avatar.Group>
    )
  }

  function ShowNFT({index}) {
    const nft = resource?.nft.read();

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {nft[index].map((subItem) => <Avatar key={subItem.address} src={subItem.image} shape="square" onError={handleImgError}/>)}
      </Avatar.Group>
    )
  }

  function ShowSmartVault() {

    //const state = resource?.state.read();

    return (
      <div style={{display: "flex", justifyContent: 'center', marginTop: 40, marginBottom: 40 }}>
        <SmartVaultCard
            key={vaultId} 
            vaultId={vaultId}
            status={vaultStatus}
            url={`/mysmartvaults/${vaultId}`}
            balance={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowBalance index={0} /></Suspense>}
            reward={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowReward index={0} /></Suspense>}
            image={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowImage index={0} /></Suspense>}
            erc20={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowERC20 index={0} /></Suspense>}
            nft={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowNFT index={0} /></Suspense>}
        />
      </div>
    );
  }

  return loading ? <Spin style={{marginTop:30}} /> : (
    <>
      <div className={windowSize?.mainContainer}>
        <Steps size='small' current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} description={!windowSize.stepsBreak && stepDescription(item.id, current)} status={stepCreatedStatus(current)}/>
          ))}
        </Steps>
      </div>
      <div className={windowSize?.mainContainer}>{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && !updated && (
          <Button className="ant-button-carapace" size="large" icon={<ArrowLeftOutlined />} style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current === 0 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={selectedERC20.length === 0 && nftCount === 0} onClick={() => customizeStep()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 1 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!allFilled}
            onClick={() => next()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 2 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!allApproved} 
            onClick={() => next()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 3 && (
          <Button 
            className="ant-button-carapace" 
            size="large" 
            type="primary" 
            disabled={!contactsChecked} 
            onClick={() => checkChanges()}
          >
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === steps.length - 1 && !updated && (
          <Button 
            className="ant-button-carapace" 
            size="large" 
            type="primary"
            disabled={ !hasContactChanges && !hasContractChanges }
            loading={loadingUpdate} 
            onClick={() => {update(); submitContacts()}}
          >
            Update<UploadOutlined />
          </Button>
        )}

      </div>
      <br/>
    {/* </div> */}
    </>
    )
}
