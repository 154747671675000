import React, { useState, useContext, Suspense, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Steps, Button, message, notification, Typography, Result, Row, Col, Skeleton, Tooltip, Avatar, Space} from 'antd';
import { ScanAssets, CustomizeSmartVault, ApproveTokens, SubmitSmartVault, CustomizeContacts, SmartVaultCard} from ".";
import { utils } from "ethers";
import "../App.css";
import {  AdditionalInfo } from ".";
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { Web3Context } from "../context/web3Details";
import { ScreenSize } from "../context/screenSize";
import { fetchSmartVaultInfo }  from "../helpers/fetchSmartVaultInfo";
import testTokenList from '../testTokenList.json';
import erc20ImgLogo from '../erc20_logo.png';
import { checkBridgeConnection } from "../helpers/checkConnectionType";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, UploadOutlined, SettingOutlined, TwitterOutlined } from '@ant-design/icons';
import { NETWORK } from "../constants";
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";
const { Text } = Typography;

//let resource;
const DEFAULT_SYMBOL = 'Symbol not available';
const DEFAULT_NAME = 'No name available';

export default function CreateSteps() {

  const { address, tx, writeContracts, signer, selectedChainId, readContracts, selectedNetwork, mainnetProvider } = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);
  const { currentTheme } = useThemeSwitcher();

  const [current, setCurrent] = React.useState(0);
  const [allApproved, setAllApproved] = useState(false);
  const [allFilled, setAllFilled] = useState(false);
  const [model, setModel] = useState(1);
  const [ERC20Assets, setERC20Assets] = useState([]);
  const [ERC721Assets, setERC721Assets] = useState([]);
  const [selectedERC20, setSelectedERC20] = useState([]);
  const [selectedERC721, setSelectedERC721] = useState([]);
  const [nftCount, setNFTSelected] = useState(0);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [created, setCreated] = useState(false);
  const [resource, setResource] = useState(null);
  const [fetchERC20, setFetchERC20] = useState(false);
  const [fetchNFT, setFetchNFT] = useState(false);

  const [beneficiaries, setBeneficiaries] = useState([{
      address: '', 
      percentage: 100
      } 
      ]);

  const [trustees, setTrustees] = useState([{
      address: '',
      percentage: null
      }]);

  const [trusteeIncentive, setTrusteeIncentive] = useState(5);
  const [lockDays, setLockDays] = useState(30);
    
  const [depositAmount, setDepositAmount] = useState(''); //0.0001
  const [minDepositAmount, setMinDepositAmount] = useState(false);

  //contacts info
  const [ownerContacts, setOwnerContacts] = useState("");
  const [beneficiariesContacts, setBeneficiariesContacts] = useState([{email: ""}]);
  const [trusteesContacts, setTrusteesContacts] = useState([{email: ""}]);
  // vault info
  const [vaultOwner, setVaultOwner] = useState("");
  const [vaultId, setVaultId] = useState("");
  const [vaultStatus, setVaultStatus] = useState("");

  const { Step } = Steps;

  const setFeeModel = (value) => {
    setModel(value);
  };

  const scannedERC20 = (assets) => {
    setERC20Assets(assets);
  };

  const scannedERC721 = (assets) => {
    setERC721Assets(assets);
  };

  const selectedERC20Assets = (assets) => {
    setSelectedERC20(assets);
  };

  const selectedERC721Assets = (assets) => {
    setSelectedERC721(assets);
  };

  const selectedBeneficiaries = (value) => {
    setBeneficiaries(value);
  };

  const selectedTrustees = (value) => {
    setTrustees(value);
  };

  const selectedTrusteeIncentive = (value) => {
    setTrusteeIncentive(value);
  };

  const selectedLockDays = (value) => {
    setLockDays(value);
  };

  const checkAllApproved = (bool) => {
    setAllApproved(bool);
  };

  const checkAllFilled = (bool) => {
    setAllFilled(bool);
  };

  const updateDeposit = (amount) => {
    setDepositAmount(amount);
    if (model === 1) {
      if (amount >= NETWORK(selectedChainId).defaultPay) {
        setMinDepositAmount(true);
      } else setMinDepositAmount(false);
    } else {
      if (amount >= NETWORK(selectedChainId).minDepositAmount && amount <= NETWORK(selectedChainId).maxDepositAmount) {
        setMinDepositAmount(true);
      } else setMinDepositAmount(false);
    }
  };

  const selectedOwnerContacts = (email) => {
    setOwnerContacts(email);
  }

  const selectedBeneficiariesContacts = (email) => {
    setBeneficiariesContacts(email);
  }

  const selectedTrusteesContacts = (email) => {
    setTrusteesContacts(email);
  }

  const checkContacts = (bool) => {
    setContactsChecked(bool);
  };

  const updateVaultId = (vaultid) => {
    setVaultId(vaultid);
  };

  const updateVaultOwner = (owner) => {
    setVaultOwner(owner);
  };

  const updateVaultStatus = (status) => {
    setVaultStatus(status);
  };

  const nftSelection = (count) => {
    setNFTSelected(nftCount+count);
  };

  const doneFetchERC20 = (bool) => {
    setFetchERC20(bool);
  };

  const doneFetchNFT = (bool) => {
    setFetchNFT(bool);
  };

  const [contactsChecked, setContactsChecked] = useState(true);

  const scanContent = <ScanAssets
                        erc20Assets={ERC20Assets}
                        erc721Assets={ERC721Assets}
                        scannedERC20={scannedERC20}
                        scannedERC721={scannedERC721}
                        selectedERC20Assets={selectedERC20Assets}
                        selectedERC721Assets={selectedERC721Assets}
                        selectedERC20={selectedERC20}
                        selectedERC721={selectedERC721}
                        vaultEdited={' '}
                        nftSelection={nftSelection}
                        fetchERC20={fetchERC20}
                        fetchNFT={fetchNFT}
                        doneFetchERC20={doneFetchERC20}
                        doneFetchNFT={doneFetchNFT}
                        mainnetProvider={mainnetProvider}
                      />;

  const customizeContent =  <CustomizeSmartVault 
                              beneficiaries={beneficiaries} 
                              selectedBeneficiaries={selectedBeneficiaries} 
                              trustees={trustees} 
                              selectedTrustees={selectedTrustees}
                              trusteeIncentive={trusteeIncentive}
                              selectedTrusteeIncentive={selectedTrusteeIncentive}
                              lockDays={lockDays}
                              selectedLockDays={selectedLockDays}
                              selectedERC20={selectedERC20} 
                              selectedERC721={selectedERC721}
                              selectedERC721Assets={selectedERC721Assets} 
                              checkAllFilled={checkAllFilled}
                              mainnetProvider={mainnetProvider}
                              infoModel={' '}
                              />;

  const approveContent = <ApproveTokens       
                              selectedERC20={selectedERC20}
                              selectedERC721={selectedERC721}
                              checkAllApproved={checkAllApproved}
                              selectedERC20Assets={selectedERC20Assets} 
                              selectedERC721Assets={selectedERC721Assets}
                          />;

  const customizeContacts = <CustomizeContacts
                              ownerContacts={ownerContacts}
                              selectedOwnerContacts={selectedOwnerContacts}
                              beneficiariesContacts={beneficiariesContacts}
                              selectedBeneficiariesContacts={selectedBeneficiariesContacts}
                              trusteesContacts={trusteesContacts}
                              selectedTrusteesContacts={selectedTrusteesContacts}
                              checkContacts={checkContacts}
                            />;

  const submitContent = <SubmitSmartVault       
                              selectedERC20={selectedERC20}
                              selectedERC721={selectedERC721}
                              beneficiaries={beneficiaries}
                              trustees={trustees}
                              trusteeIncentive={trusteeIncentive}
                              selectedTrusteeIncentive={selectedTrusteeIncentive}
                              lockDays={lockDays}
                              depositAmount={depositAmount}
                              setDepositAmount={updateDeposit}
                              setModel={setFeeModel}
                              model={model}
                          /> ;

  const createdContent = <div className="ant-div-carapace">
                            <Result
                              key={vaultId}
                              status="success"
                              icon={<CheckCircleOutlined />}
                              title={`Vault successfully created with ID # ${vaultId}`}
                              subTitle="The selected assets are protected forever"
                              extra={[
                                <div key={vaultId}>
                                  <ShowSmartVault />
                                  <Space size="middle">
                                    <TwitterButton />
                                    {/* <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fapp.carapace.io%2F&text=I%20was%20testing%20%40CarapaceDapp%20and%20just%20have%20created%20a%20new%20SmartVault%20(tokenID%3A%20${vaultId})%20to%20safeguard%20my%20%23crypto%20assets%20without%20locking%20them%20nor%20sharing%20my%20private%20keys%20%F0%9F%94%90%20Can%27t%20wait%20for%20%23mainnet%20deployment%20%F0%9F%9A%80%0a%0a%20Have%20a%20try%20and%20stay%20up%20to%20date%20%F0%9F%91%89%20https%3A%2F%2Fcarapace.io`} target="_blank">
                                      <Button type="primary" size="large" icon={<TwitterOutlined />}>Twitt</Button>
                                    </a> */}
                                    and
                                    <Link to="/mysmartvaults">Go to my Smart Vaults
                                      {/* <Button type="primary" size="large" icon={<SettingOutlined />}>Go Manage</Button> */}
                                    </Link>
                                  </Space>
                                </div>
                                ]}
                              />
                          </div>;
  
  const scanTitle = () => {
    return windowSize?.stepsBreak
      ? <div style={{dispaly:"flex", alignContent: 'center'}}>
          <h1>
            Scan
            {/* <AdditionalInfo info={<p>Select your assets to safeguard</p>}/> */}
          </h1>
        </div>
      : 'Scan'

  }

  const customizeTitle = () => {
    return windowSize?.stepsBreak
      ? <div style={{dispaly:"flex", alignContent: 'center'}}>
          <h1>
            Customize
            {/* <AdditionalInfo info={<p>Select your assets to safeguard</p>}/> */}
          </h1>
        </div>
      : 'Customize'

  }

  const approveTitle = () => {
    return windowSize?.stepsBreak
      ? <div style={{dispaly:"flex", alignContent: 'center'}}>
          <h1>
            Approve
            {/* <AdditionalInfo info={<p>Select your assets to safeguard</p>}/> */}
          </h1>
        </div>
      : 'Approve'

  }

  const contactsTitle = () => {
    return windowSize?.stepsBreak
      ? <div style={{dispaly:"flex", alignContent: 'center'}}>
          <h1>
            Contacts
            {/* <AdditionalInfo info={<p>Select your assets to safeguard</p>}/> */}
          </h1>
        </div>
      : 'Contacts'

  }

  const createTitle = () => {
    return windowSize?.stepsBreak
      ? <div style={{dispaly:"flex", alignContent: 'center'}}>
          <h1>
            Create
            {/* <AdditionalInfo info={<p>Select your assets to safeguard</p>}/> */}
          </h1>
        </div>
      : 'Create'

  }

  const steps = [
    {
      id: 0,
      title: 'Scan' ,
      content: scanContent,
    },
    {
      id: 1,
      title: 'Customize',
      content: customizeContent
    },
    {
      id: 2,
      title: 'Approve',
      content: approveContent,
    },
    {
      id: 3,
      title: 'Contacts',
      content: customizeContacts,
    },
    {
      id: 4,
      title: 'Create',
      content: !created ? submitContent : createdContent,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
    // console.log("next:", current + 1);
    logEvent(analytics, `create_next_step${current + 1}`);
  };

  const prev = () => {
    setCurrent(current - 1);
    // console.log("prev:", current - 1);
    logEvent(analytics, `create_prev_step${current - 1}`);
  };

  const isSelected = (item) => {
    return item.isChecked
  };

  const customizeStep = () => {
    if (NETWORK(selectedChainId)?.fullFeatures){ //remove or add when the smart contract is deployed on more networks
      const selectedNFTs = ERC721Assets.filter(isSelected);
      setSelectedERC721(selectedNFTs);
      setCurrent(current + 1);
      // console.log("next:", current + 1);
      logEvent(analytics, `create_next_step${current + 1}`);
    } else {
      notification.warning({
        message: <Text>Feature not available on <b>{selectedNetwork}</b></Text>,
        description: <Text>Please change to <b>Polygon</b>, <b>Mumbai</b> or <b>Goerli</b> to access all features</Text>,
        duration: 30
      })
    }
  };

  // const submit = async () => {
  //   setResource(fetchSmartVaultInfo(['48'], tx, readContracts));
  //   setLoadingCreate(true);
  //   updateVaultId('48');
  //   updateVaultStatus('0');
  //   setCreated(true);
  //   setLoadingCreate(false);
    
  // };

  const submit = async () => {
    setLoadingCreate(true);
    checkBridgeConnection(signer);
    const args = [
      model,
      selectedERC20.map(item => item.address), // Array tokens ERC20
      beneficiaries.map(item => [item.address, item.percentage]), // Array beneficiaries [address, percentage]
      //selectedERC721.filter(onlyTokenId).map(item => [item.address.split("_")[0], item.tokenId, item.beneficiary]),  // Array NFTs [NFT address, tokenId, beneficiary address ]
      selectedERC721.map(item => [item.address, item.tokenId, item.beneficiary]),  // Array NFTs [NFT address, tokenId, beneficiary address ]
      trustees.map(item => item.address),  // Array trustees address
      trusteeIncentive,
      lockDays
    ];
    // console.log("createSteps.args", args);
    // console.log("Amount: ", depositAmount);
    // console.log("createSteps.writeContracts", writeContracts);

    try{
      const gasLimit = await writeContracts.CarapaceSmartVault.estimateGas.createSmartVault(...args, {
        value: utils.parseEther(depositAmount),
      });
      
      const gasMargin = (gasEstimated, percentage) => {
        return gasEstimated * percentage;
      };
      // console.log("createStep.gasLimit", Math.ceil(gasMargin(gasLimit, 1.1)));

      const result = await tx(writeContracts.CarapaceSmartVault.createSmartVault(...args, {
        value: utils.parseEther(depositAmount),
        gasLimit: Math.ceil(gasMargin(gasLimit, 1.1)), // 10% margin
      }));

      if (result) {
        const txReceipt = await result.wait();
        txReceipt.events.forEach(item => {
          if (item.event === "vaultEvent") {
            // console.log("event.vaultid:", item);
            // console.log("event.vaultid:", item.args._vaultId.toString());
            // console.log("event.owner:", item.args[1]);
            // console.log("event.status:", item.args._status.toNumber());
            setResource(fetchSmartVaultInfo([item.args._vaultId.toString()], tx, readContracts, selectedChainId));
            updateVaultId(item.args._vaultId.toString());
            updateVaultOwner(item.args._owner);
            updateVaultStatus(item.args._status.toNumber());
            // Args[1] = _owner (Eth) = _caller (Polygon)
            submitContacts(item.args._vaultId.toString(), item.args[1], item.args._status);
            logEvent(analytics, `create_vault_success`);
          }
          
        });
        setCreated(true);
        // message.success(`Vault created! View tx on block explorer ${NETWORK(selectedChainId).blockExplorer}tx/${result.hash}`, 5);
        

      } 
    } catch (error) {
      // message.error(`Error creating Vault: ${error.message}`, 5);
      notification.error({
        message: "Error creating Vault",
        description: error.message
      })
      logEvent(analytics, `create_vault_error`);
    }
    setLoadingCreate(false);
  };

  const submitContacts = (vaultId, vaultOwner, vaultStatus) => {

    console.log("Updating Vault Contacts:", selectedNetwork, vaultId, vaultOwner, vaultStatus.toString());
    if(address === vaultOwner){

      let beneficiariesContactsList = "";
      let trusteesContactsList = "";
      
      beneficiariesContacts.forEach(element => {
        if(element.email)
          beneficiariesContactsList += element.email + ";";
      });

      trusteesContacts.forEach(element => {
        if(element.email)
          trusteesContactsList += element.email + ";";
      });

      const addVaultContact = httpsCallable(functions, 'addContact');
      addVaultContact({
        collection: selectedNetwork, 
        vaultid: vaultId,
        owner: ownerContacts,
        beneficiaries: beneficiariesContactsList,
        trustees: trusteesContactsList,
        status: vaultStatus.toString()
        })
            .then((response) => {
              const { result, error } = response.data;
              if (result) {
                // console.log('Contacts successfully submitted');
              } else
                // message.error("error: contacts not submited");
                notification.error({
                  message: "Error submitting contacts",
                  description: "Contacts not submitted"
                })
            })
            .catch((error) => {
                // console.error("Error adding contact: ", error);
                // message.error('error');
                notification.error({
                  message: "Error submitting contacts",
                  description: error
                })
            });
    } else // message.error("Contacts not updated: only owner");
      notification.error({
        message: "Error submitting contacts",
        description: "Contacts not submitted: only owner"
      })
  }

  const stepDescription = (id, current) => {
    if(id === 0 && current === 0) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Select the assets to safeguard</Text>
        </div>)
    }
    if(id === 1 && current === 1) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Fill beneficiary and trustee addresses</Text>
        </div>)
    }
    if(id === 2 && current === 2) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Allow asset protection on your behalf</Text>
        </div>)
    }
    if(id === 3 && current === 3) {
      return (
        <div style={{width: "100%"}}>
          <Text type='secondary'>Optionally add info to get notifications</Text>
        </div>)
    }
    if(id === steps.length - 1 && current === steps.length - 1) {
      return (
        <div>
          <Text type='secondary'>Review and deposit funds to create a vault</Text>
        </div>)
    }
  }

  const stepCreatedStatus = (current) => {
    if(current == steps.length -1 && created)
      return "finish";
  }

  const formatBalance = (balance) => {
    if (balance == 0){
      return balance;
    } else {
      const decimal = balance.split('.');
      if (decimal[0] != 0){
        return parseFloat(balance).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(balance).toFixed(fixedDecimals);
      }
    }
  }

  function ShowBalance({index}) {
    const balance = resource?.balance.read();
    return <Text type='primary'>{formatBalance(utils.formatEther(balance[index][0]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  const formatReward = (reward) => {
    if (reward == 0){
      return reward;
    } else {
      const decimal = reward.split('.');
      if (decimal[0] != 0){
        return parseFloat(reward).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(reward).toFixed(fixedDecimals);
      }
    }
  }

  function TwitterButton() {
    // if(NETWORK(selectedChainId).testnet) {
      // return ( 
      //   <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fapp.carapace.io%2F&text=I%20was%20testing%20%40CarapaceDapp%20and%20just%20have%20created%20a%20new%20Smart%20Vault%20(ID%3A%20${vaultId})%20to%20safeguard%20my%20%23crypto%20assets%20without%20locking%20them%20nor%20sharing%20my%20private%20keys%20%F0%9F%94%90%20%0a%0aCan%27t%20wait%20for%20%23mainnet%20deployment%20%F0%9F%9A%80%0a%0a%20Have%20a%20try%20and%20stay%20up%20to%20date%20%F0%9F%91%89%20carapace.io`} target="_blank">
      //     <Button type="primary" size="large" icon={<TwitterOutlined />}>Twitt</Button>
      //   </a>
      // )
      // } else {
        return (
          <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fapp.carapace.io%2F&text=Hey%20frens!%20%F0%9F%91%8B%20%20%0a%0aI%27ve%20created%20a%20new%20Smart%20Vault%20(ID%3A%20${vaultId})%20using%20%40CarapaceDapp%20to%20safeguard%20my%20digital%20assets%20in%20${NETWORK(selectedChainId).handle}%20network%20forever%20%E2%99%BE%EF%B8%8F%20%20%20%0a%0aIncredible%20how%20easy%20it%20was%2C%20no%20locked%20funds%20nor%20sharing%20my%20private%20keys%20%F0%9F%94%90%20%20%20%0a%0aHave%20a%20try%20%F0%9F%91%89%20app.carapace.io`} target="_blank">
            <Button type="primary" size="large" icon={<TwitterOutlined />}>Twitt</Button>
          </a>
        )
      // }
  }

  function ShowReward({index}) {
    const reward = resource?.reward.read();
    return <Text type='primary'>{formatReward(utils.formatEther(reward[index]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  function ShowImage({index}) {
    let imageUrl = '';
    const image = resource?.image.read();

    if (!!image && image.length > 0){
      // console.log("IMAGE: ", image);
      const base64String = image[index].toString().replace("data:application/json;base64,","");
      const decoded = Buffer.from(base64String, 'base64').toString();
      imageUrl = JSON.parse(decoded).image;
    }
    return <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>;
  }

  const handleImgError = () => {
    return true;
  };

  const getErc20TokenLogo = (erc20TokenList) => {
    const tokens = [];

    for (const tokenAddress of erc20TokenList) {
      const selectedItem = testTokenList.tokens.find(item => item.address === tokenAddress);
      let name = DEFAULT_NAME;
      let symbol = DEFAULT_SYMBOL;
      let logo = erc20ImgLogo;
  
      if (selectedItem) {
        name = selectedItem.name;
        symbol = selectedItem.symbol;
        logo = selectedItem.logoURI;
      };

      tokens.push({
        address: tokenAddress,
        name,
        symbol,
        logo
      })
    }
    return tokens;
  };

  const erc20Logo = (erc20Tokens) =>  {
    return (
      <Tooltip key={erc20Tokens.address} title={erc20Tokens.symbol} placement="top">
        <Avatar src={erc20Tokens.logo} onError={handleImgError}/>
      </Tooltip>  
    );
  };

  function ShowERC20({index}) {
    const vaultInfo = resource?.vaultInfo.read();

    const erc20TokensLogos = getErc20TokenLogo(vaultInfo[index][0]);

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {erc20TokensLogos.map((subItem) => (erc20Logo(subItem)))}
      </Avatar.Group>
    )
  }

  function ShowNFT({index}) {
    const nft = resource?.nft.read();

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {nft[index].map((subItem) => <Avatar key={subItem.address} src={subItem.image} shape="square" onError={handleImgError}/>)}
      </Avatar.Group>
    )
  }

  function ShowSmartVault() {
    //const state = resource?.state.read();
    
    return (
      <>
        <div style={{display: "flex", justifyContent: 'center', marginTop: 40, marginBottom: 40 }}>
          <SmartVaultCard
            key={vaultId} 
            vaultId={vaultId}
            status={vaultStatus}
            url={`/mysmartvaults/${vaultId}`}
            balance={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowBalance index={0} /></Suspense>}
            reward={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowReward index={0} /></Suspense>}
            image={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowImage index={0} /></Suspense>}
            erc20={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowERC20 index={0} /></Suspense>}
            nft={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowNFT index={0} /></Suspense>}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={windowSize?.mainContainer}>
        <Steps size='small' current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} description={!windowSize.stepsBreak && stepDescription(item.id, current)} status={stepCreatedStatus(current)}/>
          ))}
        </Steps>
      </div>
      <div className={windowSize?.mainContainer}>{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && !created  && (
          <Button className="ant-button-carapace" size="large" icon={<ArrowLeftOutlined />} style={{ margin: '0 16px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current === 0 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={selectedERC20.length === 0 && nftCount === 0 } onClick={() => customizeStep()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 1 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!allFilled} onClick={() => next()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 2 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!allApproved} onClick={() => next()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === 3 && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!contactsChecked} onClick={() => next()}>
            Next<ArrowRightOutlined />
          </Button>
        )}
        {current === steps.length - 1 && !created  && (
          <Button className="ant-button-carapace" size="large" type="primary" disabled={!minDepositAmount} loading={loadingCreate} onClick={() => submit()}>
            Create<UploadOutlined />
          </Button>
        )}
          
      </div>
      <br/>
      {/* </div> */}
    </>
    )
}
