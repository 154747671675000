import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Card , Typography, Avatar, Row, Col, Tooltip, Skeleton, Spin } from "antd";
import "../App.css";
import { SafetyCertificateOutlined, UnlockOutlined, StopOutlined, ShareAltOutlined, ExportOutlined } from '@ant-design/icons';
import testTokenList from '../testTokenList.json';
import erc20ImgLogo from '../erc20_logo.png';
import { lime, gold, magenta, purple } from '@ant-design/colors';
import Blockies from "react-blockies";

const { Meta } = Card;
const { Text, Paragraph } = Typography;

export default function SmartVaultCard(
  { vaultId, owner, status, url, balance, reward, image, erc20, nft}) {

  const vaultStatus = ['Active', 'Waiting unlock', 'Canceled', 'Executed'];
  const listOfTokens = testTokenList.tokens;
  const DEFAULT_TOKENID_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/NFT_Icon.png/640px-NFT_Icon.png";

  const checkStatus = () => {
    if (vaultStatus[status] == 'Active') {
      return lime[5];
    };
    if (vaultStatus[status] == 'Waiting unlock') {
      return gold[5];
    };
    if (vaultStatus[status] == 'Canceled') {
      return magenta[5];
    };
    if (vaultStatus[status] == 'Executed') {
      return purple[5];
    };
  }

  const iconStatus = () => {
    if (vaultStatus[status] == 'Active') {
      return <Tooltip title={vaultStatus[status]} placement="right"><SafetyCertificateOutlined style={{ marginTop: 8, fontSize: '30px', color: lime[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Waiting unlock') {
      return <Tooltip title={vaultStatus[status]} placement="right"><UnlockOutlined style={{ marginTop: 8, fontSize: '30px', color: gold[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Canceled') {
      return <Tooltip title={vaultStatus[status]} placement="right"><StopOutlined style={{ marginTop: 8, fontSize: '30px', color: magenta[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Executed') {
      return <Tooltip title={vaultStatus[status]} placement="right"><ExportOutlined style={{ marginTop: 8, fontSize: '30px', color: purple[5] }} /></Tooltip>
    };
  };

  const handleImgError = () => {
    return true;
  };

  const erc20Logo = (erc20Tokens) =>  {
    return (
      <Tooltip key={erc20Tokens.address} title={erc20Tokens.symbol} placement="top">
        <Avatar src={erc20Tokens.logo} onError={handleImgError}/>
      </Tooltip>  
    );
  };

  return (
    //<Link to={`${url}/${vaultId}`}  >
    <Link to={() => `${url}`} >

      <Card
          key={vaultId}
          style={{ width: 300, boxShadow: `1px 1px 20px 2px ${checkStatus()}` }}
          hoverable
          title={
          <Row>
          <Col span={21} style={{display: "flex", flexDirection: 'column', textAlign: 'left', marginTop:10}}>
            <Text type='secondary'>Smart Vault # {vaultId}</Text>
          </Col>
          <Col span={3} style={{display: "flex", flexDirection: 'column', textAlign: 'right'}}>
            {iconStatus()}
          </Col>
        </Row>
          }
      >
        <Row>
          <Col span={12} style={{display: "flex", flexDirection: 'column', textAlign: 'left'}}>
            <Text type='secondary' >Balance </Text>
            {balance}
          </Col>
          <Col span={12} style={{display: "flex", flexDirection: 'column', textAlign: 'right'}}>
            <Text type='secondary' >Rewards </Text>
            {reward}
          </Col>
        </Row>
      </Card>

      <Card
        style={{ width: 300, boxShadow: `1px 1px 20px 2px ${checkStatus()}` }}
        hoverable
        cover={
            
              <div style={{ overflow: "hidden", height: 300 }}>
                
                  {/* <img alt="nft" style={{ height: "100%" }} src={imageUrl}/> */}
           {image}
              </div>
 
        }
      //   cover={
      //     <>
      //       <div style={{ overflow: "hidden", height: 300 }}>
      //         {loadingImage 
      //           ? <div style={{margin: 20}}> 
      //               <Skeleton  active loading={loadingImage} avatar round />
      //             </div>
      //           : <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>
      //       }
      //       </div>
      //     </>
      // }
      >
        <div style={{display: 'flex', justifyContent: 'space-between', height: owner ? 60 : 30 }}>
          {erc20}
          {nft}
          { owner &&
            <span style={{ verticalAlign: "middle" }}>
              <Row>
                <Text type='secondary' style={{marginBottom: 10 }}>Owner</Text>
              </Row>
              {owner}
            </span>
          }
        </div>
      </Card>
    </Link>
  );
}
