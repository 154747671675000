import React, { useState, useEffect, useContext } from "react";
import { Button, Table, Avatar, Empty, List, Card, Typography, Row, Col, Skeleton, Divider, Alert, notification } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import { NFTCard, AdditionalInfo } from ".";
import "../App.css";
import { LinkOutlined } from '@ant-design/icons';
import { Web3Context } from "../context/web3Details";
import { NETWORK } from "../constants";
import { fetchNFTAssets }  from "../helpers/fetchNFTAssets";
import { fetchERC20Assets }  from "../helpers/fetchERC20Assets";
import emptyWallet from '../emptyWallet.png';
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";

const { Text } = Typography;

export default function ScanAssets(
  { 
    erc20Assets, 
    erc721Assets, 
    scannedERC20, 
    scannedERC721, 
    selectedERC20Assets, 
    selectedERC721Assets, 
    selectedERC20, 
    selectedERC721, 
    vaultEdited,
    nftSelection,
    fetchERC20,
    fetchNFT,
    doneFetchERC20,
    doneFetchNFT,
    mainnetProvider
  }) {
  
  const { selectedChainId, selectedNetwork, address, tx, readContracts } = useContext(Web3Context);

  const [reScanERC20, setReScanERC20] = useState(false);
  const [reScanNFT, setReScanNFT] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');
  const [faucetSubmitted, setFaucetSubmitted] = useState(false);
  const [doneFaucet, setDoneFaucet] = useState(false);
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (address !== currentAddress || selectedChainId !== currentNetwork){
      doneFetchERC20(false);
      doneFetchNFT(false);
      setDoneFaucet(false);
    }
  },[address, selectedChainId]);


  useEffect(() => {
    if (NETWORK(selectedChainId)?.fullFeatures ){ //remove or add when the smart contract is deployed on more networks
      if (!fetchERC20 && address && tx && Object.entries(readContracts).length !== 0 && selectedChainId){
        setCurrentAddress(address);
        setCurrentNetwork(selectedChainId);
        setReScanERC20(true);
        doneFetchERC20(true);
        fetchERC20Assets(address, selectedChainId, tx, readContracts, vaultEdited, scannedERC20, endReScanERC20);
      }
    } else { //remove all else
      if (!fetchERC20 && address && selectedChainId){
        setCurrentAddress(address);
        setCurrentNetwork(selectedChainId);
        setReScanERC20(true);
        doneFetchERC20(true);
        fetchERC20Assets(address, selectedChainId, null, null, vaultEdited, scannedERC20, endReScanERC20);
      }
    }
  },[fetchERC20, address, selectedChainId, readContracts]);

  useEffect(() => {
      if (NETWORK(selectedChainId)?.fullFeatures ){ //remove or add when the smart contract is deployed on more networks
        if (!fetchNFT && address && tx && Object.entries(readContracts).length !== 0 && selectedChainId ){
          setCurrentAddress(address);
          setCurrentNetwork(selectedChainId);
          setReScanNFT(true);
          doneFetchNFT(true);
          fetchNFTAssets(address, selectedChainId, tx, readContracts, vaultEdited, selectedERC721, scannedERC721, endReScanNFT);
        }
      } else { //remove all else
        if (!fetchNFT && address && selectedChainId ){
          setCurrentAddress(address);
          setCurrentNetwork(selectedChainId);
          setReScanNFT(true);
          doneFetchNFT(true);
          fetchNFTAssets(address, selectedChainId, null, null, vaultEdited, selectedERC721, scannedERC721, endReScanNFT);
        }
      }
    },[fetchNFT, address, selectedChainId, readContracts]);

  const columnsERC20 = [
    {
      title: '',
      dataIndex: 'logoDetail',
      key: 'logoDetail',
      render: logoDetail => { return (
                        <div style={{marginLeft: 20}}>
                          {logoDetail === '' 
                            ? null 
                            : <Row>
                                <Col>
                                  <Avatar style={{marginTop: 5}} src={logoDetail.image}/>
                                </Col>
                                <Col>
                                  <Row>
                                    <Text style={{marginLeft: 20}} type='primary'>{logoDetail.symbol}</Text>
                                  </Row>
                                  <Row>
                                    <Text style={{marginLeft: 20}} type='secondary'>{logoDetail.name}</Text>
                                  </Row>
                                </Col>
                              </Row> 
                          }
                        </div>      
                        )
                      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
    }
  ];

  const rowSelectionERC20 = {
    selectedRowKeys: selectedERC20.map(item => item.address),                      
    onChange: (selectedRowKeys, selectedRows) => {
      selectedERC20Assets(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.isAlreadyUsed
    }),
  };

  const endReScanNFT = () => {
    setReScanNFT(false);
  };

  const endReScanERC20 = () => {
    setReScanERC20(false);
  };

  const selectorOnChange = (event, vaultId, address, tokenId) => {
    let newSelection = [...erc721Assets];
    const index = newSelection.findIndex((item) => item.address === address && item.tokenId === tokenId );
    newSelection[index]['isChecked'] = !newSelection[index]['isChecked'];
    newSelection[index]['isChecked'] ? nftSelection(1) : nftSelection(-1);
    selectedERC721Assets(newSelection);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.getElementById("nfts").offsetTop - 40,
      behavior:"smooth"
  });
};

  const tokenIdLink = ({tokenId, address}) => {
    return (
      <>
        <Text type='secondary' style={{marginRight: 5}}># {tokenId}</Text>
        <Text>
          <a href={`${NETWORK(selectedChainId).blockExplorer}token/${address}?a=${tokenId}`} target="_blank">
            <LinkOutlined key="setting" />
          </a>
        </Text>
      </>
    )
  };

  function ShowERC20() {
    if (erc20Assets.length == 0) {
      return <Empty 
                description={<Text type='secondary'>Connected wallet has no tokens</Text>} 
                image={emptyWallet} 
                imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
            />
    } else {
      return <Table
                className="ant-table-content"
                size="small"
                rowKey='address' 
                dataSource={erc20Assets} 
                columns={columnsERC20}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionERC20
                }}
                pagination={{ 
                  pageSize: erc20Assets.length,
                  position: ['none']
                }}
              />
    }
  }

  const addFaucet = () => {
    setFaucetSubmitted(true);
    const addFaucetContact = httpsCallable(functions, 'addFaucet');
    addFaucetContact({
      address,
      network: selectedNetwork
    }).then((response) => {
        const { result, error } = response.data;
        if (result) {
          setFaucetSubmitted(false);
          setDoneFaucet(true);
          notification.success({
            message: "Faucet request submited",
            description: "The assets will be available in your wallet in about 30min."
          })
          
        } else {
          setFaucetSubmitted(false);
          setDoneFaucet(true);
          notification.error({
            message: "Error submitting faucet",
            description: "There is a request pending. Please wait a few more minutes."
          })
        }
      })
      .catch((error) => {
          console.error("Error submiting faucet: ", error);
          // message.error('error');
          // notification.error({
          //   message: "Error submitting faucet",
          //   description: error
          // })
      });
    }

  const ShowFaucet = () => {
    return <Alert
        message="Testnet Faucet"
        description={
          <div>
            <Text>Do you need cryptocurrencies or NFTs for testing purposes?</Text>
              <Row style={{display:'flex', justifyContent:'center', marginTop:20}}>
                <Button type="primary" onClick={addFaucet} loading={faucetSubmitted}>Get testnet assets for my address</Button>
              </Row>
         </div>
        }
        type="warning"
        closable
      />
  }
  return (
    <>
    { NETWORK(selectedChainId)?.testnet && !reScanERC20 && !reScanNFT && !doneFaucet &&
      erc20Assets.length === 0 && erc721Assets.length === 0 && <ShowFaucet /> }
      <div className="ant-div-carapace" style={{marginTop:30}}>
        <div className="div-header">
          <Row style={{display: "flex", justifyContent: "space-between"}}>
            <h2>Cryptocurrency<AdditionalInfo info={
              <>
                <p>Select your tokens to be protected. The tokens will NOT be locked, so you can always use them normally.</p>
                <p>At least one token or NFT must be selected. Disabled assets are already safeguarded in one of your vaults.</p>
              </>
              }/>
            </h2>
            <Button onClick={() => doneFetchERC20(false)} loading={reScanERC20} icon={<SyncOutlined/>} />
          </Row>
        <br/>
        { reScanERC20
          ? <>
              <Row>
                <Skeleton active avatar size='small' shape='square'/>
              </Row>
              <Divider />
              <Row>
                <Skeleton active avatar size='small' shape='square'/>
              </Row>
            </>
          : <ShowERC20 />
        }
        </div>
      </div>

      <div id="nfts"/>      
      <div  className="ant-div-carapace" style={{marginTop:30}}>
        <div className="div-header">
          <Row style={{display: "flex", justifyContent: "space-between"}}>
            <h2>NFT<AdditionalInfo info={
              <>
                <p>Select your NFTs to be protected. The NFTs will NOT be locked, so you can always use them normally.</p>
                <p>At least one token or NFT must be selected. Disabled assets are already safeguarded in one of your vaults.</p>
              </>
              }/></h2>
            <Button onClick={() => doneFetchNFT(false)} loading={reScanNFT} icon={<SyncOutlined/>} />
          </Row>
        </div>
        { reScanNFT
          ?  <div style={{margin: 30}}>
              <Row gutter={32}>
                <Col span={6}>
                  <Card style={{height: 400}}>
                    <Skeleton active avatar size='small' shape='square'/>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{height: 400}}>
                    <Skeleton active avatar size='small' shape='square'/>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{height: 400}}>
                    <Skeleton active avatar size='small' shape='square'/>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{height: 400}}>
                    <Skeleton active avatar size='small' shape='square'/>
                  </Card>
                </Col>
              </Row>
            </div> 

          : <> { erc721Assets.length == 0
            ? <Empty 
                description={<Text type='secondary'>Connected wallet has no NFTs</Text>} 
                image={emptyWallet} 
                imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
              />
            : <List
                align="center"
                grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }}
                dataSource={erc721Assets}
                renderItem={item => (
                  <List.Item className="scan-nft" >
                    <NFTCard
                      address={item.address}
                      tokenId={item.tokenId} 
                      selector 
                      selectorText={'Add to smart vault'} 
                      title={item.symbol} 
                      description={item.name.length < 28
                        ? `${item.name}`
                        : `${item.name.substring(0, 25)}...`}
                      src={item.image} 
                      additional={tokenIdLink(item)}
                      selectorOnChange={selectorOnChange}
                      isChecked={item.isChecked}
                      isAlreadyUsed={item.isAlreadyUsed}
                    />
                  </List.Item>
                )}
                bordered={false}
                pagination={{ 
                  onChange: page => {
                    scrollToTop();
                  },
                  pageSize: 16 
                }}
              />
            }
          </>
        }
      </div>
    </>
  )
}

