import axios from "axios";
import { NETWORK } from "../constants";

const DEFAULT_SYMBOL = 'Symbol not available';
const DEFAULT_NAME = 'No name available';
const DEFAULT_TOKENID_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/NFT_Icon.png/640px-NFT_Icon.png";

export const fetchSmartVaultInfo = ( vaultsOfOwner, tx, readContracts, selectedChainId ) => {

  const fetchVaultsState = () => {
    let vaultState = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultState(item)));
      });
      vaultState.push(promise);
    });
    return Promise.all(vaultState)
  }

  const fetchVaultsBalance = () => {
    let vaultsBalance = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultRwrdInfo(item)));
      });
      vaultsBalance.push(promise);
    });
    return Promise.all(vaultsBalance)
  }

  const fetchVaultsReward = () => {
    let vaultsReward = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceSmartVault.getVaultRewards(item)));
      });
      vaultsReward.push(promise);
    });
    return Promise.all(vaultsReward)
  }

  const fetchVaultsImage = () => {
    let vaultsImage = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.tokenURI(item)));
      });
      vaultsImage.push(promise);
    });
    return Promise.all(vaultsImage)
  }

  const fetchVaultsInfo = () => {
    let vaultsERC20 = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultInfo(item)));
      });
      vaultsERC20.push(promise);
    });
    return Promise.all(vaultsERC20)
  }

  const fillNFTList = async (nftList) => {
    const filledNFTList = [];
    for (const item of nftList){
      const config = {
        method: 'get',
        url: `${NETWORK(selectedChainId).alchemyNFTApi}/getNFTMetadata?contractAddress=${item.address.split("_")[0]}&tokenId=${item.tokenId}`,
        headers: { }
      };

      await axios(config)
        .then((res) => {
          let tokenIdName = DEFAULT_NAME;
          let tokenIdImage = DEFAULT_TOKENID_IMAGE;
          const metadata = res.data.metadata;
          if (!!metadata){
            !!metadata.name ? tokenIdName = metadata.name : null;
          
            if(!!metadata.image && metadata.image.includes("ipfs://")) {
              if (metadata.image.includes("ipfs://ipfs/")) {
                tokenIdImage = metadata.image.replace("ipfs://ipfs/", "https://ipfs.io/ipfs/");
              } else {
                tokenIdImage = metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
              }
            } else {
              tokenIdImage = metadata.image;
            }
          }
          filledNFTList.push({
            ...item,
            name: tokenIdName,
            image: tokenIdImage,
            parentSymbol: res.data.title
          });
        })
        .catch((error) => {
          filledNFTList.push({
            ...item,
            name: DEFAULT_NAME,
            image: DEFAULT_TOKENID_IMAGE,
            parentSymbol: DEFAULT_SYMBOL
          });
          console.error(error);
        });
    }
    return filledNFTList ;
  };

  const fetchVaultsNFT = () => {
    let vaultsNFT = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        
        resolve(tx(readContracts.CarapaceStorage.getNonFungibleAssets(item)).then(result => {
          const erc721Tokens = result[0].map((subItem,index) => {
            return {
                id: result[1][index].toString(),
                address: subItem + '_' + result[1][index],
                type: 'tokenId',
                tokenId: result[1][index].toString(),
                beneficiary: result[2][index],
            }
          });
          return fillNFTList(erc721Tokens);   
        }));
     
      });
      vaultsNFT.push(promise);
    });
    return Promise.all(vaultsNFT);
  }

  const fetchTrusteeIncentive = () => {
    let trusteeIncentive = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getSmartVaultTrusteeIncentive(item)));
      });
      trusteeIncentive.push(promise);
    });
    return Promise.all(trusteeIncentive)
  }

  const fetchBeneficiaries = () => {
    let beneficiaries = [];
    vaultsOfOwner.forEach( item => {
      const promise = new Promise((resolve) => {
        resolve(tx(readContracts.CarapaceStorage.getBeneficiaries(item)));
      });
      beneficiaries.push(promise);
    });
    return Promise.all(beneficiaries)
  }

  let statePromise = fetchVaultsState();
  let balancePromise = fetchVaultsBalance();
  let rewardPromise = fetchVaultsReward();
  let imagePromise = fetchVaultsImage();
  let vaultInfoPromise = fetchVaultsInfo();
  let nftPromise = fetchVaultsNFT();
  let incentivePromise = fetchTrusteeIncentive();
  let beneficiariesPromise = fetchBeneficiaries();

  return {
    state: wrapPromise(statePromise),
    balance: wrapPromise(balancePromise),
    reward: wrapPromise(rewardPromise),
    image: wrapPromise(imagePromise),
    vaultInfo: wrapPromise(vaultInfoPromise),
    nft: wrapPromise(nftPromise),
    trusteeIncentive: wrapPromise(incentivePromise),
    beneficiaries: wrapPromise(beneficiariesPromise),
  };
};

function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}
