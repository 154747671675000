import axios from "axios";

export const getNFTDetail = async (chainId, nftAddress, tokenId) => {
  
  let symbol = 'Symbol not available';
  let name = 'Name not available';
  let image = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/NFT_Icon.png/640px-NFT_Icon.png";
  let owner;

  await axios.get(`https://deep-index.moralis.io/api/v2/nft/${nftAddress}/${tokenId}?chain=${chainId}&format=decimal`, {
          headers: {
            'accept': 'application/json',
            'X-API-key': process.env.REACT_APP_MORALIS_KEY
          }
      })
    .then((res) => {
      owner = res.data.owner_of;
      if (res.data.symbol != '') {
        symbol = res.data.symbol
      }
      const metadata = JSON.parse(res.data.metadata);
      if (!!metadata){
        !!metadata.name ? name = metadata.name : null;

        if(!!metadata.image && metadata.image.includes("ipfs://")) {
          if (metadata.image.includes("ipfs://ipfs/")) {
            image = metadata.image.replace("ipfs://ipfs/", "https://ipfs.io/ipfs/");
          } else {
            image = metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
          }
        } else {
          image = metadata.image;
        }
      }

    })
    .catch((error) => {
      console.error("Error getting NFT Detail: ", error);
    });

    return { owner, symbol, name, image };
};