import axios from "axios";
import erc20ImgLogo from '../erc20_logo.png';
import testTokenList from '../testTokenList.json';
import { NETWORK } from "../constants";
import { utils } from "ethers";

const { toChecksumAddress } = require('ethereum-checksum-address');
const DEFAULT_ERC20_NAME = 'No name available';
const DEFAULT_ERC20_SYMBOL = 'No symbol available';
const DEFAULT_ERC20_LOGO = erc20ImgLogo;
const DEFAULT_TOKENID_NAME = 'No name available';
const DEFAULT_TOKENID_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/NFT_Icon.png/640px-NFT_Icon.png";

export const fetchNFTAssets = ( address, selectedChainId, tx, readContracts, vaultEdited, selectedERC721, scannedERC721, endReScanNFT ) => {
 
    const getTokenIdImage = (metadata) => {
      let image;
      let tokenIdImage;

      if(!!metadata.image) {
        image = metadata.image;
      } else if (!!metadata.image_url) {
        image = metadata.image_url;
      } else image = DEFAULT_TOKENID_IMAGE;

      if(image.includes("ipfs://")) {
        if (image.includes("ipfs://ipfs/")) {
          tokenIdImage = image.replace("ipfs://ipfs/", "https://ipfs.io/ipfs/");
        } else {
          tokenIdImage = image.replace("ipfs://", "https://ipfs.io/ipfs/");
        }
      } else {
        tokenIdImage = image;
      };
      return tokenIdImage;
    };

    const getKey = (id) => {
      let sumChars = 0;
      for(let i = 0;i < id.length;i++){
        sumChars += id.charCodeAt(i);
      }
      const apiKeys = JSON.parse(process.env.REACT_APP_MORALIS);
      const manyKeys = Object.entries(apiKeys).length;
      const useKey = sumChars % manyKeys;

      return apiKeys[useKey];
    }

    //   const fetchAPINFT = () => {

    //     return new Promise((resolve) => {
    //       const config = {
    //         method: 'get',
    //         url: `https://eth-${NETWORK(selectedChainId).name}.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY}/getNFTs?owner=${address}`,
    //         headers: { }
    //       };
    //     resolve(axios(config)
    //     .then((res) => {
    //       console.log("Payload NFT :", res.data);

    //       let nftTokenIds = []
    
    //       const orderedResult = res.data.ownedNfts.sort((a, b) => a.contract.address.localeCompare(b.contract.address) || a.id.tokenId.localeCompare(b.id.tokenId));
          
    //       orderedResult.forEach((item) => {
    
    //         let tokenIdName = DEFAULT_TOKENID_NAME;
    //         let tokenIdImage = DEFAULT_TOKENID_IMAGE;
    //         let nftAddress =  toChecksumAddress(item.contract.address);
    //         let isChecked = false;
    //         let beneficiary = '';
    
    //         let selected = selectedERC721.find(selected => selected.address === nftAddress + '_' + item.id.tokenId);
    //         if (!!selected) {
    //           isChecked = true;
    //           beneficiary = selected.beneficiary;
    //         };
    
    //         const metadata = item.metadata;
    //         if (!!metadata){
    //           !!metadata.name ? tokenIdName = metadata.name : null;
    //           tokenIdImage = getTokenIdImage(metadata);
    //         }
    
    //         nftTokenIds.push({
    //           address: nftAddress,
    //           tokenId: item.id.tokenId,
    //           symbol: item.title,
    //           name: tokenIdName, 
    //           image: tokenIdImage,
    //           isChecked,
    //           beneficiary
    //         });
    //       });
    //       return nftTokenIds;
    //     })
    //     .catch((error) => {
    //         console.error(error)
    //     })
    //     )});
    // };

    const isERC721 = (item) => {
      return item.contract_type === "ERC721";
    };
    
    const fetchAPINFT = () => {

        return new Promise((resolve) => {

        resolve(axios.get(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=${NETWORK(selectedChainId).moralisApi}&format=decimal`, {
            headers: {
              'accept': 'application/json',
              // 'X-API-key': getKey(address)
              'X-API-key': process.env.REACT_APP_MORALIS_KEY
            }
        })
        .then((res) => {
          //console.log("Payload NFT :", res.data.result);

          let nftTokenIds = []
          
          const filteredPayload = res.data.result.filter(isERC721);

          const orderedResult = filteredPayload.sort((a, b) => a.token_address.localeCompare(b.token_address) || a.token_id.localeCompare(b.token_id));
          
          orderedResult.forEach((item) => {
    
            let tokenIdName = DEFAULT_TOKENID_NAME;
            let tokenIdImage = DEFAULT_TOKENID_IMAGE;
            let nftAddress =  toChecksumAddress(item.token_address);
            let isChecked = false;
            let beneficiary = '';

            //let selected = selectedERC721.find(selected => selected.address === nftAddress + '_' + item.token_id);
            let selected = selectedERC721.find(selected => selected.address === nftAddress && selected.tokenId === item.token_id);

            if (!!selected) {
              isChecked = true;
              beneficiary = selected.beneficiary;
            };
    
            const metadata = JSON.parse(item.metadata);
            if (!!metadata){
              !!metadata.name ? tokenIdName = metadata.name : null;
              tokenIdImage = getTokenIdImage(metadata);
            }
    
            nftTokenIds.push({
              address: nftAddress,
              tokenId: item.token_id,
              symbol: item.symbol,
              name: tokenIdName, 
              image: tokenIdImage,
              isChecked,
              beneficiary
            });
          });
          return nftTokenIds;
        })
        .catch((error) => {
            console.error(error)
        })
        )});
    };

    const getNFTOfOwner = (vaultsOfOwner) => {
        let nftOfOwner = [];
        vaultsOfOwner.forEach( item => {
          const promise = new Promise((resolve) => {
            resolve(tx(readContracts.CarapaceStorage.getNonFungibleAssets(item))
                .then(async (nfts) => {
                  let nftList = [];
                  for (let i = 0; i < nfts[0].length; i++) {
                    const inVault = 
                      await tx(readContracts.CarapaceStorage.getSmartVaultOwnerNonFungibleAsset(address, nfts[0][i], nfts[1][i]));
                    if (inVault) {
                      nftList.push(nfts[0][i] + '_' + nfts[1][i]);
                    }
                  }
                  return nftList;
                })
            );
          });
          nftOfOwner.push(promise);
        });
        return Promise.all(nftOfOwner);
    };

    const isEditing = (item) => {
        return (item.toString() != vaultEdited);
      };

    const fetchVaultedNFT = () => {
      if (NETWORK(selectedChainId)?.fullFeatures ){ //remove or add when the smart contract is deployed on more networks
        return new Promise((resolve) => {
            resolve(tx(readContracts.CarapaceStorage.getVaultsOfOwner(address))
                .then(vaults => {
                    return getNFTOfOwner(vaults.filter(isEditing));
                })
            );
        });
      } else return [];
    };

    const validateUsedNFT = (apiNFT, vaultedNFTList) => {
        let validatedList = [];

        apiNFT.forEach(item => {
            validatedList.push({
                ...item,
                isAlreadyUsed: vaultedNFTList.includes(item.address + '_' + item.tokenId)
            })
        });

        scannedERC721(validatedList);
        endReScanNFT();
        return validatedList;
    };

    const fetchNFT = () => {
          
          return Promise.all([
            fetchAPINFT(),
            fetchVaultedNFT()
          ]).then(([apiNFT, vaultedNFT]) => {
            let vaultedNFTList = [];
            vaultedNFT.forEach(item => {
              item.forEach(subItem => { 
                vaultedNFTList.push(subItem);
              })  
            })
            return validateUsedNFT(apiNFT, vaultedNFTList);
          })
    }

    let nftPromise = fetchNFT();

    return {
        nft: wrapPromise(nftPromise),
      };
}

function wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
      (r) => {
        status = "success";
        result = r;
      },
      (e) => {
        status = "error";
        result = e;
      }
    );
    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        } else if (status === "success") {
          return result;
        }
      }
    };
  }