import { message, notification } from 'antd';

export const checkBridgeConnection = (signer) => {
    // Gnosis vs Metamask (Proxy)
    // console.log("approve.bridge", signer.provider.provider.hasOwnProperty('bridge'));
    
    if(signer.provider.provider.hasOwnProperty('bridge')){
      // message.info("Please check your connected app to continue. Refresh or restart the process after all signatures if using a multisig wallet.", 5);
      notification.warning({
        message: "Connection Bridge",
        description: "Please check your connected app to continue. Refresh or restart the process after all signatures if using a multisig wallet."
      })
    }
  };