import axios from "axios";
import React, {useEffect, useState, useContext, Suspense} from "react";
import { Typography, Spin, Button, Empty, Col, Row, Avatar, Tooltip, Skeleton, Switch as SwitchAntd} from "antd";
import { utils } from "ethers";
import erc20ImgLogo from '../erc20_logo.png';
import logoGrey from "../logo_grey_hexagon.png";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { ManageSmartVault, SmartVaultCard } from './';
import { fetchSmartVaultInfo }  from "../helpers/fetchSmartVaultInfo";
import { Web3Context } from "../context/web3Details";
import { ScreenSize } from "../context/screenSize";
import testTokenList from '../testTokenList.json';
import { PlusCircleOutlined } from '@ant-design/icons';
import { NETWORK } from "../constants";
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";

const { Text } = Typography;

let resource;

const DEFAULT_SYMBOL = 'Symbol not available';
const DEFAULT_NAME = 'No name available';

export default function MySmartVaults() {
  const { address, tx, readContracts, selectedChainId, refresh, activateRefresh } = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);
  let { path,url } = useRouteMatch();
  const [myVaults, setMyVaults] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [toogle, setToogle] = useState(true);
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');

  useEffect(() => {
    if ( address !== currentAddress || selectedChainId !== currentNetwork || refresh ){
      setFetch(false);
    }
  },[address, selectedChainId, currentNetwork,  refresh]);

  useEffect(() => {
    const getMySmartVaults = async () => {
      if (NETWORK(selectedChainId)?.fullFeatures){ //remove or add when the smart contract is deployed on more networks
        if (!fetch && address && tx && Object.entries(readContracts).length !== 0 && selectedChainId){
            try {
              const response = await tx(readContracts.CarapaceStorage.getVaultsOfOwner(address));
              const vaultsOfOwner = response.map(item => item.toNumber());
              setMyVaults(vaultsOfOwner);
              resource = fetchSmartVaultInfo(vaultsOfOwner, tx, readContracts, selectedChainId);
              setFetch(true);
              setCurrentAddress(address);
              setCurrentNetwork(selectedChainId);
              activateRefresh(false);
            }
            catch(err) {
              setMyVaults([]);
            }
          } 
      } else { //remove all else
          resource = null;
          setMyVaults([]);
          setFetch(true);
          setCurrentAddress(address);
          setCurrentNetwork(selectedChainId);
          activateRefresh(false);
      }
    }

    getMySmartVaults();
  },[fetch, address, refresh, readContracts, selectedChainId, currentNetwork]);

  const clickTrack = (event) => {
    // console.log("Analytics:", event);
    logEvent(analytics, `click_${event}`);
  }

  const getErc20TokenLogo = (erc20TokenList) => {
    const tokens = [];

    for (const tokenAddress of erc20TokenList) {
      const selectedItem = testTokenList.tokens.find(item => item.address === tokenAddress);
      let name = DEFAULT_NAME;
      let symbol = DEFAULT_SYMBOL;
      let logo = erc20ImgLogo;
  
      if (selectedItem) {
        name = selectedItem.name;
        symbol = selectedItem.symbol;
        logo = selectedItem.logoURI;
      };

      tokens.push({
        address: tokenAddress,
        name,
        symbol,
        logo
      })
    }
    return tokens;
  };

  const formatBalance = (balance) => {
    if (balance == 0){
      return balance;
    } else {
      const decimal = balance.split('.');
      if (decimal[0] != 0){
        return parseFloat(balance).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(balance).toFixed(fixedDecimals);
      }
    }
  }

  function ShowBalance({index}) {
    const balance = resource?.balance.read();
    return <Text type='primary'>{formatBalance(utils.formatEther(balance[index][0]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  const formatReward = (reward) => {
    if (reward == 0){
      return reward;
    } else {
      const decimal = reward.split('.');
      if (decimal[0] != 0){
        return parseFloat(reward).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(reward).toFixed(fixedDecimals);
      }
    }
  }

  function ShowReward({index}) {
    const reward = resource?.reward.read();
    return <Text type='primary'>{formatReward(utils.formatEther(reward[index]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  function ShowImage({index}) {
    const image = resource?.image.read();

    const base64String = image[index].toString().replace("data:application/json;base64,","");
    const decoded = Buffer.from(base64String, 'base64').toString();
    const imageUrl = JSON.parse(decoded).image;

    return <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>;
  }

  const handleImgError = () => {
    return true;
  };

  const erc20Logo = (erc20Tokens) =>  {
    return (
      <Tooltip key={erc20Tokens.address} title={erc20Tokens.symbol} placement="top">
        <Avatar src={erc20Tokens.logo} onError={handleImgError}/>
      </Tooltip>  
    );
  };

  function ShowERC20({index}) {
    const vaultInfo = resource?.vaultInfo.read();

    const erc20TokensLogos = getErc20TokenLogo(vaultInfo[index][0]);

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {erc20TokensLogos.map((subItem) => (erc20Logo(subItem)))}
      </Avatar.Group>
    )
  }

  function ShowNFT({index}) {
    const nft = resource?.nft.read();

    return ( 
      <Avatar.Group    
        maxCount={3}
        maxPopoverTrigger="click"
        maxStyle={{
          color: '#ffffff',
          backgroundColor: '#bfbfbf',
          cursor: 'pointer',
        }} 
      >
        {nft[index].map((subItem) => <Avatar key={subItem.address} src={subItem.image} shape="square" onError={handleImgError}/>)}
      </Avatar.Group>
    )
  }

  function ShowSmartVaults() {

    const state = resource?.state.read();

    return (
      <>
      <div className="steps">
        <Row  gutter={64}>
        {myVaults?.map((item, index) => (
  
           <Col key={item} className="vault-cards" span={windowSize?.vaultSpan || 8 } style={{marginTop: 20, marginBottom: 100, display: "flex", justifyContent: "space-evenly"}}>
            <SmartVaultCard
                key={item} 
                vaultId={item}
                status={!!state && state[index]}
                url={`${url}/${item}`}
                balance={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowBalance index={index} /></Suspense>}
                reward={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowReward index={index} /></Suspense>}
                image={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowImage index={index} /></Suspense>}
                erc20={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowERC20 index={index} /></Suspense>}
                nft={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowNFT index={index} /></Suspense>}
                
            />
          </Col>
        ))}
        </Row>
        { myVaults?.length == 0 && fetch &&
              <div className="ant-div-carapace" style={{marginTop: "10%"}}>
                <Empty 
                  description="This account has no vaults. Please create one."
                  image={logoGrey}
                >
                  <Link to="/createsmartvault">
                    <Button icon={<PlusCircleOutlined />} size="large" className="ant-button-carapace" type="primary" onClick={() => clickTrack("CreateVault")}>Create vault</Button>
                  </Link>
                </Empty>
              </div>
          }
      </div>
      </>
    );
  }

  const isClosed = (item) => {
    return (item.status == '0' || item.status == '1');
  }

  const hideClosedVaults = () => {
    const myVaultsFiltered = myVaults.filter(isClosed);
    setMyVaults(myVaultsFiltered);
  };

  const getClosedVaults =  () => {

  };

  const handleSwitch = () => {
    toogle ? hideClosedVaults() : getClosedVaults()
    setToogle(!toogle);
  };
  
  return (
    <Switch >
      <>
        <Route exact path={path}>       
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            {/* Waiting for backlog development */}
            {/* <div className="toogle">
              <SwitchAntd size='small' checked={toogle} onChange={() => handleSwitch()} style={{marginTop: 3}}/>
              <Text type='secondary' style={{marginLeft: 10}}>Show closed vaults</Text>
            </div> */}
            <div className="toogle" />
            <div style={{marginRight: "10%"}}>
            
              { myVaults.length > 0 &&
                <Link to="/createsmartvault">
                  <Button icon={<PlusCircleOutlined />} size="large" type="primary" onClick={() => clickTrack("CreateVault")}>Create vault</Button>
                </Link> 
              }
            </div>
          </div>
          <Suspense fallback={<Spin style={{marginTop:30}} />}>
            <ShowSmartVaults />
          </Suspense>
        </Route>
        <Route path={`${path}/:vaultId`}>
          <ManageSmartVault />
        </Route>
      </>
    </Switch>
  );
}

