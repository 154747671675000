import React, {useEffect, useState, useContext, Suspense } from "react";
import { Web3Context } from "../context/web3Details";
import { ScreenSize } from "../context/screenSize";
import { fetchReceiveAssets } from "../helpers/fetchReceiveAssets";
import { checkBridgeConnection } from "../helpers/checkConnectionType";
import { NFTCard, AdditionalInfo } from ".";
import { utils } from "ethers";
import emptyWallet from '../emptyWallet.png';
import erc20ImgLogo from '../erc20_logo.png';
import { Table, List, Tooltip, Avatar, Button, Typography, Row, Col, Skeleton, Divider, Spin, Card, Empty} from "antd";
import testTokenList from '../testTokenList.json';
import { NETWORK } from "../constants";
import Blockies from "react-blockies";
import { LinkOutlined, BankOutlined } from '@ant-design/icons';
import "../App.css";
 
const { toChecksumAddress } = require('ethereum-checksum-address');
const { Text, Paragraph } = Typography;
let resource;

export default function ReceiveAssets() {

  const { address, tx, readContracts, writeContracts, selectedChainId, signer } = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);
  const [doneFetch, setDoneFetch] = useState(false);

  const DEFAULT_ERC20_NAME = 'No name available';
  const DEFAULT_ERC20_SYMBOL = 'No symbol available';
  const DEFAULT_ERC20_LOGO = erc20ImgLogo;
  const DEFAULT_ERC20_DECIMALS = 18;

  const [selectedBalance, setSelectedBalance] = useState([]);
  const [selectedERC20, setSelectedERC20] = useState([]);
  const [selectedNFT, setselectedNFT] = useState([]);
  const [enabled, setEnabled] = useState(0);
  const [loadingWithdrawBalance, setLoadingWithdrawBalance] = useState(false);
  const [loadingWithdrawERC20, setLoadingWithdrawERC20] = useState(false);
  const [loadingWithdrawNFT, setLoadingWithdrawNFT] = useState(false);
  const [fetchAddress, setFetchAddress] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');

  const setAddress = (value) => {
    setFetchAddress(value);
  };

  useEffect(() => {
    if ( address !== currentAddress || selectedChainId !== currentNetwork ){
      setDoneFetch(false);
      setFetchAddress('');
    }
  },[address, selectedChainId]);

  useEffect(() => {
    const getBeneficiaryAssets = async () => {
      if (NETWORK(selectedChainId)?.fullFeatures){ //remove or add when the smart contract is deployed on more networks
        try {
          if (!doneFetch && address && tx && Object.entries(readContracts).length !== 0 && selectedChainId){   
              const vaultIds = await getBeneficiaryVaults(address);
              resource = fetchReceiveAssets(vaultIds, tx, readContracts, address, selectedChainId, setAddress);
              setDoneFetch(true);
              setCurrentAddress(address);
              setCurrentNetwork(selectedChainId);
            } 
          } 
        catch (err) {
          console.error("Error fetching beneficiary vaults: ", err);
        }
      } else {
        resource = null;
        setFetchAddress(address);
        setDoneFetch(true);
        setCurrentAddress(address);
        setCurrentNetwork(selectedChainId);
      }
    }
    getBeneficiaryAssets();
  },[address, doneFetch, readContracts, selectedChainId]);

  const columnsBalance = [

    {
      className: 'ant-table-title',
      title: 'Original owner',
      dataIndex: 'owner',
      key: 'owner',
      render: owner => {
        const shortOwnerAddress = owner.slice(0,6).trim() + '...' + owner.slice(-4).trim();
        return (
          <div style={{marginTop: 18}}>
            <Row>
              <Blockies seed={owner.toLowerCase()} size={8} scale={3}  />
              {/* <Tooltip placement="bottomLeft" title={owner}> */}
                <Paragraph 
                  // ellipsis={shortAdress} 
                  style={{marginLeft: 10, width: 120 }} 
                  copyable={{text: owner}}
                >
                  {shortOwnerAddress}
                </Paragraph>
              {/* </Tooltip> */}
            </Row>
          </div>
        )
      }
    },
    {
      title: 'Smart Vault',
      dataIndex: 'vaultNFT',
      key: 'vaultNFT',
      render: logo => <div>
                        <Avatar shape="square" src={logo.image} />
                        <Text style={{marginLeft: 10}}># {logo.vaultId}</Text>
                      </div>
    },
    {
      title: `Amount`,
      dataIndex: 'balance',
      key: 'balance',
      align: 'right'
    }
  ];

  const columnsERC20 = [
    {
      title: '',
      dataIndex: 'logo',
      key: 'logo',
      render: logo => { return windowSize?.stepsBreak 
                      ? <div style={{marginLeft: 10}}>
                          {logo === '' 
                            ? null 
                            : <Row>
                                <Col>
                                  <Row>
                                    <Avatar src={logo.image}/>
                                  </Row>
                                  <Row style={{display:'flex', justifyContent:'center'}}>
                                    <Text type='secondary'>{logo.symbol}</Text>
                                  </Row>
                                </Col>
                              </Row> 
                          }
                    </div> 
                      : <div style={{marginLeft: 30}}>
                          {logo === '' 
                            ? null 
                            : <Row>
                                <Col>
                                  <Avatar style={{marginTop: 5 }} src={logo.image}/>
                                </Col>
                                <Col>
                                  <Row>
                                    <Text style={{marginLeft: 20}} type='primary'>{logo.symbol}</Text>
                                  </Row>
                                    <Row>
                                      <Text style={{marginLeft: 20}} type='secondary'>{logo.name}</Text>
                                    </Row>
                                </Col>
                              </Row> 
                          }
                        </div>      
                      }
    },
    {
      title: '',
      dataIndex: 'owner',
      key: 'owner'
    },
    {
      title: '',
      dataIndex: 'vaultNFT',
      key: 'vaultNFT',
      render: value => {
                      return value.type === 'vaultNFT'      
                              ? (
                                  <div>
                                    <Tooltip placement="bottomLeft" title={`Original vault: # ${value.vaultId}`}>
                                      <Avatar shape="square" src={value.image} />
                                      <Text type='secondary' style={{marginLeft: 10}}># {value.vaultId}</Text>
                                    </Tooltip>
                                  </div>
                                )
                              : value.name
                      }
    },
    {
      title: 'Total amount',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
      render: value => {
        return value.parent 
        ? <div>
            <Text>{value.amount}</Text>
          </div>
        : <div>
            <Text type='secondary'>{value.amount}</Text>
          </div>
      }
    }
  ];

  const getBeneficiaryVaults = async (beneficiaryAddress) => {
     const vaultList = await tx(readContracts.CarapaceStorage.getVaultsOfBeneficiary(beneficiaryAddress));
     return vaultList.filter(item => !!item.toNumber()).map(item => item.toNumber());
  };

 const generateTableData = (erc20Detail) => { 
  const erc20TableData = [];
  let erc20DetailData = [];
  const lastIndex = erc20Detail.length - 1;
  let erc20Balance = BigInt(0);
  const orderedERC20Detail = erc20Detail.sort((a, b) => a.token.localeCompare(b.token));
  let symbol; 
  let name; 
  let logo;
  let decimals;

  orderedERC20Detail.forEach((item, index, array) => {

    if (index > 0 && array[index-1].token !== item.token){

      erc20TableData.push({
        key: toChecksumAddress(array[index-1].token),
        type: 'parent',
        logo: {name , symbol, image: logo},
        vaultNFT: {type: 'coinName'},
        token: toChecksumAddress(array[index-1].token),
        children: erc20DetailData,
        balance: {amount: parseFloat(utils.formatUnits(erc20Balance, decimals)).toFixed(6), parent: true},
        parent: true
      });
      erc20DetailData = [];
      erc20Balance = BigInt(0);
    }
    
    let selectedItem = testTokenList.tokens.find(elem => elem.address === toChecksumAddress(array[index].token));
    selectedItem ? logo = selectedItem.logoURI : logo = DEFAULT_ERC20_LOGO;
    selectedItem ? symbol = selectedItem.symbol : symbol = DEFAULT_ERC20_SYMBOL;
    selectedItem ? name = selectedItem.name : name = DEFAULT_ERC20_NAME;
    selectedItem ? decimals = selectedItem.decimals : decimals = DEFAULT_ERC20_DECIMALS;
    
    erc20Balance += item.balance;

    let shortOwnerAddress;

    if (windowSize?.stepsBreak){
      shortOwnerAddress = item.owner.slice(0,6).trim();
    } else {
      shortOwnerAddress = item.owner.slice(0,6).trim() + '...' + item.owner.slice(-4).trim();
    }

    erc20DetailData.push({
      ...item,
      owner:  <div style={{marginTop: 18}}>
                <Row>
                  <Blockies seed={item.owner.toLowerCase()} size={8} scale={3}  />
                  <Tooltip placement="top" title={`Original owner`}>
                    <Paragraph 
                      type='secondary' 
                      style={{marginLeft: 10 }} 
                      copyable={{text: item.owner, placement: "right"}}
                    >
                      {shortOwnerAddress}
                    </Paragraph>
                  </Tooltip>
                </Row>
              </div>,
      key: item.token+' '+item.name,
      type: 'children',
      balance: {amount: parseFloat(utils.formatUnits(item.balance, decimals)).toFixed(6), parent: false}
    });

    if (lastIndex === index){
      erc20TableData.push({
        key: toChecksumAddress(item.token),
        type: 'parent',
        logo: {name , symbol, image: logo},
        vaultNFT: {type: 'coinName'},
        token: toChecksumAddress(item.token),
        children: erc20DetailData,
        balance: {amount: parseFloat(utils.formatUnits(erc20Balance, decimals)).toFixed(6), parent: true},
        
      });
  }
  });
  return erc20TableData;
};

  const withdrawBalance = async () => {
    setLoadingWithdrawBalance(true);
    const args = [selectedBalance.map(item => item.name)];
    try {
      checkBridgeConnection(signer);
      await tx(writeContracts.CarapaceSmartVault.withdrawBeneficiaryBalance(...args));            
      setDoneFetch(false);
      setSelectedBalance([]);
    } catch (err) {
      console.error("Error withdrawing balance: ", err);
    }
    setLoadingWithdrawBalance(false);
  };

  const onlyChildren = (item) => {
    return item.type === 'children' ? true : false;
  };

  const withdrawERC20 = async () => {
    setLoadingWithdrawERC20(true);
    const args = [ selectedERC20.filter(onlyChildren).map(item => [item.name, item.token]) ];
    try {
      checkBridgeConnection(signer);
      await tx(writeContracts.CarapaceSmartVault.withdrawBeneficiaryFungibleAssets(...args));            
      setDoneFetch(false);
      setSelectedERC20([]);
    } catch (err) {
      console.error("Error withdrawing tokens: ", err);
    }
    setLoadingWithdrawERC20(false);
  };

  const withdrawNFT = async () => {
    setLoadingWithdrawNFT(true);
    const args = [ selectedNFT.map(item => [item.vaultId, item.address, item.tokenId]) ];
    try {
      checkBridgeConnection(signer);
      await tx(writeContracts.CarapaceSmartVault.withdrawBeneficiaryNonFungibleAssets(...args));            
      setDoneFetch(false);
      setselectedNFT([]);
      setEnabled(0);
    } catch (err) {
      console.error("Error withdrawing NFT: ", err);
    }
    setLoadingWithdrawNFT(false);
  };

  const rowSelectionBalance = {
    selectedRowKeys: selectedBalance.map(item => item.name),                      
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedBalance(selectedRows);
    },
  };

  const rowSelectionERC20 = {
    selectedRowKeys: selectedERC20.map(item => item.key),                      
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedERC20(selectedRows);
    },
  };

  const removeCheck = (item, vaultId, address, tokenId) => {
    return (item.vaultId !== vaultId ||
            item.address !== address || 
            item.tokenId !== tokenId );
  }

  const selectorOnChange = (event, vaultId, address, tokenId) => {
    let newSelection = [...selectedNFT];

    if (event.target.checked) {
      newSelection.push({ vaultId, address, tokenId });
      setEnabled(enabled+1);
    } else {
      newSelection = selectedNFT.filter((item) => removeCheck(item, vaultId, address, tokenId));
      setEnabled(enabled-1);
    }
    setselectedNFT(newSelection);
  };

  const removeZeros = (item) => {
    return (item.balance !== 0);
  }

  function ShowBalance() {
    
    const balance = resource?.balance.read();

    let balanceList = [];
    let balanceDetail = [];
    let totalBalance = BigInt(0);

    if (!!balance) {
      balanceDetail = balance.filter(removeZeros);
      if (balanceDetail.length > 0) {
        
        balanceDetail.forEach((item) => {
          totalBalance += BigInt(item.balanceBigInt);
        });

        balanceList.push({
          balance: parseFloat(utils.formatEther(totalBalance)).toFixed(6),
          children: balanceDetail
        });
      }
    }

    return (
      <>
        <div className="div-header">
          <Row>
            <Col>
              <Avatar size='large' shape='square' icon={<BankOutlined/>} style={{marginTop: 3}}  />
            </Col>
            <Col>
              <Row>
                <Text style={{marginLeft: 20}} type='secondary'>Available to withdraw</Text>
              </Row>
              <Row>
                <Text style={{marginLeft: 20}} type='primary'>{parseFloat(utils.formatEther(totalBalance)).toFixed(6)} {NETWORK(selectedChainId)?.symbol}</Text>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{marginTop:30}}>
          { balanceDetail.length
            ?  <Table className="ant-table-content"
                //style={{margin: 10 }}  
                rowKey='name'
                dataSource={balanceDetail} 
                columns={columnsBalance}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionBalance,
                  checkStrictly: false
                }}
                pagination={{ 
                  pageSize: balanceDetail.length,
                  position: ['none']
                }}
              />
          : <Empty description={<Text type='secondary'>No funds received</Text>} image={emptyWallet} 
              imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
            />
          }
        </div>
        { balanceDetail.length > 0 &&
          <div style={{marginTop: 30}}>
            <Button 
              className="ant-button-carapace"
              type="primary" 
              disabled={!selectedBalance.length} 
              loading={loadingWithdrawBalance} 
              onClick={() => withdrawBalance()}>
                  Withdraw
            </Button>
          </div>
        }
      </>
    );
  }

  function ShowERC20() {
    const erc20List = resource?.erc20.read();
    let tableData = [];
    if (!!erc20List){
      let toTableData = [];
      erc20List.forEach(item => {
        item.forEach(subItem => {
          toTableData.push({
            ...subItem
          });
        })  
      })
      tableData = generateTableData(toTableData);
    }
    return ( 
          tableData.length
            ? (
                <>
                  <Table 
                    className="ant-table-content"
                    //style={{margin: 10 }}
                    expandRowByClick  
                    dataSource={tableData} 
                    columns={columnsERC20}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelectionERC20,
                      checkStrictly: false
                    }}
                    pagination={{ 
                      pageSize: tableData.length,
                      position: ['none']
                    }}
                  />
                  <div style={{marginTop: 30}}>
                    <Button 
                      className="ant-button-carapace"
                      type="primary" 
                      disabled={!selectedERC20.length} 
                      loading={loadingWithdrawERC20} 
                      onClick={() => withdrawERC20()}
                    >
                      Withdraw
                    </Button>
                  </div>
                </>
              )
            : <Empty description={<Text type='secondary'>No tokens received</Text>} image={emptyWallet} 
                      imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
              />
    )
  };

  function ShowNFT() {
    const nftList = resource?.nft.read();
    let nftData = [];
    if (!!nftList){
      let list = [];
      nftList.forEach(item => {
        item.forEach(subItem => {
          list.push({
            ...subItem
          });
        })  
      })
      nftData = list.sort((a, b) => a.address.localeCompare(b.address) || a.tokenId - b.tokenId );
    }
    return (
            nftData.length 
        ? (
            <>
                <List
                  align="center"
                  grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
                  dataSource={nftData}
                  renderItem={item => (
                    <List.Item className="scan-nft" >
                      <NFTCard
                        vaultId={item.vaultId}
                        address={item.address}
                        tokenId={item.tokenId} 
                        selector 
                        selectorText={'Withdraw'} 
                        title={item.symbol} 
                        description={item.name.length < 28
                          ? `${item.name}`
                          : `${item.name.substring(0, 25)}...`}
                        src={item.image} 
                        additional={tokenIdLink(item)}
                        selectorOnChange={selectorOnChange}
                        isChecked={selectedNFT.some(e =>
                          e.vaultId === item.vaultId &&
                          e.address === item.address &&
                          e.tokenId === item.tokenId
                        )}
                      />
                    </List.Item>
                  )}
                  bordered={false}
                  pagination={{ 
                    onChange: page => {
                      scrollToTop();
                    },
                    pageSize: 16 
                  }}
                />
                <div style={{marginTop: 5}}>
                  <Button 
                    className="ant-button-carapace"
                    type="primary" 
                    disabled={!enabled} 
                    loading={loadingWithdrawNFT} 
                    onClick={() => withdrawNFT()}
                  >
                    Withdraw
                  </Button>
                </div> 
              </>
            )
          : <Empty description={<Text type='secondary'>No NFT received</Text>} image={emptyWallet} 
                    imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
            />
    )
  }

  const tokenIdLink = ({tokenId, address, owner}) => {
    const shortOwnerAddress = owner.slice(0,6).trim() + '...' + owner.slice(-4).trim();
    return (
      <>
        <Row>
          <Text type='secondary' style={{marginRight: 5}}># {tokenId}</Text>
          <Text>
            <a href={`${NETWORK(selectedChainId).blockExplorer}token/${address}?a=${tokenId}`} target="_blank">
              <LinkOutlined key="setting" />
            </a>
          </Text>
        </Row>
        <Row style={{marginTop: 10, marginBottom: 8}}>
          <Text type='secondary'>Original owner</Text>
        </Row>
        <Row>
          <Blockies seed={owner.toLowerCase()} size={8} scale={3}  />
          <Text style={{marginLeft: 10}} copyable={{text: owner}}>{shortOwnerAddress}</Text>
        </Row>
      </>
    )
  };

  return fetchAddress !== address ? <Spin style={{marginTop:30}} /> 
  : (  
    <div className={windowSize?.mainContainer}>

      <div className="ant-div-carapace">
        <div className="div-header">
            <Row >
              <h2>Balance</h2>
              <AdditionalInfo info={
                <>
                  <p>Deposit amounts available to withdraw from each executed vault where you were listed as a beneficiary.</p>
                  <p>Select all to withdraw at once to optimize transaction fees.</p>
                </>
              }/>
            </Row>
        </div>
        <Suspense 
          fallback={
            <div style={{margin:30}}>
              <Row>
                <Skeleton active avatar size='small' shape='square'/>
              </Row>
              <Divider />
              <Row>
                <Skeleton active size='small' shape='square'/>
              </Row>
            </div>
          }
        > 
          <ShowBalance />
        </Suspense>
      </div>
        
      <br/>
      <br/>
      <div className="ant-div-carapace">
        <div className="div-header">
          <Row >
            <h2>Cryptocurrency</h2>
            <AdditionalInfo info={
              <>
                <p>Tokens available to withdraw from each executed vault where you were listed as a beneficiary.</p>
                <p>Select as many as you want to withdraw to optimize transaction fees.</p>
              </>
            }/>
          </Row>
          <Row>
            <Text type='secondary'>Tokens available to withdraw</Text>
          </Row>
        </div>
        <div style={{marginTop:30}}>
          <Suspense fallback={
                      <>
                      <Row>
                        <Skeleton active avatar size='small' shape='square'/>
                      </Row>
                      <Divider />
                      <Row>
                        <Skeleton active avatar size='small' shape='square'/>
                      </Row>
                      </>
                    } 
          >
            <ShowERC20 />
          </Suspense>
        </div>
      </div>
      <br/>
      <br/>
      <div className="ant-div-carapace">
        <div className='div-header'>
            <Row >
              <h2>NFT</h2>
              <AdditionalInfo info={
                <>
                  <p>NFTs available to withdraw from each executed vault where you were listed as a beneficiary.</p>
                  <p>Select all NFTs you want to optimize transaction fees.</p>
                </>
              }/>
            </Row>
            <Row>
              <Text type='secondary'>NFT available to withdraw</Text>
            </Row>
          </div>
          <div className='received-nfts'>
            <Suspense fallback={
                        <div style={{margin: 30}}>
                          <Row gutter={32}>
                            <Col span={6}>
                              <Card style={{height: 400}}>
                                <Skeleton active avatar size='small' shape='square'/>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card style={{height: 400}}>
                                <Skeleton active avatar size='small' shape='square'/>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card style={{height: 400}}>
                                <Skeleton active avatar size='small' shape='square'/>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card style={{height: 400}}>
                                <Skeleton active avatar size='small' shape='square'/>
                              </Card>
                            </Col>
                          </Row>
                        </div>           
                      } 
            >
              <ShowNFT />
            </Suspense>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
}