import React, {useEffect} from "react";
import { Input, Row } from 'antd';
import { DynamicFormContacts, AdditionalInfo } from ".";
import "../App.css";

export default function CustomizeContacts({
  ownerContacts,
  selectedOwnerContacts,
  beneficiariesContacts,
  selectedBeneficiariesContacts,
  trusteesContacts,
  selectedTrusteesContacts,
  checkContacts
}) {
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      //checkContacts(false);
      let ownerChecked = false;
      let beneficiariesChecked = false;
      let trusteesChecked = false;

      if (ownerContacts === "" || ownerContacts === null || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ownerContacts)) {
        // console.log("Values after test:", ownerContacts);
        ownerChecked = true;
      } else ownerChecked = false;
      
      beneficiariesContacts.forEach(element => {
        if (element.email === "" || element.email === null  || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element.email)) {
          beneficiariesChecked = true;
        } else beneficiariesChecked = false;
      });
      
      trusteesContacts.forEach(element => {
        if (element.email === "" || element.email === null ||   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element.email)) {
          trusteesChecked = true;
        } else trusteesChecked = false;
      });

      // console.log("ownerChecked:", ownerChecked);
      // console.log("benefChecked:", beneficiariesChecked);
      // console.log("trustChecked:", trusteesChecked);
      
      if(ownerChecked && beneficiariesChecked && trusteesChecked) 
        checkContacts(true); 
      else 
        checkContacts(false);

    }, [ownerContacts, beneficiariesContacts, trusteesContacts]);

    const handleChangeOwnerContacts = (event) => {
      // let values = [...ownerContacts];
      let values = event.target.value;
      // console.log("Values before test:", values);
      selectedOwnerContacts(values);
    };

  return (
    <>
      <div className="ant-div-carapace" style={{marginTop: 30}}>
        <div className="div-header">
        {/* <p>Note that contacts are optional but important to heartbeat protocol notifications. They won't be directly associated to any address...</p>
        <br/> */}
        {/* <Divider orientation="left" plain>Owner</Divider> */}
          <Row>
            <h2>Owner</h2>
            <AdditionalInfo info={
              <>
                <p>Get notifications of your vault state such as on Create, Cancel, Unlock and Execute.</p>
                <p>Contacts will NOT be linked with any account address.</p>
                <p>This information is optional but helpful to monitor vault's lifecycle.</p>
              </>
            }/>
          </Row>
        </div>
        <Input 
                value={ownerContacts}
                name="owner"
                onChange={event => handleChangeOwnerContacts(event)} 
                placeholder="Owner e-mail"
                allowClear={true}
                style={{ width: '50%'}}/>
      </div>

      <div className='ant-div-carapace' style={{marginTop: 30}}>
        <div className="div-header">
          <Row>
            <h2>Beneficiaries</h2>
            <AdditionalInfo info={
              <>
                <p>Beneficiaries will be notified if a vault is Executed.</p>
                <p>Contacts will NOT be linked with any account address.</p>
                <p>This information is optional but helpful to get beneficiary awareness.</p>
              </>
            }/>
          </Row>
        </div>
        {/* <Divider orientation="left" plain>Beneficiaries</Divider> */}
      {/* </div>
      <div> */}
        <DynamicFormContacts actor='Beneficiary' stateValues={beneficiariesContacts} selectedActors={selectedBeneficiariesContacts} />  
      </div>

      <div className='ant-div-carapace' style={{marginTop: 30}}>
        {/* <Divider orientation="left" plain>Trustees</Divider> */}
      {/* </div>
      <div className='customize-titles'> */}
        <div className="div-header">
          <Row>
            <h2>Trustees</h2>
            <AdditionalInfo info={
              <>
                <p>Trustees will be notified on your vault actions such as on Create, Cancel and Execute.</p>
                <p>Contacts will NOT be linked with any account address.</p>
                <p>This information is optional but helpful to monitor vault's lifecycle.</p>
              </>
            }/>
          </Row>
        </div>
        <DynamicFormContacts actor='Trustee' stateValues={trusteesContacts} selectedActors={selectedTrusteesContacts} />
      </div>
    </>
  )
}