import React, {useEffect, useState, useContext, Suspense } from "react";
import { Spin, Skeleton, Col, Row, Switch as SwitchAntd, Typography, Empty } from "antd";
import { utils } from "ethers";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Web3Context } from "../context/web3Details";
import { ManageSmartVault, SmartVaultCard } from './';
import { fetchSmartVaultTrustee }  from "../helpers/fetchSmartVaultTrustee";
import { ScreenSize } from "../context/screenSize";
import Blockies from "react-blockies";
import logoGrey from "../logo_grey_hexagon.png";
import { NETWORK } from "../constants";

const { Text, Paragraph } = Typography;

let resource;

export default function TrustedSmartVaults() {

  const { address, tx, readContracts, selectedChainId, refresh, activateRefresh } = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);
  let { path,url } = useRouteMatch();
  const [myVaults, setMyVaults] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [toogle, setToogle] = useState(true);
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');

  const removeZeros = (item) => {
    return (item > 0);
  }

  useEffect(() => {
    if ( address !== currentAddress || selectedChainId !== currentNetwork || refresh ){
      // console.log("REFRESH!!!");
      setFetch(false);
    }
  },[address, selectedChainId, refresh]);

  useEffect(() => {
    const getMySmartVaults = async () => {
      if (NETWORK(selectedChainId)?.fullFeatures){ //remove or add when the smart contract is deployed on more networks
        if (!fetch && address && tx && Object.entries(readContracts).length !== 0 && selectedChainId){
            try {
                const response = await tx(readContracts.CarapaceStorage.getVaultsOfTrustee(address));;
                const vaultsOfTrustee = response.map(item => item.toNumber()).filter(removeZeros).sort((a, b) => a - b);
                setMyVaults(vaultsOfTrustee);
                resource = fetchSmartVaultTrustee(vaultsOfTrustee, tx, readContracts);
                setFetch(true);
                setCurrentAddress(address);
                setCurrentNetwork(selectedChainId);
                activateRefresh(false);
              } 
            catch (err) {
              setMyVaults([]);
            }
          }
        } else { //remove all else
          resource = null;
          setMyVaults([]);
          setFetch(true);
          setCurrentAddress(address);
          setCurrentNetwork(selectedChainId);
          activateRefresh(false);
      }
    }
    getMySmartVaults();

  },[fetch, address, refresh, readContracts]);

  const formatBalance = (balance) => {
    if (balance == 0){
      return balance;
    } else {
      const decimal = balance.split('.');
      if (decimal[0] != 0){
        return parseFloat(balance).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(balance).toFixed(fixedDecimals);
      }
    }
  }

  function ShowBalance({index}) {
    const balance = resource?.balance.read();
    return <Text type='primary'>{formatBalance(utils.formatEther(balance[index][0]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  const formatReward = (reward) => {
    if (reward == 0){
      return reward;
    } else {
      const decimal = reward.split('.');
      if (decimal[0] != 0){
        return parseFloat(reward).toFixed(2);
      } else {
        const fixedDecimals = decimal[1].search(/[1-9]/) + 2;
        return parseFloat(reward).toFixed(fixedDecimals);
      }
    }
  }

  function ShowReward({index}) {
    const reward = resource?.reward.read();
    return <Text type='primary'>{formatReward(utils.formatEther(reward[index]))} {NETWORK(selectedChainId)?.symbol} </Text>;
  }

  function ShowImage({index}) {
    const image = resource?.image.read();

    const base64String = image[index].toString().replace("data:application/json;base64,","");
    const decoded = Buffer.from(base64String, 'base64').toString();
    const imageUrl = JSON.parse(decoded).image;

    return <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>;
  }

  function ShowOwner({index}) {
    const owner = resource?.owner.read();
    const shortOwnerAddress = owner[index].slice(0,6).trim() + '...' + owner[index].slice(-4).trim();
    return (
      <Row>
        <Blockies seed={owner[index].toLowerCase()} size={8} scale={3}  />
        {/* <Tooltip placement="bottomLeft" title={owner[index]}> */}
          <Paragraph 
            //ellipsis={{ suffix: owner[index].slice(-4).trim()}} 
            style={{marginLeft: 10, width: 120 }} 
            //copyable
          >
            {shortOwnerAddress}
          </Paragraph>
        {/* </Tooltip> */}
      </Row>
    )
  }

  const isClosed = (item) => {
    return (item.status == '0' || item.status == '1');
  }

  const hideClosedVaults = () => {
    const myVaultsFiltered = myVaults.filter(isClosed);
    setMyVaults(myVaultsFiltered);
  };

  const getClosedVaults =  () => {

  };

  const handleSwitch = () => {
    toogle ? hideClosedVaults() : getClosedVaults()
    setToogle(!toogle);
  };

  function ShowSmartVaults() {

    const states = resource?.states.read();

    return (
      <>
      <div className="steps">
        <Row  gutter={64}>
        {myVaults?.map((item, index) => (
           <Col key={item} className="vault-cards" span={windowSize.vaultSpan || 8 } style={{marginTop: 20, marginBottom: 100, display: "flex", justifyContent: "space-evenly" }}>
            <SmartVaultCard
                key={item} 
                vaultId={item}
                status={!!states && states[index]}
                url={`${url}/${item}`}
                balance={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowBalance index={index} /></Suspense>}
                reward={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowReward index={index} /></Suspense>}
                image={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowImage index={index} /></Suspense>}
                owner={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowOwner index={index} /></Suspense>}
            />
          </Col>
        ))}
        </Row>
        { myVaults?.length == 0 && fetch &&
              <div className="ant-div-carapace" style={{marginTop: "10%"}}>
                <Empty description="No trusted vaults yet." image={logoGrey} />
              </div>
          }
      </div>
      </>
    );
  }

  return (
    <Switch >
      <>
        <Route exact path={path}>
          {/* Waiting for backlog development */}
          {/* <div className="toogle">
            <SwitchAntd size='small' checked={toogle} onChange={() => handleSwitch()} style={{marginTop: 3}}/>
            <Text type='secondary' style={{marginLeft: 10}}>Show closed vaults</Text>
          </div>           */}
          <Suspense fallback={<Spin style={{marginTop:30}} />}>
            <ShowSmartVaults />
          </Suspense>
        </Route>
        <Route path={`${path}/:vaultId`}>
          <ManageSmartVault />
        </Route>
      </>
    </Switch>
  );
}