import React, { useState, useEffect, useContext, Suspense }  from "react";
import { Modal, Skeleton, Card, Col, Row, Button, Avatar, Empty, List, Typography, Space, Form, Input, Tooltip, Alert } from "antd";
import { useParams, useHistory, Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { EditSteps } from '../components';
import {
        ArrowLeftOutlined, 
        EditOutlined, 
        StopOutlined, 
        PlusOutlined, 
        MinusOutlined, 
        MinusSquareOutlined, 
        PlusSquareOutlined, 
        GiftOutlined,
        WarningOutlined,
        LockOutlined,
        UnlockOutlined,
        ExportOutlined,
        BankOutlined,
        SketchOutlined,
        LinkOutlined,
        TwitterOutlined
      } from '@ant-design/icons';
import { utils } from "ethers";
import Blockies from "react-blockies";
import { checkBridgeConnection } from "../helpers/checkConnectionType";
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { Web3Context } from "../context/web3Details";
import { ScreenSize } from "../context/screenSize";
import { magenta } from '@ant-design/colors';
import { NETWORK } from "../constants";
import { fetchSmartVaultDetail } from "../helpers/fetchSmartVaultDetail";
import { ManageSmartVaultCard, NFTCard, AdditionalInfo } from './';
import emptyWallet from '../emptyWallet.png';

const { confirm } = Modal;
const { Paragraph, Text } = Typography;

let resource;

export default function ManageSmartVault() {

  const { selectedChainId, address, readContracts, writeContracts, tx, signer, price, refresh, activateRefresh, selectedNetwork } 
    = useContext(Web3Context);
  const { windowSize } = useContext(ScreenSize);

  let { path,url } = useRouteMatch();
  let { vaultId } = useParams();
  const history = useHistory();
  const actualRoute = url.split("/")[1];

  const vaultStatus = ['Active', 'Waiting unlock', 'Canceled', 'Executed'];

  const [loadingClaimRewards, setLoadingClaimRewards] = useState(false);
  const [claimDisabled, setClaimDisabled] = useState(true);
  const [rewards, setRewards] = useState('');
  const [form] = Form.useForm()

  const [infoStatus, setInfoStatus] = useState('');
  const [infoBalance, setInfoBalance] = useState(0);
  const [infoReward, setInfoReward] = useState(0);
  const [infoImage, setInfoImage] = useState('');
  const [infoERC20, setInfoERC20] = useState([]);
  const [infoNFT, setInfoNFT] = useState([]);
  const [infoTrustee, setInfoTrustee] = useState([]);
  const [infoIncentive, setInfoIncentive] = useState(0);
  const [infoBeneficiary, setInfoBeneficiary] = useState([]);
  const [infoLockDays, setInfoLockDays] = useState(0);
  const [infoModel, setInfoModel] = useState(0);

  const [okButtonAdd, setOkButtonAdd] = useState(false);
  const [cancelButtonAdd, setCancelButtonAdd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if ( address && tx && Object.entries(readContracts).length !== 0){
      activateRefresh(false);
      resource = fetchSmartVaultDetail(    
        vaultId, 
        tx, 
        readContracts, 
        setVaultInfoStatus,
        setVaultInfoBalance,
        setVaultInfoReward,
        setVaultInfoImage,
        setVaultInfoERC20,
        setVaultInfoTrustee,
        setVaultInfoIncentive,
        setVaultInfoBeneficiary,
        setVaultInfoLockDays,
        setVaultInfoModel,
        setVaultInfoNFT,
        selectedChainId,
        signer
      );
    } 
  }, [address, readContracts, refresh]);

  const setVaultInfoStatus = (value) => {
    setInfoStatus(value);
  };

  const setVaultInfoBalance = (value) => {
    setInfoBalance(value);
  };

  const setVaultInfoReward = (value) => {
    setInfoReward(value);
  };

  const setVaultInfoImage = (value) => {
    setInfoImage(value);
  };

  const setVaultInfoERC20 = (value) => {
    setInfoERC20(value);
  };

  const setVaultInfoTrustee = (value) => {
    setInfoTrustee(value);
  };

  const setVaultInfoIncentive = (value) => {
    setInfoIncentive(value);
  };

  const setVaultInfoBeneficiary = (value) => {
    setInfoBeneficiary(value);
  };

  const setVaultInfoLockDays = (value) => {
    setInfoLockDays(value);
  };

  const setVaultInfoModel = (value) => {
    setInfoModel(value);
  };

  const setVaultInfoNFT = (value) => {
    setInfoNFT(value);
  };

  const handleCancel = () => {
    setIsWithdrawModalVisible(false);
    setIsAddModalVisible(false);
    form.resetFields();
}

  const getMaxWithdrawAllowed = () => {
    return infoBalance - NETWORK(selectedChainId).minDepositAmount;
  }

  const getMaxAddAllowed = () => {
    return NETWORK(selectedChainId).maxDepositAmount - infoBalance;
  }

  function CancelContent() {
    const balance = resource?.balance.read();
    const reward = resource?.reward.read();

    if (infoModel === 2) {
    return (
        <div style={{marginTop:20}}>
          <p>This action will withdraw your deposited funds and claim the earned rewards to your account.</p>
          {!!balance && <h3>Withdraw: {parseFloat(utils.formatEther(balance[0])).toFixed(6)} {NETWORK(selectedChainId)?.symbol} </h3>}
          {!!reward && <h3>Rewards: {parseFloat(utils.formatEther(reward)).toFixed(6)} {NETWORK(selectedChainId)?.symbol} </h3>}
          <br/>
          <h5>Processing Fee: 10% </h5>
        </div>
      )
    } else { 
      return (
        <div style={{marginTop:20}}>
          <p>This action will stop the rules from executing on your behalf.</p>
          <p>Currently it is not possible to undo this action.</p>
          <br/>
        </div>
      )
    }
  };

  function WithdrawContent() {
    return (<>
      <Row style={{marginTop:20, marginBottom:10}}>
      <Text type='secondary' >Maximum amount allowed: {getMaxWithdrawAllowed()} {NETWORK(selectedChainId)?.symbol} </Text>
    </Row>
    <Row style={{marginBottom:30}}>
      <Text type='secondary' >Processing fee: 2%</Text>
    </Row>

    <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} id='withdrawFundsForm'>
      <Form.Item 
        name="inputWithdraw" 
        label="Amount" 
        validateTrigger='onBlur'
        rules={[
          { 
            required: true, 
            message: "Please insert an amount to withdraw",
          },
          () => ({
            validator(_, value) {
              if (value > getMaxWithdrawAllowed()) {
                return Promise.reject("Amount not allowed");
              }
              return Promise.resolve();
            },
          }),
        ]}>
          <Input id='withdrawAmount' />
      </Form.Item>
    </Form>
    </>
    )
  };

  function AddContent() {
    return (<>
      <Row style={{marginTop:20, marginBottom:10}}>
      <Text type='secondary' >Current deposit balance: {infoBalance} {NETWORK(selectedChainId)?.symbol}</Text>
    </Row>
    <Row style={{marginTop:20, marginBottom:10}}>
      <Text type='secondary' >Maximum amount allowed: {getMaxAddAllowed()} {NETWORK(selectedChainId)?.symbol}</Text>
    </Row>
    <Row style={{marginBottom:30}}>
      <Text type='secondary' >Processing fee: 0%</Text>
    </Row>

    <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} id='addFundsForm'>
      <Form.Item 
        name="inputAdd" 
        label="Amount" 
        validateTrigger='onBlur'
        initialValue={''}
        rules={[
          { 
            required: true, 
            message: "Please insert an amount to add",
          },
          () => ({
            validator(_, value) {
              if (value > getMaxAddAllowed()) {
              //  setOkButtonAdd(true);
                return Promise.reject("Amount not allowed");
              }
            //  setOkButtonAdd(false);
              return Promise.resolve();
            },
          }),
        ]}>
          <Input id='addAmount' />
      </Form.Item>
    </Form>
    </>
    )
  };

  const stopExecutionContent = (
    <div style={{marginTop:20}}>
      <p>This action will stop the intention of the trustee to execute the vault.</p>
      <br/>
      <h5>Processing Fee: 0% </h5>
    </div>
  );
  
  const updateAddContacts = (status) => {
    const addVaultContact = httpsCallable(functions, 'addContact');
    console.log("manage.UpdatingContacts", selectedNetwork, vaultId, status);
    addVaultContact({
      collection: selectedNetwork,
      vaultid: vaultId,
      status: status
     })
     .then((response) => {
       if (!response.data.result) {
         console.error("Contacts not updated");
        }   
     })
     .catch((err) => {
         console.error("Contacts not updated. Error: ", err);
     });
    }

  const updateInfo = (status) => {
  //  setFetch(false);
    updateAddContacts(status);
    activateRefresh(true);
  }

  const cancelSmartVault = () => {
    confirm({
      title: 'Do you want to cancel the Smart Vault?',
      icon: <StopOutlined style={{ color: magenta[5]}} />,
      content: <Suspense><CancelContent /></Suspense> ,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      width: 500,
      confirmLoading: true,
      
      onOk() {
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.cancelSmartVault(vaultId))
          .then((response) => { 
            // console.log("manage.response", response);
            if(response) {
              updateInfo(vaultStatus.indexOf('Canceled').toString()); 
            }
          }));
        })
        // .then(() => { updateInfo(vaultStatus.indexOf('Canceled').toString()) })
        .catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    });
  };

  const withdrawFunds = async () => {
    confirm({
      title: 'Withdraw funds',
      icon: <MinusSquareOutlined />,
      content: <WithdrawContent /> ,
      centered: true,
      mask: true,
      maskClosable: true,
      okText: 'Withdraw',
      cancelText: 'Cancel',
      width: 500,

      onOk() {
        const withdrawAmount = document.getElementById('withdrawAmount').value;
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.withdrawFunds(vaultId, utils.parseEther(withdrawAmount))));
        })
        .then(() => { 
        //  setFetch(false);
          activateRefresh(true);
          document.getElementById("withdrawFundsForm").reset();
        })
        .catch(err => console.error('Withdraw funds error: ', err));
      },
      onCancel() {}
    });
  };

  const addFunds = async () => {
    confirm({
      title: 'Add funds',
      icon: <PlusSquareOutlined />,
      content: <AddContent /> ,
      centered: true,
      mask: true,
      maskClosable: true,
      okText: 'Add',
      cancelText: 'Cancel',
      width: 500,
      okButtonProps:  { disabled: okButtonAdd },
      cancelButtonProps: { disabled: cancelButtonAdd },

      onOk() {
        const addAmount = document.getElementById('addAmount').value;
        if (addAmount > NETWORK(selectedChainId).maxDepositAmount - infoBalance) {
          
          return null;
        }
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.addFunds(vaultId, {value: utils.parseEther(addAmount)})));
        })
        .then(() => { 
          activateRefresh(true);
          document.getElementById("addFundsForm").reset();
        //  setFetch(false); 
        })
        .catch(err => console.error('Add funds error: ', err));
      },
      onCancel() {}
    });
  };

  const stopExecution = () => {
    confirm({
      title: 'A trustee requested the execution of the smart vault!',
      icon: <WarningOutlined />,
      content: stopExecutionContent ,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      width: 500,
      
      onOk() {
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.stopRequest(vaultId))
          .then((response) => { 
            // console.log("manage.response", response);
            if(response) {
              updateInfo(vaultStatus.indexOf('Active').toString()); 
            }
          }));
        })
        // .then(() => { updateInfo(vaultStatus.indexOf('Active').toString()) })
        .catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    });
  };

  const claimRewards = async () => {
    setLoadingClaimRewards(true);
    try {
      checkBridgeConnection(signer);
      await tx(writeContracts.CarapaceSmartVault.ownerClaimRewards(vaultId, utils.parseEther(infoReward)));
      activateRefresh(true);
    } catch(err) {
      console.error('Claim Rewards error: ', err);
    } 
    setLoadingClaimRewards(false);
  };

  const unlockContent = (
    <div style={{marginTop:20}}>
      <p>This action will unlock the vault during a time frame. In that period, the original Owner can revert this action.</p>
      <br/>
    </div>
  );

  const executeContent = (
    <div style={{marginTop:20}}>
      <p>This action will execute this vault and transfer tokens as configured by original Owner.</p>
      <br/>
    </div>
  );

  const unlockSmartVault = async () => {
    confirm({
      title: 'Do you want to unlock this Smart Vault?',
      icon: <UnlockOutlined />,
      content: unlockContent,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      width: 500,
      
      onOk() {
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.requestExecution(vaultId))
          .then((response) => { 
            // console.log("manage.response", response);
            if(response) {
              updateInfo(vaultStatus.indexOf('Waiting unlock').toString()); 
            }
          }));
        })
        // .then(() => { updateInfo(vaultStatus.indexOf('Waiting unlock').toString()) })
        .catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    });
  };

  const executeSmartVault = async () => {
    confirm({
      title: 'Do you want to execute this Smart Vault?',
      icon: <UnlockOutlined />,
      content: executeContent,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      width: 500,
      
      onOk() {
        return new Promise((resolve, reject) => {
          checkBridgeConnection(signer);
          resolve(tx(writeContracts.CarapaceSmartVault.executeSmartVault(vaultId))
          .then((response) => { 
            // console.log("manage.response", response);
            if(response) {
              updateInfo(vaultStatus.indexOf('Executed').toString()); 
            }
          }));
        })
        // .then( async () => { updateInfo(vaultStatus.indexOf('Executed').toString()) })
        .catch(() => console.log('Oops errors!'));
      },
      onCancel() {}
    });
  };

  function ShowImage() {
    const image = resource?.image.read();

    if (!!image) {
      const base64String = image.toString().replace("data:application/json;base64,","");
      const decoded = Buffer.from(base64String, 'base64').toString();
      const imageUrl = JSON.parse(decoded).image;
  
      return <img alt="nft" style={{ height: "100%" }} src={imageUrl}/>;
    } else return null;
  }

  function ShowBalance() {
    let balance = [];
    balance = resource?.balance.read();

    return (
      <>
      { !!balance &&
        <Row>
          <Text style={{marginLeft: 20}} type='primary'>
            {utils.formatEther(balance[0])} {NETWORK(selectedChainId)?.symbol}
          </Text>
        </Row>
      }
      </>
    )
  }

  function ShowReward() {
    let reward = [];
    reward = resource?.reward.read();
    return (
      <>
      { !!reward &&
        <Row>
          <Text style={{marginLeft: 20}} type='primary'>
            {utils.formatEther(reward)} {NETWORK(selectedChainId)?.symbol}
          </Text>
        </Row>
      }
      </>
    )
  }

  // function getTimer() {
  //   const vaultInfo = resource?.vaultInfo.read();
  //   const state = resource?.state.read();

  //   if (state !== null && vaultInfo !== null && vaultStatus[state] == 'Waiting unlock'){
  //     const requestExecTS = vaultInfo[2].toNumber();
  //     const lockDays = vaultInfo[3].toNumber();
  //     // If adds 1 day in seconds 86400 (why?) - should multiply by 1 day in production -  and multiplies for 1000 (milliseconds)
  //     let date;
  //     NETWORK(selectedChainId)?.testnet
  //     ? date = new Date((requestExecTS + lockDays)*1000) // multiplies for 1000 (milliseconds)
  //     : date = new Date((requestExecTS + (lockDays * 86400))*1000); // multiplies for 86400 the get time in days

  //     console.log("getTimer.date", date)
  //     return date;
  //   }
  // }

  // function ShowTimer() {
  //     const date = getTimer();
  //     console.log("showTimer.date", date)
  //     return (        
  //       <div style={{marginLeft:10, marginTop:30}}>
  //         <Text type="warning">Ready for execution at: {date.toUTCString()}</Text>
  //       </div>
        
  //     ) ;
  //   }

  function ShowTimer() {
    const vaultInfo = resource?.vaultInfo.read();
    const state = resource?.state.read();

    if (state !== null && vaultInfo !== null && vaultStatus[state] == 'Waiting unlock'){
      const requestExecTS = vaultInfo[2].toNumber();
      const lockDays = vaultInfo[3].toNumber();
      // If adds 1 day in seconds 86400 (why?) - should multiply by 1 day in production -  and multiplies for 1000 (milliseconds)
      let date;
      NETWORK(selectedChainId)?.testnet
      ? date = new Date((requestExecTS + lockDays)*1000) // multiplies for 1000 (milliseconds)
      : date = new Date((requestExecTS + (lockDays * 86400))*1000); // multiplies for 86400 the get time in days
      
      return (        
        <div style={{marginLeft:10, marginTop:30}}>
          <Text type="warning">Ready for execution at: {date.toUTCString()}</Text>
        </div>
        
      ) ;
    }
  }

  function ShowTrustees() {
    const vaultInfo = resource?.vaultInfo.read();

    return ( 
      <Row>
        <Col span={12}>
          { !!vaultInfo && 
              vaultInfo[1].map(item => (
                <Row key={item} style={{padding:5}}>
                  <Col style={{display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
                    <Blockies seed={item.toLowerCase()} size={8} scale={3}  />
                    <Text style={{marginLeft: 10 }} copyable={{ text: item }} >
                      {item.slice(0,6).trim() + '...' + item.slice(-4).trim()}
                    </Text>
                  </Col>
                </Row>
              ))
          }
        </Col>
        <Col span={12}>
          { infoModel === 2 &&
            <Row style={{marginBottom:10, marginLeft:'30%',  display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
              <Col>
                <Avatar size='large' shape='square' icon={<SketchOutlined/>} style={{marginTop: 4}}  />
              </Col>
              <Col>
                <Row>
                  <Text style={{marginLeft: 20}} type='secondary'>Incentive</Text>
                </Row>
                <Suspense>
                  <ShowIncentive />
                </Suspense>
              </Col>
            </Row>
          }
          <Row style={{marginLeft:'30%',  display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
            <Col>
              <Avatar size='large' shape='square' icon={<LockOutlined/>} style={{marginTop: 4}}  />
            </Col>
            <Col>
              <Row>
                <Text style={{marginLeft: 20}} type='secondary'>Time lock</Text>
              </Row>
              { !!vaultInfo &&
                <Row>
                  <Text style={{marginLeft: 20}} type='primary'>
                    {vaultInfo[3].toNumber()} days
                  </Text>
                </Row>
            }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  function ShowIncentive() {

    const trusteeIncentive = resource?.trusteeIncentive.read();
    return (
      <>
      { !!trusteeIncentive &&
        <Row>
          <Text style={{marginLeft: 20}} type='primary'>
            {trusteeIncentive.toNumber()} %
          </Text>
        </Row>
      }
      </>
    )
  }

  function ShowBeneficiaries() {

    let beneficiaries = [];
    const beneficiariesInfo = resource?.beneficiaries.read();

    if (!!beneficiariesInfo) {
      beneficiaries = beneficiariesInfo[0].map((item,index) => {
        return {
            address: item,
            percentage: beneficiariesInfo[1][index].toNumber()
        }
    });

    }

    return (
      <>
        <Row style={{ marginBottom:10}}>
          <Col span={18} style={{display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
            <Text type='secondary'>Beneficiaries</Text>
          </Col>
          <Col span={6} style={{display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
            <Text type='secondary'>Share</Text>
          </Col>
        </Row>
        
        { beneficiaries.map(item => (
           <Row key={item.address} style={{ padding:5}}>
            <Col span={18} style={{display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
              <Blockies seed={item.address.toLowerCase()} size={8} scale={3}  />
              <Text style={{marginLeft: 10 }} copyable={{ text: item.address }}>
                {item.address.slice(0,6).trim() + '...' + item.address.slice(-4).trim()}
              </Text>
            </Col>
            <Col span={6} style={{display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
              <Text type='secondary' style={{marginLeft: 5 }} >
                {item.percentage} %
              </Text>
            </Col>
           </Row>
         ))
        }
      </>
    )
  }

  function ShowERC20() {
    let tableData = [];
    // let symbol;
    // let name; 
    // let logo;
    const vaultInfo = resource?.vaultInfo.read();
    // console.log("Manage.showERC20.vaultInfo", vaultInfo);
    // console.log("Manage.showERC20.infoERC20", infoERC20);
    
    if (!!vaultInfo && infoERC20?.length > 0) {
      
      infoERC20.forEach(item => {
        // console.log("manage.item", item);
        // let selectedItem = testTokenList.tokens.find(elem => elem.address === item);
        // name = infoERC20?.name;
        // symbol = infoERC20?.symbol;
        // selectedItem ? logo = selectedItem.logoURI : logo = DEFAULT_ERC20_LOGO;
        // selectedItem ? symbol = selectedItem.symbol : symbol = DEFAULT_ERC20_SYMBOL;
        // selectedItem ? name = selectedItem.name : name = DEFAULT_ERC20_NAME;
        

        tableData.push({
          key: item.address,
          name:item.name,
          symbol: item.symbol, 
          image: item.logo,
        })
      });

    }
    return (
      tableData.length
      ? <>
          <div style={{marginLeft:'30%',  display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
            <Text type='secondary'>Tokens covered</Text>
          </div>
          
          { tableData.map(item => (
            <a key={item.key} href={`${NETWORK(selectedChainId).blockExplorer}address/${item.key}`} target="_blank">
              <Row style={{marginTop: 20, marginLeft:'30%',  display:'flex', flexGrow:1, justifyContent:'flex-start'}}>
                <Col>
                  <Avatar size='large' style={{marginTop: 3}} src={item.image}/>
                </Col>
                <Col>
                  <Row>
                    <Text style={{marginLeft: 20}} type='primary'>{item.symbol}</Text>
                  </Row>
                  <Row>
                    <Text style={{marginLeft: 20}} type='secondary'>{item.name}</Text>
                  </Row>
                </Col>
              </Row> 
              </a>
            ))
          }               
        </>
 
      : <Empty 
          description={
            <Text type='secondary'>
              <p>Smart Vault has no tokens selected.</p>
              <p>Beneficiaries will share the vault balance.</p>
            </Text>
          } 
          image={emptyWallet} 
          imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
        />
    )
  }

  const tokenIdLink = ({tokenId, address, beneficiary}) => {
    const shortBenefAddress = beneficiary.slice(0,6).trim() + '...' + beneficiary.slice(-4).trim();
    const correctAddress = address.split("_")[0];
    // console.log("Manage.newAddress", correctAddress);
    return (
      <>
        <Row>
          <Text type='secondary' style={{marginRight: 5}}># {tokenId}</Text>
          <Text>
            <a href={`${NETWORK(selectedChainId).blockExplorer}token/${correctAddress}?a=${tokenId}`} target="_blank">
              <LinkOutlined key="setting" />
            </a>
          </Text>
        </Row>
        <Row style={{marginTop: 10, marginBottom: 8}}>
          <Text type='secondary'>Beneficiary</Text>
        </Row>
        <Row>
          <Blockies seed={beneficiary.toLowerCase()} size={8} scale={3}  />
          <Text style={{marginLeft: 10}} copyable={{text: beneficiary}}>{shortBenefAddress}</Text>
        </Row>
      </>
    )
  };

  function ShowNFT() {
    const nftList = resource?.nft.read();
    let nftData = [];
    if (!!nftList){
      nftData = nftList.map(item => item)
                  .sort((a, b) => a.address.localeCompare(b.address) || a.tokenId - b.tokenId );
    }

    return (
            nftData.length 
        ? (
            <>
                <List
                  grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 4, xxl: 4 }}
                  dataSource={nftData}
                  renderItem={item => (
                    <List.Item className="scan-nft" style={{display:'flex', flexGrow:1, justifyContent:'center'}}>
                      <NFTCard
                        vaultId={item.vaultId}
                        address={item.address}
                        tokenId={item.tokenId} 
                        title={item.symbol} 
                        description={item.name.length < 28
                          ? `${item.name}`
                          : `${item.name.substring(0, 25)}...`}
                        src={item.image} 
                        additional={tokenIdLink(item)}
                      />
                    </List.Item>
                  )}
                  bordered={false}
                  pagination={{ 
                    onChange: page => {
                      scrollToTop();
                    },
                    pageSize: 16 
                  }}
                />
              </>
            )
          : <Empty description={<Text type='secondary'>Smart Vault has no NFT selected.</Text>} image={emptyWallet} 
                    imageStyle={{ height: 50, opacity: 0.4, marginBottom: 20 }}
            />
    )
  }

  function ShowOwner() {
    const owner = resource?.owner.read();
    let shortOwnerAddress;


    if(owner) {
      shortOwnerAddress = owner.slice(0,6).trim() + '...' + owner.slice(-4).trim();
    }

    return ( 
      <>
      {owner && 
        <Row>
          <Blockies seed={owner.toLowerCase()} size={8} scale={3}  />
          {/* <Tooltip placement="bottomLeft" title={owner[index]}> */}
            <Paragraph 
              //ellipsis={{ suffix: owner[index].slice(-4).trim()}} 
              style={{marginLeft: 10, width: 120 }} 
              copyable={{ text: owner }}
            >
              {shortOwnerAddress}
            </Paragraph>
          {/* </Tooltip> */}
        </Row>
      }
      </>
    )
  }

  const checkDisabled = () => {
    return infoReward == 0 ? true : false;
  }

  function ShowSmartVault() {
   
    const state = resource?.state.read();
    const owner = resource?.owner.read();

    let editable = false;
    let cancelable = false;
    let stoppable = false;
    let balanceUpdatable = false;
    let unlockable = false;
    let executable = false;
    let lockTimeElapsed = false;

    const vaultInfo = resource?.vaultInfo.read();
    if (state !== null && vaultInfo !== null && vaultStatus[state] == 'Waiting unlock'){
      // const date = getTimer();
      // console.log("showSmartVault.date", date)
      const vaultInfo = resource?.vaultInfo.read();
      const state = resource?.state.read();

      const requestExecTS = vaultInfo[2].toNumber();
      const lockDays = vaultInfo[3].toNumber();
      // If adds 1 day in seconds 86400 (why?) - should multiply by 1 day in production -  and multiplies for 1000 (milliseconds)
      let date;
      NETWORK(selectedChainId)?.testnet
      ? date = new Date((requestExecTS + lockDays)*1000) // multiplies for 1000 (milliseconds)
      : date = new Date((requestExecTS + (lockDays * 86400))*1000); // multiplies for 86400 the get time in days

      const now = new Date();
      
      if(date <= now){
        lockTimeElapsed = true;
        console.log("unlock possible")
      } else {
        // setLockTimeElapsed(false);
        console.log("Lock time period not elapsed yet");
      }
    }
    
    if (actualRoute === 'mysmartvaults' && owner === address || actualRoute === 'trustedsmartvaults' && infoTrustee.map(item => item.address).includes(address)) {
      if (state !== null) {
        if (actualRoute === 'mysmartvaults'){
          if (vaultStatus[state] == 'Active'){
            editable = true;
            balanceUpdatable = true;
            cancelable = true;
          } 
          //Waiting on backlog to allow the smart contract to edit a canceled vault
          // else if (vaultStatus[state] == 'Canceled') {
          //   editable = true;
          // } 
            else if (vaultStatus[state] == 'Waiting unlock') {
            stoppable = true;
          }
        } else if (actualRoute === 'trustedsmartvaults'){
          if (vaultStatus[state] == 'Active'){
            unlockable = true;
          } else if (vaultStatus[state] == 'Waiting unlock' && lockTimeElapsed) {
            executable = true;
          }
        } else error.log("Route not defined for actions.");
      };
    }

    return (
     <>
      <div className="top-buttons">
        { actualRoute === 'mysmartvaults'
          ? <Space>
              <Link to={() => `/${actualRoute}`}>
                <Tooltip title="back" placement="bottom">
                  <Button icon={<ArrowLeftOutlined />} />
                </Tooltip>
              </Link>
              <Link to={`${url}/edit`}>
                <Tooltip title="edit" placement="bottom">
                  <Button icon={<EditOutlined />} disabled={!editable}/>
                </Tooltip>
              </Link>
              <Tooltip title="cancel" placement="bottom">
                <Button icon={<StopOutlined />} disabled={!cancelable} onClick={cancelSmartVault} />
              </Tooltip>
              <Tooltip title="stop execution" placement="bottom">
                <Button icon={<WarningOutlined />} disabled={!stoppable} onClick={stopExecution} />
              </Tooltip>
              <Tooltip title="twitt" placement="bottom">
                <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fapp.carapace.io%2F&text=Hey%20frens!%20%F0%9F%91%8B%20%20%0a%0aI%27ve%20created%20a%20new%20Smart%20Vault%20(ID%3A%20${vaultId})%20using%20%40CarapaceDapp%20to%20safeguard%20my%20digital%20assets%20in%20${NETWORK(selectedChainId)?.handle}%20network%20forever%20%E2%99%BE%EF%B8%8F%20%20%20%0a%0aIncredible%20how%20easy%20it%20was%2C%20no%20locked%20funds%20nor%20sharing%20my%20private%20keys%20%F0%9F%94%90%20%20%20%0a%0aHave%20a%20try%20%F0%9F%91%89%20app.carapace.io`} target="_blank">
                  <Button type="secondary" icon={<TwitterOutlined />}></Button>
                </a>
              </Tooltip>
            </Space>
          : <Space>
              <Tooltip title="back" placement="bottom">
                <Button icon={<ArrowLeftOutlined />} onClick={history.goBack}/>
                {/* <Button icon={<ArrowLeftOutlined />} onClick={() => window.location.replace(`/${actualRoute}`)}/> */}
              </Tooltip>
              <Tooltip title="unlock" placement="bottom">
                <Button icon={<UnlockOutlined />} disabled={!unlockable} onClick={unlockSmartVault}/>
              </Tooltip>
              <Tooltip title="execute" placement="bottom">
                <Button icon={<ExportOutlined />} disabled={!executable} onClick={executeSmartVault} />
              </Tooltip>
            </Space>
        }
        <Suspense>
          <ShowTimer />
        </Suspense>
      </div>
        <Row wrap={false}>
          { windowSize?.vaultBreak
         ? <Col span={24} style={{marginBottom: 40, display:'flex', justifyContent: 'center'}}>
            { actualRoute === 'mysmartvaults' 
              ? <ManageSmartVaultCard 
                  vaultId={vaultId}
                  status={state}
                  image={
                          <Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}>
                            <ShowImage />
                          </Suspense>
                        } 
              /> 
              : <ManageSmartVaultCard 
                  vaultId={vaultId}
                  status={state}
                  image={
                          <Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}>
                            <ShowImage />
                          </Suspense>
                        } 
                  owner={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowOwner /></Suspense>}
                 />
            } 
          </Col>
        :  <Col flex='none' style={{marginRight: 40, marginBottom: 40}}>
            { actualRoute === 'mysmartvaults' 
              ? <ManageSmartVaultCard 
                  vaultId={vaultId}
                  status={state}
                  image={
                          <Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}>
                            <ShowImage />
                          </Suspense>
                        } 
                      
              /> 
              : <ManageSmartVaultCard 
                  vaultId={vaultId}
                  status={state}
                  image={
                          <Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}>
                            <ShowImage />
                          </Suspense>
                        } 
                  owner={<Suspense fallback={<Skeleton.Button active size='small' shape='round'/>}><ShowOwner /></Suspense>}
                 />
            } 
          </Col>
          }
          <Col flex='auto'>
            <Row >
                <div className="ant-div-carapace" style={{ height: actualRoute === 'mysmartvaults' ? 179 : 225 , width:'100%', marginBottom: 20}}>
                  <div className="div-header">
                    <Row >
                      <h2>Balance</h2>
                      { actualRoute === 'mysmartvaults'
                        ? <AdditionalInfo info={
                          NETWORK(selectedChainId)?.stakingModel
                            ? <>
                                <p>Amount of network's native token deposited and protected in the vault. After a successful execution, this amount will be available to beneficiaries based on their share configured by you (See <i>Cryptocurrency</i> below).</p>
                                <p><b>Add funds:</b> increase amount protected and baseline for rewards.</p>
                                <p><b>Withdraw funds:</b> decrease amount protected up to the minimum value.</p>
                                <p>The minimum amount is refunded if the vault is <i>Canceled</i> (processing fee applied)</p>
                              </>
                            : <>
                                <i><p>(Feature only available in "Deposit & Earn" model. Coming soon for the current selected network)</p>
                                <p>Amount of network's native token deposited and protected in the vault. After a successful execution, this amount will be available to beneficiaries based on their share configured by you (See <i>Cryptocurrency</i> below).</p>
                                <p><b>Add funds:</b> increase amount protected and baseline for rewards.</p>
                                <p><b>Withdraw funds:</b> decrease amount protected up to the minimum value.</p>
                                <p>The minimum amount is refunded if the vault is <i>Canceled</i> (processing fee applied)</p></i>
                              </>
                          }/>
                        : <AdditionalInfo info={
                          NETWORK(selectedChainId)?.stakingModel
                            ? <>
                                <p>Amount of network native token deposited and protected by the owner. After a successful execution, this amount will be available to beneficiaries based on their share configured by the owner (See <i>Cryptocurrency</i> below).</p>
                              </>
                            : <>
                                <i><p>(Feature only available in "Deposit & Earn" model. Coming soon for the current selected network)</p>
                                <p>Amount of network native token deposited and protected by the owner. After a successful execution, this amount will be available to beneficiaries based on their share configured by the owner (See <i>Cryptocurrency</i> below).</p></i>
                              </>
                          }/>
                      }
                    </Row>
                    <Row>
                      <Col>
                        <Avatar size='large' shape='square' icon={<BankOutlined/>} style={{marginTop: 4}}  />
                      </Col>
                      <Col>
                        <Row>
                          <Text style={{marginLeft: 20}} type='secondary'>Deposited</Text>
                        </Row>
                        <Suspense>
                          <ShowBalance />
                        </Suspense>
                      </Col>
                      { actualRoute === 'mysmartvaults' && infoModel === 2 && 
                        <>
                          <div style={{padding:5,  marginLeft: 40, display:'flex', flexGrow:1, justifyContent: 'flex-start'}}>
                            <Space>
                              <Col> 
                                <Button 
                                  icon={<MinusOutlined />} 
                                  onClick={withdrawFunds} 
                                  disabled={!balanceUpdatable}
                                />
                              </Col>
                              <Col>
                                <Button  
                                  icon={<PlusOutlined />} 
                                  onClick={addFunds} 
                                  disabled={!balanceUpdatable}
                                  />
                              </Col>
                            </Space>
                          </div>
                        </>  
                      }
                    </Row>   
                  </div>
                </div>
                </Row> 
                
              <Row>
                <div className="ant-div-carapace" style={{height: actualRoute === 'mysmartvaults' ? 179 : 225, width:'100%', marginBottom: 20}}>
                  <div className="div-header">
                    <Row>
                      <h2>Rewards</h2>
                      { actualRoute === 'mysmartvaults'
                        ? <AdditionalInfo info={
                          NETWORK(selectedChainId)?.stakingModel
                          ? <>
                              <p>Rewards are calculated based on your deposit value. Increase deposit to receive more rewards and protect higher amount. The unclaimed amount will be available to your beneficiaries based on their share (See <i>Cryptocurrency</i> below).</p>
                              <p><b>Claim:</b> always possible to claim rewards as soon as they become available.</p>
                            </>
                          : <>
                              <i><p>(Feature only available in "Deposit & Earn" model. Coming soon for the current selected network)</p>
                              <p>Rewards are calculated based on your deposit value. Increase deposit to receive more rewards and protect higher amount. The unclaimed amount will be available to your beneficiaries based on their share (See <i>Cryptocurrency</i> below).</p>
                              <p><b>Claim:</b> always possible to claim rewards as soon as they become available.</p></i>
                            </>
                          }/>
                        : <AdditionalInfo info={
                          NETWORK(selectedChainId)?.stakingModel
                          ? <>
                              <p>Owner rewards are calculated based on his deposit value. The unclaimed amount will be available to beneficiaries based on their share (See <i>Cryptocurrency</i> below).</p>
                            </>
                          : <>
                              <i><p>(Feature only available in "Deposit & Earn" model. Coming soon for the current selected network)</p>
                              <p>Owner rewards are calculated based on his deposit value. The unclaimed amount will be available to beneficiaries based on their share (See <i>Cryptocurrency</i> below).</p></i>
                            </>
                          }/>
                      }
                    </Row>
                    <Row>
                      <Col>
                        <Avatar size='large' shape='square' icon={<GiftOutlined/>} style={{marginTop: 4}}  />
                      </Col>
                      <Col>
                        <Row>
                          <Text style={{marginLeft: 20}} type='secondary'>Earned</Text>
                        </Row>
                        <Suspense>
                          <ShowReward />
                        </Suspense>
                      </Col>
                      
                      { actualRoute === 'mysmartvaults' && infoModel === 2 &&
                        <>
                          <div style={{padding:5, marginLeft: 40, display:'flex', flexGrow:1, justifyContent: 'flex-start'}}>
                            <Space>
                              <Col>
                                <Button 
                                  onClick={claimRewards} 
                                  loading={loadingClaimRewards}
                                  disabled={checkDisabled()}
                                >Claim
                                </Button>
                                {/* Twitter button test */}
                                {/* <a href='https://twitter.com/intent/tweet?hashtags=vault&original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=teste&url=https%3A%2F%2Fcarapace.io&via=CarapaceDapp' target="_blank">teste</a> */}
                                {/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-size="large" data-text="teste" data-url="https://carapace.io" data-via="CarapaceDapp" data-hashtags="vault" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> */}
                                {/* <a href='https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=I%20just%20have%20created%20a%20new%20vault%20()%20with%20%40CarapaceDapp%20to%20%23safeguard%20my%20%23crypto%20assets%20and%20meanwhile%20I%20still%20can%20using%20them.%20Have%20a%20look%20at%20&url=https%3A%2F%2Fcarapace.io' target="_blank">test</a> */}
                                {/* <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=I%20just%20have%20created%20a%20new%20vault%20(${vaultId})%20in%20%40CarapaceDapp%20to%20%23safeguard%20my%20%23crypto%20assets%20and%20meanwhile%20I%20still%20can%20using%20them.%20Have%20a%20look%20at%20&url=https%3A%2F%2Fcarapace.io`} target="_blank">test</a> */}
                                {/* <a href={`https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fapp.carapace.io%2F&text=I%20just%20have%20created%20a%20new%20vault%20(${vaultId})%20in%20%40CarapaceDapp%20to%20%23safeguard%20my%20%23crypto%20assets%20and%20meanwhile%20I%20still%20can%20use%20them.%20Have%20a%20look%20at%20&url=https%3A%2F%2Fcarapace.io`} target="_blank">test</a> */}
                                {/* <a href={`https://twitter.com/intent/tweet?text=I%20was%20testing%20%40CarapaceDapp%20and%20just%20have%20created%20a%20new%20SmartVault%20(tokenID%3A%20${vaultId})%20to%20safeguard%20my%20%23crypto%20assets%20without%20locking%20them%20nor%20sharing%20my%20private%20keys%20%F0%9F%94%90%20Can%27t%20wait%20for%20%23mainnet%20deployment%20%F0%9F%9A%80%0a%0a%20Have%20a%20try%20and%20stay%20up%20to%20date%20%F0%9F%91%89%20https%3A%2F%2Fcarapace.io`} target="_blank">test</a> */}
                                {/* END Twitter button test */}
                              </Col>
                            </Space>
                          </div>
                        </>
                      }
                      
                    </Row>  
                  </div>
                </div>
            </Row>

          </Col>
        </Row>
      <Row style={{marginBottom: 20}}>
        <Col span={24}>
          <div className="ant-div-carapace" >
            <div className="div-header">
              <Row >
                <h2>Trustees</h2>
                { actualRoute === 'mysmartvaults'
                  ? <AdditionalInfo info={
                    NETWORK(selectedChainId)?.stakingModel
                      ? <>
                          <p>Trustees are the account addresses that can trigger the vault's unlock. After sucessful execution the beneficiaries can access your assets.</p>
                          <p><b>Incentive:</b> share of the deposit value that trustee will receive after a successful execution.</p>
                          <p><b>Time Lock:</b> time period until you can revert the unlock process.</p>
                          <p>You can change these settings anytime using <i>Edit</i> vault option above.</p>
                        </>
                      : <>
                          <p>Trustees are the account addresses that can trigger the vault's unlock. After sucessful execution the beneficiaries can access your assets.</p>
                          <p><b>Time Lock:</b> time period until you can revert the unlock process.</p>
                          <p>You can change these settings anytime using <i>Edit</i> vault option above.</p>
                        </>
                    }/>
                  : <AdditionalInfo info={
                    NETWORK(selectedChainId)?.stakingModel
                      ? <>
                          <p>Trustees are the account addresses that can trigger the vault's <i>unlock</i> option above. After sucessful execution the beneficiaries can access owner's assets.</p>
                          <p><b>Incentive:</b> share of the deposit value that trustee will receive after a successful execution.</p>
                          <p><b>Time Lock:</b> time period until owner can revert the unlock process.</p>
                        </>
                      : <>
                          <p>Trustees are the account addresses that can trigger the vault's <i>unlock</i> option above. After sucessful execution the beneficiaries can access owner's assets.</p>
                          <p><b>Time Lock:</b> time period until owner can revert the unlock process.</p>
                        </>
                    }/>
                }
              </Row>
              <Suspense>
                <ShowTrustees />
              </Suspense>
            </div>
          </div>
        </Col>       
      </Row>
      <Row style={{marginBottom: 20}}>
        <Col span={24}>
          <div className="ant-div-carapace">
            <div className="div-header">
              <Row >
                <h2>Cryptocurrency</h2>
                { actualRoute === 'mysmartvaults'
                  ? <AdditionalInfo info={
                      <>
                        <p>Cryptocurrency are the tokens protected in this vault. They will become available to beneficiaries based on their share after a successful execution by any trustee.</p>
                        <p>Remember these tokens are NOT locked and you can use them normally.</p>
                        <p>You can add or remove tokens, change beneficiaries and their share, using <i>Edit</i> vault option above.</p>
                      </>
                    }/>
                  : <AdditionalInfo info={
                      <>
                        <p>Cryptocurrency are the tokens protected in this vault. They will become available to beneficiaries based on their share after a successful execution by any trustee.</p>
                      </>
                    }/>
                }
              </Row>
              <br/>
              <Row>
                <Col span={12}>
                  <Suspense>
                    <ShowBeneficiaries />
                  </Suspense>
                </Col>
                
                <Col span={12}>
                  <Suspense>
                    <ShowERC20 />
                  </Suspense>
                </Col>
              </Row>
            </div>
          </div>
        </Col>       
      </Row>
      <Row style={{marginBottom: 20}}>
        <Col span={24}>
          <div className="ant-div-carapace">
            <div className="div-header">
              <Row >
                <h2>NFT</h2>
                { actualRoute === 'mysmartvaults'
                  ? <AdditionalInfo info={
                      <>
                        <p>NFTs protected in this vault. They will become available to the assigned beneficiary after a successful execution by any trustee.</p>
                        <p>Remember these NFTs are NOT locked and you can use them normally.</p>
                        <p>You can add or remove NFTs and change their beneficiary using <i>Edit</i> vault option above.</p>
                      </>
                    }/>
                  : <AdditionalInfo info={
                      <>
                        <p>NFTs protected in this vault. They will become available to the assigned beneficiary after a successful execution by any trustee.</p>
                      </>
                    }/>
                }
              </Row>
            </div>
            <div className='received-nfts'>
              <Suspense fallback={
                          <div style={{margin: 30}}>
                            <Row gutter={32}>
                              <Col span={6}>
                                <Card style={{height: 400}}>
                                  <Skeleton active avatar size='small' shape='square'/>
                                </Card>
                              </Col>
                              <Col span={6}>
                                <Card style={{height: 400}}>
                                  <Skeleton active avatar size='small' shape='square'/>
                                </Card>
                              </Col>
                              <Col span={6}>
                                <Card style={{height: 400}}>
                                  <Skeleton active avatar size='small' shape='square'/>
                                </Card>
                              </Col>
                              <Col span={6}>
                                <Card style={{height: 400}}>
                                  <Skeleton active avatar size='small' shape='square'/>
                                </Card>
                              </Col>
                            </Row>
                          </div>           
                        } 
              >
                <ShowNFT />
              </Suspense>
            </div>
          </div>
        </Col>       
      </Row>
      <br/>
    </> 
    );
  }

  return (
    <Switch>
      <Route exact path={path}>
        <div className={windowSize?.mainContainer}>
          <Suspense>
            <ShowSmartVault />
          </Suspense>
        </div>
      </Route>
      <Route path={`${path}/edit`}>
          <EditSteps
            owner={address}
            infoStatus={infoStatus}
            infoBalance={infoBalance}
            infoReward={infoReward}
            infoImage={infoImage} 
            infoERC20={infoERC20} 
            infoTrustee={infoTrustee} 
            infoIncentive={infoIncentive} 
            infoBeneficiary={infoBeneficiary} 
            infoLockDays={infoLockDays} 
            infoNFT={infoNFT}
            infoModel={infoModel} 
          />
      </Route>
    </Switch>
  );
}


