import React, { useEffect, useContext } from "react";
import { Button, Row, Col, Avatar, Image, message, notification, Typography, List } from "antd";
import { LinkOutlined } from '@ant-design/icons';
import "../App.css";
import { Web3Context } from "../context/web3Details";
import { checkBridgeConnection } from "../helpers/checkConnectionType";
import { NFTCard, AdditionalInfo } from ".";
import { NETWORK } from "../constants";

const { ethers } = require("ethers");

const { Text } = Typography;

const erc20Abi = [
  "function balanceOf(address owner) view returns (uint256)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
];

const erc721Abi = [
  "function getApproved(uint256 _tokenId) external view returns (address)",
  "function approve(address _approved, uint256 _tokenId) external payable",
  "function isApprovedForAll(address _owner, address _operator) external view returns (bool)",
  "function setApprovalForAll(address _operator, bool _approved) external",
];

const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export default function ApproveTokens(
  {selectedERC20, selectedERC721, checkAllApproved, selectedERC20Assets, selectedERC721Assets }) {

  const { address, signer, readContracts, selectedChainId } = useContext(Web3Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const erc20Approvals = async () => {
    let erc20Assets = [];
      for (const erc20 of selectedERC20) {
        let contract = new ethers.Contract(erc20.address, erc20Abi, signer);
        let allowance = await contract.allowance(address, readContracts.CarapaceSmartVault.address);
        if (allowance > 0) {
          erc20Assets.push({
            ...erc20,
            approved: true
          })
        }
        else {
          erc20Assets.push({
            ...erc20,
            approved: false
          })
        }
      }
      selectedERC20Assets(erc20Assets);
  };

  // const erc721Approvals = async () => {
  //   let erc721Assets = [];
  //     for (const erc721 of selectedERC721) {
  //       if (erc721.type === 'nft'){
  //         let contract = new ethers.Contract(erc721.address, erc721Abi, signer);
  //         let isApprovedForAll = await contract.isApprovedForAll(address, readContracts.CarapaceSmartVault.address);
  //         isApprovedForAll 
  //         ? erc721Assets.push({...erc721, approvedForAll: true, operatorApprovedForAll: readContracts.CarapaceSmartVault.address})
  //         : erc721Assets.push({...erc721, approvedForAll: false, operatorApprovedForAll: ''})
          
  //       } else if (erc721.type === 'tokenId'){
  //         let tokenIdApproved = false;
  //         let parentNFTApproved = false;
  //         const parentNFT = erc721Assets.find(selItem => selItem.address === erc721.address.split("_")[0]);
  //         if (parentNFT) {
  //           parentNFTApproved = parentNFT.operatorApprovedForAll;
  //         } else {
  //           const contract = new ethers.Contract(erc721.address.split("_")[0], erc721Abi, signer);
  //           parentNFTApproved = await contract.isApprovedForAll(address, readContracts.CarapaceSmartVault.address);
  //         }
  //         if (!parentNFTApproved) {
  //           let contract = new ethers.Contract(erc721.address.split("_")[0], erc721Abi, signer);
  //           tokenIdApproved = await contract.getApproved(erc721.tokenId);
  //         }
  //         if (parentNFTApproved || tokenIdApproved.toString() === readContracts.CarapaceSmartVault.address) {
  //           erc721Assets.push({...erc721, approved: true})
  //         } else { erc721Assets.push({...erc721, approved: false})}
  //         }
  //      }
  //     selectedERC721Assets(erc721Assets);
  // };

  const erc721Approvals = async () => {
    let erc721Assets = [];
      for (const erc721 of selectedERC721) {
        let contract = new ethers.Contract(erc721.address.split("_")[0], erc721Abi, signer);
        let tokenIdApproved = await contract.getApproved(erc721.tokenId);
        
        if (tokenIdApproved.toString() === readContracts.CarapaceSmartVault.address) {
          erc721Assets.push({...erc721, approved: true})
        } else { erc721Assets.push({...erc721, approved: false})}    
      }
      selectedERC721Assets(erc721Assets);
  };

  useEffect(() => {
    const checkApprovals = async () => {
      erc20Approvals();
      erc721Approvals();
    }
    checkApprovals();
  },[]);

  useEffect(() => {
    checkAllApproved(true);
    selectedERC20.forEach(element => {
      element.approved === false ? checkAllApproved(false) : null;
    });
    selectedERC721.forEach(element => {
      element.approved === false ? checkAllApproved(false) : null;
    });
  }, [selectedERC20, selectedERC721]);

  const approveERC20Token = async (index, token) => {
    try{
      checkBridgeConnection(signer);
      let contract = new ethers.Contract(token, erc20Abi, signer);
      let approve = await contract.approve(readContracts.CarapaceSmartVault.address, MAX_INT);

      if (approve){
        const assets = [...selectedERC20];
        assets[index]['approved'] = true;
        selectedERC20Assets(assets);
      }
    } catch(err) {
      // message.error(`Error approving token: ${err.message}`);
      notification.error({
        message: "Error approving token",
        description: err.message
      })
    }
  };

  const approveERC721Token = async (address, tokenId) => {
    try{
      checkBridgeConnection(signer);
      let contract = new ethers.Contract(address.split("_")[0], erc721Abi, signer);
      let approve = await contract.approve(readContracts.CarapaceSmartVault.address, tokenId);
      const values = [...selectedERC721];
      const index = values.findIndex((item) => item.address === address && item.tokenId === tokenId );
      // console.log("approve.721.index", index);
      if (approve){
        const assets = [...selectedERC721];
        assets[index]['approved'] = true;
        selectedERC721Assets(assets);
      }
    } catch(err) {
      // message.error(`Error approving token: ${err.message}`);
      notification.error({
        message: "Error approving token",
        description: err.message
      })
    }
  };

  const approveAllTokens = async (index, nftAddress) => {
    checkBridgeConnection(signer);
    let contract = new ethers.Contract(nftAddress, erc721Abi, signer);
    let approveAll = await contract.setApprovalForAll(readContracts.CarapaceSmartVault.address, true);

    if (approveAll){
      const assets = [...selectedERC721];
      assets[index]['approvedForAll'] = true;
      assets[index]['operatorApprovedForAll'] = readContracts.CarapaceSmartVault.address;
      selectedERC721Assets(approveAllChildren(assets, nftAddress));
    }
  };

  const approveAllChildren = (assets, nftAddress) => {
    let erc721Assets = [];
      for (const erc721 of assets) {
        if (erc721.type === 'tokenId'){
          if (erc721.address.split("_")[0] === nftAddress){
            erc721Assets.push({...erc721, approved: true})
          } else {
            erc721Assets.push({...erc721})
          }
        } else {
          erc721Assets.push({...erc721})
        }
       }
      return erc721Assets;
  };

  const tokenIdLink = ({tokenId, address}) => {
    return (
      <>
        <Text type='secondary' style={{marginRight: 5}}># {tokenId}</Text>
        <Text>
          <a href={`${NETWORK(selectedChainId).blockExplorer}token/${address}?a=${tokenId}`} target="_blank">
            <LinkOutlined key="setting" />
          </a>
        </Text>
      </>
    )
  };

  const renderERC721 = (item, index) => {
  //  if (item.type === 'tokenId'){
      return (
        <div key={index}>
        <br/>
        <Row gutter={[16, 24]} style={{display: 'flex', alignItems:'center'}}>
          <Col span={6}>
            <div align="Right">
              <Image width={100} height={100} src={item.image} />
            </div>
          </Col>
          <Col span={6} style={{textAlign: 'center', align: 'middle'}} >
          <Button type="text" >{item.parentSymbol} | {item.tokenId}</Button>
          </Col>
          <Col span={12} style={{textAlign: 'Left', align: 'middle'}} >
            <Button type="primary" disabled={item.approved} onClick={() => approveERC721Token(index, item.address, item.tokenId)}>
              {item.approved ? 'Approved' : 'Approve'}
            </Button>
          </Col>
        </Row>
      </div>
      )
 //   } 
    // else {
    //   return (
    //     <div key={index}>
    //     <br/>
    //     <Row gutter={[16, 24]}>
    //       <Col span={12} style={{textAlign: 'Right', align: 'middle'}} >
    //         <Button type="text" >{item.symbol} | {item.name}</Button>
    //       </Col>
    //       <Col span={12} style={{textAlign: 'Left', align: 'middle'}} >
    //         <Button type="primary" disabled={item.approvedForAll} onClick={() => approveAllTokens(index, item.address)}>
    //           {item.approvedForAll ? 'Approved for All' : 'Approve All'}
    //         </Button>
    //       </Col>
    //     </Row>
    //   </div>
    //   )
    // }
  };

  return (
    <>
    {selectedERC20.length !== 0 &&
      <div className="ant-div-carapace" style={{marginTop: 30}}>
        <div className="div-header">
          <Row>
            <h2>Approvals</h2>
            <AdditionalInfo info={
              <>
                <p>Give permission for each token to be protected by Carapace Protocol. Be sure you are approving the Carapace Smart Contract. Please read the <a href="https://docs.carapace.io/support/contract-addresses" target="_blank">docs</a>.</p>
                <p>The Carapace Smart Contract is immutable to ensure the security of your approvals.</p>
                <p>You can change the amount to protect. Note that only up to the amount approved will be protected.</p>
              </>
            }/>
          </Row>
          <Row>
            <Text type="secondary">Cryptocurrency</Text>
          </Row>
        </div>
        {/* <p>Please approve the following ERC-20 assets to safeguard in the smart vault</p> */}
        {selectedERC20.map((item, index) => (
          <div key={index}>
            <br/>
            <Row gutter={[16, 24]}>
              <Col span={9} style={{textAlign: 'Right', align: 'middle'}} >
                <Avatar src={item.logo}/>
              </Col>
              <Col span={3} style={{textAlign: 'Left', align: 'middle'}} >
                <Button type="text" >{item.symbol}</Button>
              </Col>
              <Col span={12} style={{textAlign: 'Left', align: 'middle'}} >
                <Button type="primary" disabled={item.approved} onClick={() => approveERC20Token(index, item.address)}>
                  {item.approved ? 'Approved' : 'Approve'}
                </Button>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    }
    {selectedERC721.length !== 0 &&
      <div className="ant-div-carapace" style={{marginTop: 30}}>
        <div className="div-header">
          <Row>
            <h2>Approvals</h2>
            <AdditionalInfo info={
              <>
                <p>Give permission for each NFT to be protected by Carapace Protocol. Be sure you are approving the Carapace Smart Contract. Please read the <a href="https://docs.carapace.io/support/contract-addresses" target="_blank">docs</a>.</p>
                <p>The Carapace Smart Contract is immutable to ensure the security of your approvals.</p>
              </>
            }/>
          </Row>
          <Row>
            <Text type="secondary">NFT</Text>
          </Row>
        </div>
        {/* <p>Please approve the following ERC-721 assets to safeguard in the smart vault</p> */}
        {/* {selectedERC721.sort((a, b) => a.address.localeCompare(b.address)).map(renderERC721)} */}
        <List
            align="center"
            grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            dataSource={selectedERC721}
            renderItem={item => (
              <>
              <div style={{padding: 15}}>
                <List.Item className="scan-nft" >
                  <NFTCard
                    address={item.address}
                    tokenId={item.tokenId} 
                    // selector 
                    selectorText={'Beneficiary Address'} 
                    title={item.symbol} 
                    description={item.name.length < 28
                      ? `${item.name}`
                      : `${item.name.substring(0, 25)}...`}
                    src={item.image} 
                    additional={tokenIdLink(item)}
                    // selectorOnChange={handleChangeAddress}
                    // isChecked={item.isChecked}
                    // isAlreadyUsed={item.isAlreadyUsed}
                  />
                </List.Item>
                {/* </div> */}
                {/* <div style={{padding: 5, border: "solid"}}> */}
                <Button type="primary" disabled={item.approved} onClick={() => approveERC721Token(item.address, item.tokenId)}>
                  {item.approved ? 'Approved' : 'Approve'}
                </Button>
              </div>
              </>
            )}
            bordered={false}
            pagination={{ 
              onChange: page => {
                scrollToTop();
              },
              pageSize: 16
            }}
          />
      </div>
    }
      {/* <br/> */}
    </>
  )
}