import React, { useState } from "react";
import { Button, Input, InputNumber, Form, Space, Row, Col } from "antd";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "../App.css";
import AddressInput from "./AddressInput";

export default function DynamicForm({actor, stateValues, selectedActors, mainnetProvider}) {

  const formItemLayoutWithOutLabel = {
    // wrapperCol: {
    //   xs: { span: 24, offset: 0 },
    //   sm: { span: 18, offset: 4 },
    // },
  };

  const onFinish = values => {
    selectedActors(values);
  };

const handleChangeAddress = (index, event) => {
    const values = [...stateValues];
    values[index]['address'] = event;
    selectedActors(values);
};

const handleChangePercentage = (index, value) => {
  const values = [...stateValues];
  values[index]['percentage'] = value;
  selectedActors(values);
};

return (
  <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel} onFinish={onFinish} initialValues={{[actor]: stateValues}}>
    <Form.List name={actor} >
      {(fields, { add, remove }, { errors }) => (
        <>    
          {fields.map((field, index) => (
            
            <Form.Item key={field.key}>
              {/* <div className="form-address-content"> */}
              <div>
                <Row>
                  <Col span={16} offset={1} style={{display: "flex", justifyContent: "flex-end"}}>
                    <Form.Item
                      name={[field.name, 'address']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: `Please input ${actor} wallet address or delete this field.`,
                        },
                      ]}
                      noStyle
                    >
                      {/* <Input name='address' addonBefore={index+1} placeholder={`${actor} wallet address` } style={{ width: '60%' }}
                       onChange={event => handleChangeAddress(index, event)}/> */}
                      
                       <AddressInput ensProvider={mainnetProvider} name='address' placeholder={`${actor} wallet address` } onChange={event => handleChangeAddress(index, event)}/>
                      
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{display: "flex", justifyContent: "flex-start"}}>
                    { actor === 'Beneficiary' &&
                      <Form.Item 
                        name={[field.name, 'percentage']} 
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: `Please input ${actor} split percentage or delete this field.`,
                          },
                        ]}
                        noStyle
                      >
                        <InputNumber
                          min={1} 
                          max={100}
                          addonAfter="%" 
                          // formatter={value => `${value} %`} 
                          // parser={value => value.replace('%', '')}
                          style={{ marginLeft: 20, width: 100 }}
                          onChange={event => handleChangePercentage(index, event)}
                        />
                      </Form.Item>
                    }
                    { fields.length > 1 
                      ? <DeleteOutlined
                          className="dynamic-delete-button"
                          
                          onClick={() => {
                            const values = [...stateValues];
                            const deleted = values.splice(field.name, 1);
                            selectedActors(values); 
                            remove(field.name);
                          }}
                        />
                      : null
                    }
                  </Col>
                </Row>
              </div>
            </Form.Item>
            
          ))}
          {/* <div className="form-address-content"> */}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {   
                  selectedActors([...stateValues, {
                      address: null, 
                      percentage:null
                    }]);
                  add(); 
                }}
                icon={<PlusOutlined />}
              >
                Add {actor}
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
   
        </>
      )}
    </Form.List>
  </Form>
);
}
