import { Button, Empty, Image, Row, Col, Typography, Space } from "antd";
import { LoginOutlined, ArrowLeftOutlined, ArrowRightOutlined,CloseOutlined } from '@ant-design/icons';
import React, { useState, useContext } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { ScreenSize } from "../context/screenSize";
import carapaceLogo from '../logo_white_circle.png';
import introStep1V2 from '../Intro_Step_1_v2.png';
import introStep2V2 from '../Intro_Step_2_v2.png';
import introStep3V2 from '../Intro_Step_3_v2.png';
import introStep4V2 from '../Intro_Step_4_v2.png';
import introStep5V2 from '../Intro_Step_5_v2.png';
import introStep1V2_light from '../Intro_Step_1_v2_light.png';
import introStep2V2_light from '../Intro_Step_2_v2_light.png';
import introStep3V2_light from '../Intro_Step_3_v2_light.png';
import introStep4V2_light from '../Intro_Step_4_v2_light.png';
import introStep5V2_light from '../Intro_Step_5_v2_light.png';
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";

const { Text } = Typography;

export default function Home({
  loadWeb3Modal,
}) {

  const { currentTheme } = useThemeSwitcher();
  const { windowSize } = useContext(ScreenSize);
  const [skipIntro, setSkipIntro] = useState(false);
  const [introStep, setIntroStep] = useState(1);

  const clickTrack = (event) => {
    // console.log("Analytics:", event);
    logEvent(analytics, `click_${event}`);
  }

  const intro = [
    {}, // burn 0 position to align with introStep
    {
      title: 'Connect your wallet and select the crypto or NFTs to protect',
      image: currentTheme === "light" ? introStep1V2_light : introStep1V2
    },
    {
      title: 'Customize your rules, set your beneficiaries and trustees',
      image: currentTheme === "light" ? introStep2V2_light : introStep2V2
    },
    {
      title: 'Give permission to Carpace protocol act on your behalf',
      image: currentTheme === "light" ? introStep3V2_light : introStep3V2
    },
    {
      title: 'Revise settings and create a Smart Vault',
      image: currentTheme === "light" ? introStep4V2_light : introStep4V2
    },
    {
      title: 'Your assets stay protected forever by an unique Smart Vault NFT',
      image: currentTheme === "light" ? introStep5V2_light : introStep5V2
    },
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const hideIntro = () => {
    setSkipIntro(true);
    logEvent(analytics, `intro_skipped${introStep}`);
  };

  const previous = () => {
    setIntroStep(introStep - 1);
    logEvent(analytics, `intro_prev_step${introStep - 1}`);
  };

  const next = () => {
    setIntroStep(introStep + 1);
    logEvent(analytics, `intro_next_step${introStep + 1}`);
  };

  const showSkipIntro = () => {
    return ( 
      <div style={{display:'flex', alignItems: 'center', justifyContent: 'right'}}>
        { introStep !== 5 ?
          <Button type='text' onClick={hideIntro} >
             <Text type='secondary'>skip</Text>
          </Button>
          : <Button style={{marginRight: 10}} type='text' onClick={hideIntro} icon={<CloseOutlined />}/>
        }
      </div>
    )
  };

  const showArrows = () => {
    return (
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Space style={{marginRight: 10, marginTop: 20}}>
            <Button onClick={previous} icon={<ArrowLeftOutlined />} disabled={introStep === 1}/>
            <Button onClick={next} icon={<ArrowRightOutlined />} disabled={introStep === 5} />
          </Space>
        </div>
    )
  };

  return (
    <div className="ant-div-carapace" style={{marginTop: "5%" , marginLeft: "10%", marginRight: "10%"}}>
    { !skipIntro ? 
        <>
            <div>
              <Row >
              <Col span={8}>
                  <div style={{display:'flex', alignItems: 'center', justifyContent: 'left', marginTop:5}}>
                    { introStep !== 5 ? <Text type='secondary'>Step {introStep}</Text> : <Text type='secondary'>Et voilá!</Text>}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', marginTop:5}}>
                    <Text type='secondary'>Tutorial</Text>
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', marginTop:5}}>
                    <a href="https://www.loom.com/embed/4c5c6079a8594a1e8d787952d3d273ce"
                       target="_blank" 
                       rel="noopener noreferrer"
                       onClick={() => clickTrack("tuturialVideo")}>
                      Watch video
                    </a>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{display:'flex', alignItems: 'center', justifyContent: 'right', marginTop:5}}>{showSkipIntro()}</div>
                </Col>
              </Row>
              <Row style={{display:'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                  { windowSize?.breakpoint === 'sm' 
                    ?  <h5 style={{marginBottom: 20}}>{intro[introStep].title}</h5>
                    :  <h2 style={{marginBottom: 20}}>{intro[introStep].title}</h2>
                  }
              </Row>
              <Row style={{display:'flex', justifyContent:'center' }}>
                <Image height='512px' src={intro[introStep].image} preview={false} />
              </Row>
            </div>
          {showArrows()}
        </>
    : <Empty
          image={carapaceLogo}
          imageStyle={{
            height: 200,
            marginBottom: 48,
          }}
          description={
            <span>
              Connect your wallet and start protecting your digital assets. 
              Please read the <a href="https://docs.carapace.io" target="_blank" onClick={() => clickTrack("docs")}>Docs</a>.
            </span>
          }
        >
          <Button
            className="ant-button-carapace"
            key="loginbutton"
            type="primary"
            shape="square"
            size="large"
            onClick={loadWeb3Modal}
            icon={<LoginOutlined />}
          >
            Connect
          </Button>
        </Empty>
} 
    </div>
  );
}
