import React, {useState, useEffect } from "react";
import { Button, Row, Col, InputNumber, Input, Avatar, Tooltip, Typography  } from "antd";
import { utils } from "ethers";
import "../App.css";
import { local } from "web3modal";

const { Text } = Typography;

export default function UpdateSmartVault(
  { selectedERC20, 
    selectedERC721, 
    beneficiaries, 
    trustees,
    trusteeIncentive,
    lockDays
  }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImgError = () => {
    return true;
  };

  return (
    <div style={{marginTop: 30}}> 
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={6}>
          <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
            <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                <h2>Beneficiaries</h2>
            </Row>
            <Row style={{display:'flex', justifyContent:'center'}}> 
                <h2>{beneficiaries.length}</h2>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
            <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                <h2>Trustees</h2>
            </Row>
            <Row style={{display:'flex', justifyContent:'center'}}>
                <h2>{trustees.length}</h2>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
            <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                <h2>Cryptocurrency</h2>
            </Row>
            <Row style={{display:'flex', justifyContent:'center'}}> 
              { selectedERC20.length !== 0 ?
                <>
                  <Avatar.Group      
                    maxCount={3}
                    maxPopoverTrigger="click"
                    maxStyle={{
                      color: '#f56a00',
                      backgroundColor: '#fde3cf',
                      cursor: 'pointer',
                    }}
                  >
                    {selectedERC20.map((item, index) => (
                    <Tooltip key={index} title={item.symbol} placement="top">
                      <Avatar src={item.logo}/>
                    </Tooltip>
                    ))}
                  </Avatar.Group>
                </> : <Text type="secondary">No cryptocurrency selected</Text>
              }
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
            <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                <h2>NFT</h2>
            </Row>
            <Row style={{display:'flex', justifyContent:'center'}}>
              { selectedERC721.length !== 0 ?
                <>
                  <Avatar.Group    
                    maxCount={3}
                    maxPopoverTrigger="click"
                    maxStyle={{
                      color: '#ffffff',
                      backgroundColor: '#bfbfbf',
                      cursor: 'pointer',
                    }} 
                  >
                    {selectedERC721.map((subItem) => <Avatar key={subItem.address} src={subItem.image} shape="square" onError={handleImgError}/>)}
                  </Avatar.Group>
                </> : <Text type="secondary">No NFT selected</Text>
              }
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}