import axios from "axios";
import erc20ImgLogo from '../erc20_logo.png';
import testTokenList from '../testTokenList.json';
import { NETWORK } from "../constants";
import { utils } from "ethers";

const { toChecksumAddress } = require('ethereum-checksum-address');
const DEFAULT_ERC20_NAME = 'No name available';
const DEFAULT_ERC20_SYMBOL = 'No symbol available';
const DEFAULT_ERC20_LOGO = erc20ImgLogo;

export const fetchERC20Assets = ( address, selectedChainId, tx, readContracts, vaultEdited, scannedERC20, endReScanERC20 ) => {
 
    const fetchAPIERC20 = () => {

        return new Promise((resolve) => {

        resolve(axios.get(`https://deep-index.moralis.io/api/v2/${address}/erc20?chain=${NETWORK(selectedChainId).moralisApi}`, {
            headers: {
              'accept': 'application/json',
              'X-API-key': process.env.REACT_APP_MORALIS_KEY
            }
        })
        .then((res) => {
            const fetchedERC20Assets = [];
            // console.log("Payload ERC20:", res.data);
            res.data.forEach((item) => {
              let name = item.name ? item.name : DEFAULT_ERC20_NAME; 
              let symbol = item.symbol ? item.symbol : DEFAULT_ERC20_SYMBOL; 
              
              let logo = item.logo;
              if (!logo) {
                const selectedItem = testTokenList.tokens.find(elem => elem.address === toChecksumAddress(item.token_address)); 
                selectedItem ? logo = selectedItem.logoURI : logo = DEFAULT_ERC20_LOGO;
              }
        
              fetchedERC20Assets.push({
                type: 'cryptocurrency',
                address: toChecksumAddress(item.token_address),
                balance:  parseFloat(utils.formatUnits(item.balance, item.decimals)).toFixed(6),
                name,
                symbol,
                logo,
                logoDetail: {name , symbol, image: logo},
                //isAlreadyUsed: vaultedERC20.includes(toChecksumAddress(item.token_address)),
                approved: false
              });
            });

            return fetchedERC20Assets;
        })
        .catch((error) => {
            console.error(error)
        })
        )});
    };

    const getERC20OfOwner = (vaultsOfOwner) => {
        let erc20OfOwner = [];
        vaultsOfOwner.forEach( item => {
          const promise = new Promise((resolve) => {
            resolve(tx(readContracts.CarapaceStorage.getSmartVaultState(item))
                .then(async (status) => {
                    if (status <= 1 ){
                        const result = await tx(readContracts.CarapaceStorage.getSmartVaultInfo(item));
                        return result[0];
                    } else return [];
                })
            );
          });
          erc20OfOwner.push(promise);
        });
        return Promise.all(erc20OfOwner);
    };

    const isEditing = (item) => {
        return (item.toString() != vaultEdited);
      };

    const fetchVaultedTokens = () => {
      if (NETWORK(selectedChainId)?.fullFeatures ){ //remove or add when the smart contract is deployed on more networks
        return new Promise((resolve) => {
            resolve(tx(readContracts.CarapaceStorage.getVaultsOfOwner(address))
                .then(vaults => {
                    return getERC20OfOwner(vaults.filter(isEditing));
                })
            );
        });
      } else return [];
    };

    const validateUsedTokens = (apiERC20, vaultedTokensList) => {
        let validatedList = [];

        apiERC20.forEach(item => {
            validatedList.push({
                ...item,
                isAlreadyUsed: vaultedTokensList.includes(item.address)
            })
        });

        scannedERC20(validatedList);
        endReScanERC20();
        return validatedList;
    };

    const fetchERC20 = () => {
          return Promise.all([
            fetchAPIERC20(),
            fetchVaultedTokens()
          ]).then(([apiERC20, vaultedTokens]) => {
            let vaultedTokensList = [];
            vaultedTokens.forEach(item => {
              item.forEach(subItem => { 
                vaultedTokensList.push(subItem);
              })  
            })
            return validateUsedTokens(apiERC20, vaultedTokensList);
          })
    }

    let erc20Promise = fetchERC20();

    return {
        erc20: wrapPromise(erc20Promise),
      };
}

function wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
      (r) => {
        status = "success";
        result = r;
      },
      (e) => {
        status = "error";
        result = e;
      }
    );
    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        } else if (status === "success") {
          return result;
        }
      }
    };
  }