import React, { useState } from "react";
import { Button, Input, Form, Space } from "antd";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import "../App.css";

export default function DynamicFormContacts({actor, stateValues, selectedActors}) {

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 4 },
    },
  };

  const onFinish = values => {
    selectedActors(values);
  };

const handleChangeEmail = (index, event) => {
    const values = [...stateValues];
    values[index]['email'] = event.target.value;
    selectedActors(values);
};

return (
  <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel} onFinish={onFinish} initialValues={{[actor]: stateValues}}>
    <Form.List name={actor} >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item key={field.key} {...formItemLayoutWithOutLabel} >
              
              <Form.Item
                name={[field.name, 'email']}
                
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    whitespace: true,
                    message: `Please input a valid e-mail.`,
                  },
                ]}
                noStyle
              >
                <Input name='email' addonBefore={index+1} placeholder={`${actor} e-mail` } style={{ width: '80%' }}
                  allowClear={true} onChange={event => handleChangeEmail(index, event)}/>
              </Form.Item>
            
              {fields.length > 1 ? (
                <DeleteOutlined
                  className="dynamic-delete-button"
                  onClick={() => {
                    const values = [...stateValues];
                    const deleted = values.splice(field.name, 1);
                    selectedActors(values); 
                    remove(field.name);
                  }}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {   
                selectedActors([...stateValues, {
                    email: ""
                  }]);
                add(); 
              }}
              
              icon={<PlusOutlined />}
            >
              Add e-mail
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  </Form>
);
}
