import React, {useEffect, useContext} from "react";
import { Row, Col, Tooltip, Avatar, InputNumber, Typography, List } from 'antd';
import { BankOutlined, SketchOutlined, LockOutlined, GiftOutlined, LinkOutlined } from '@ant-design/icons';
import { NFTCard } from "./";
import { DynamicForm, AdditionalInfo, AddressInput } from ".";
import "../App.css";
import { Web3Context } from "../context/web3Details"; 
import { NETWORK } from "../constants";

const { toChecksumAddress } = require('ethereum-checksum-address');
const { Text } = Typography;

export default function CustomizeSmartVault({
  beneficiaries, 
  trustees, 
  selectedBeneficiaries, 
  selectedTrustees,
  trusteeIncentive,
  selectedTrusteeIncentive,
  lockDays,
  selectedLockDays,
  selectedERC20, 
  selectedERC721,
  selectedERC721Assets,
  checkAllFilled,
  mainnetProvider,
  infoModel
  }) {
  
    const { selectedChainId } = useContext(Web3Context);

    useEffect(() => {
      checkAllFilled(true);
      let totalPercentage = 0;
      beneficiaries.forEach(element => {
        element.address === '' || element.address === null  ? checkAllFilled(false) : null;
        element.percentage === '' || element.percentage === null  ? checkAllFilled(false) : null;
        if (element.percentage && element.percentage > 0){
          totalPercentage += element.percentage;
        }        
      });
      new Set(beneficiaries.map(item => item.address)).size !== beneficiaries.length ? checkAllFilled(false) : null;
      totalPercentage !== 100 ? checkAllFilled(false) : null;
      
      selectedERC721.forEach(element => {
        element.beneficiary === '' ? checkAllFilled(false) : null;
      });
      trustees.forEach(element => {
        element.address === '' || element.address === null ? checkAllFilled(false) : null;
      });
      new Set(trustees.map(item => item.address)).size !== trustees.length ? checkAllFilled(false) : null;

      trusteeIncentive === '' || trusteeIncentive === null ? checkAllFilled(false) : null;
      lockDays === '' || lockDays === null ? checkAllFilled(false) : null;

    }, [selectedBeneficiaries, selectedERC721, selectedTrustees]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleChangeAddress = (event, address, tokenId) => {
      const values = [...selectedERC721];
      const index = values.findIndex((item) => item.address === address && item.tokenId === tokenId );
      values[index]['beneficiary'] = event;
      selectedERC721Assets(values);
    };

    const tokenIdLink = ({tokenId, address}) => {
      return (
        <>
          <Text type='secondary' style={{marginRight: 5}}># {tokenId}</Text>
          <Text>
            <a href={`${NETWORK(selectedChainId).blockExplorer}token/${address}?a=${tokenId}`} target="_blank">
              <LinkOutlined key="setting" />
            </a>
          </Text>
        </>
      )
    };

  return (
    <>
        <div className="ant-div-carapace" style={{marginTop: 30}}>
          <div className="div-header">
            <Row>
              <h2>Beneficiaries</h2>
              <AdditionalInfo info={
                <>
                  <p>Beneficiaries are the account addresses that will receive their share of each token, deposited value and unclaimed rewards.</p>
                  <p>Fill at least one beneficiary address and share (%). Addresses cannot be repeated for the same vault and total share must be 100%</p>
                </>
              }/>
            </Row>
            <Row>
              <Text type="secondary">Cryptocurrency</Text>
            </Row>
          <Row style={{marginTop: 20}}>
            <Col flex='none'>
                <Row style={{marginBottom: 20, marginRight:60}}>
                  <Tooltip title='Deposit amount' placement="top">
                    <Avatar shape="square" size="large" icon={<BankOutlined />} />
                  </Tooltip>
                  <Tooltip title='Rewards accrued' placement="top">
                    <Avatar shape="square" size="large" icon={<GiftOutlined /> } style={{marginLeft: 10}} />
                  </Tooltip>
                </Row>
                <Row style={{marginBottom: 50}}>
                  <Avatar.Group      
                    maxCount={8}
                    maxPopoverTrigger="click"
                    maxStyle={{
                      color: '#f56a00',
                      backgroundColor: '#fde3cf',
                      cursor: 'pointer',
                    }}
                  >
                    {selectedERC20.map((item, index) => (
                    <Tooltip key={index} title={item.symbol} placement="top">
                      <Avatar src={item.logo}/>
                    </Tooltip>
                    ))}
                  </Avatar.Group>
                </Row>
           
            </Col>
            <Col flex='auto'>
              <DynamicForm actor='Beneficiary' stateValues={beneficiaries} selectedActors={selectedBeneficiaries} mainnetProvider={mainnetProvider} />
            </Col>
          </Row>
        </div>
      </div>
      {
        selectedERC721.length !== 0 &&
        <div className="ant-div-carapace" style={{marginTop: 20}}>
          <div className="div-header">
            <Row>
              <h2>Beneficiaries</h2>
              <AdditionalInfo info={
                <>
                  <p>Beneficiaries are the account addresses that will receive the NFT.</p>
                  <p>All NFTs must have one beneficiary address set.</p>
                </>
              }/>
            </Row>
            <Row>
              <Text type="secondary">NFT</Text>
            </Row>
          
          <List
            align="center"
            grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            dataSource={selectedERC721}
            renderItem={item => (
              <>
              <div style={{padding: 15}}>
                <List.Item className="scan-nft" >
                  <NFTCard
                    address={item.address}
                    tokenId={item.tokenId} 
                    // selector 
                    selectorText={'Beneficiary Address'} 
                    title={item.symbol} 
                    description={item.name.length < 28
                      ? `${item.name}`
                      : `${item.name.substring(0, 25)}...`}
                    src={item.image} 
                    additional={tokenIdLink(item)}
                    // selectorOnChange={handleChangeAddress}
                    // isChecked={item.isChecked}
                    // isAlreadyUsed={item.isAlreadyUsed}
                  />
                </List.Item>
                {/* </div> */}
                {/* <div style={{padding: 5, border: "solid"}}> */}
                <div style={{width: 250}}>
                <AddressInput ensProvider={mainnetProvider} name='address' placeholder={`Beneficiary wallet address` } value={item.beneficiary} 
                  onChange={event => handleChangeAddress(event, item.address, item.tokenId)}/>
                </div>                  
              </div>
              </>
            )}
            bordered={false}
            pagination={{ 
              onChange: page => {
                scrollToTop();
              },
              pageSize: 16
            }}
          />
        </div>
      </div>
      }

        <div className="ant-div-carapace" style={{marginTop: 30}}>
          <div className="div-header">
            <Row>
              <h2>Trustees</h2>
              <AdditionalInfo info={
                NETWORK(selectedChainId)?.testnet
                ? <>
                    <p>Trustees are the account addresses that can trigger the vault's unlock. After successful execution the beneficiaries can access your assets.</p>
                    <p><b>Time Lock:</b> time period until you can revert the unlock process. Value must be between 1-365 (Seconds).
                      Note that on testnet the value is in seconds to reduce testing cycle time.</p>
                    <p>Fill at least one trustee. Addresses cannot be repeated for the same vault.</p>
                  </>
                : <>
                    <p>Trustees are the account addresses that can trigger the vault's unlock. After successful execution the beneficiaries can access your assets.</p>
                    <p><b>Time Lock:</b> time period until you can revert the unlock process. Value must be between 1-365 (Days).</p>
                    <p>Fill at least one trustee. Addresses cannot be repeated for the same vault.</p>
                  </>
              }/>
            </Row>
            <Row style={{marginTop: 20}}>
              <Col flex='none'>
                { infoModel === 2 &&
                  <Row style={{marginBottom: 20, marginRight:60}}>
                    <Col>
                      <Tooltip title="Trustee incentive" placement="top">
                        <Avatar shape="square" size="large" style={{marginRight: 10}} icon={<SketchOutlined />} />
                      </Tooltip>
                    </Col>
                    <Col>
                      <InputNumber
                        value={trusteeIncentive}
                        min={0} 
                        max={100} 
                        addonAfter="%"
                        // formatter={value => `${value} %`} 
                        // parser={value => value.replace('%', '')}
                        style={{ marginTop: 5, width: 100}}
                        onChange={selectedTrusteeIncentive}
                        //  onChange={event => handleTrusteeIncentive(index, event)}
                      />
                    </Col>
                  </Row>
                }
                <Row style={{marginBottom: 50, marginRight:20}}>
                  <Col>
                    <Tooltip title="Time Lock" placement="top">
                      <Avatar shape="square" size="large" style={{marginRight: 10}} icon={<LockOutlined />} />
                    </Tooltip>
                  </Col>
                  <Col>
                    <InputNumber
                      value={lockDays}
                      min={1}
                      max={365}
                      // formatter={value => `${value} days`} 
                      // parser={value => value.replace('days', '')}
                      addonAfter={
                        NETWORK(selectedChainId)?.testnet
                        ? "s"
                        : "D"
                      }
                      style={{ marginTop: 5, width: 100}}
                      onChange={selectedLockDays}
                    />
                  </Col>
                </Row>
              
            </Col>
            <Col flex='auto' style={{ marginTop: 5, marginLeft:20 }}>
              <DynamicForm actor='Trustee' stateValues={trustees} selectedActors={selectedTrustees} mainnetProvider={mainnetProvider}  />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}