import React from "react";
import { Card , Typography, Row, Col, Tooltip } from "antd";
import "../App.css";
import { SafetyCertificateOutlined, UnlockOutlined, StopOutlined, ExportOutlined } from '@ant-design/icons';
import { lime, gold, magenta, purple } from '@ant-design/colors';

const { Text } = Typography;

export default function ManageSmartVaultCard(
  { vaultId, owner, status, image }) {

  const vaultStatus = ['Active', 'Waiting unlock', 'Canceled', 'Executed'];

  const checkStatus = () => {
    if (vaultStatus[status] == 'Active') {
      return lime[5];
    };
    if (vaultStatus[status] == 'Waiting unlock') {
      return gold[5];
    };
    if (vaultStatus[status] == 'Canceled') {
      return magenta[5];
    };
    if (vaultStatus[status] == 'Executed') {
      return purple[5];
    };
  }

  const iconStatus = () => {
    if (vaultStatus[status] == 'Active') {
      return <Tooltip title={vaultStatus[status]} placement="right"><SafetyCertificateOutlined style={{ marginTop: 8, fontSize: '30px', color: lime[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Waiting unlock') {
      return <Tooltip title={vaultStatus[status]} placement="right"><UnlockOutlined style={{ marginTop: 8, fontSize: '30px', color: gold[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Canceled') {
      return <Tooltip title={vaultStatus[status]} placement="right"><StopOutlined style={{ marginTop: 8, fontSize: '30px', color: magenta[5] }} /></Tooltip>
    };
    if (vaultStatus[status] == 'Executed') {
      return <Tooltip title={vaultStatus[status]} placement="right"><ExportOutlined style={{ marginTop: 8, fontSize: '30px', color: purple[5] }} /></Tooltip>
    };
  };

  return (
    <div style={{ width: 300, boxShadow: `1px 1px 20px 2px ${checkStatus()}` }}>
      <Row style={{display: "flex", alignItems: 'center', padding:20}}>
        <Col flex={3} style={{ display: "flex", justifyContent:'flex-start', marginLeft:10}}>
          <Text type='secondary'>Smart Vault # {vaultId}</Text>
        </Col>
        <Col flex={2} style={{ display: "flex", justifyContent:'flex-end', marginRight:10}} >
          {iconStatus()}
        </Col>
      </Row>
      <div style={{ overflow: "hidden", height: 300, width: 300 }}>
        {image}
      </div>
      {owner &&
        <div style={{ marginLeft: 24, width: 300, height: 80, marginTop: 10, marginBottom: 10 }}>
          <span style={{ verticalAlign: "middle"}}>
            <Row>
              <Text type='secondary' style={{marginBottom: 10 }}>Owner</Text>
            </Row>
            {owner}
          </span>
        </div>
      }
    </div>
  );
}
