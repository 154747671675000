import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Input, Avatar, Typography, Tooltip, Space, Radio, InputNumber} from "antd";
import { BankOutlined, SketchOutlined } from '@ant-design/icons';
import { utils } from "ethers";
import "../App.css";
import { Web3Context } from "../context/web3Details";
import { useBalance } from "eth-hooks";
import { AdditionalInfo } from ".";
import { NETWORK } from "../constants";

const { Text } = Typography;

export default function SubmitSmartVault(
  { selectedERC20, 
    selectedERC721, 
    beneficiaries, 
    trustees,
    trusteeIncentive,
    selectedTrusteeIncentive,
    lockDays,
    depositAmount, 
    setDepositAmount,
    setModel,
    model
  }) {

  const { localProvider, address, selectedChainId } = useContext(Web3Context);
  const localBalance = useBalance(localProvider, address);
  
  const modelOptions = [
    {
      label: 'One Time Pay',
      value: 1,
    },
    {
      label: 'Deposit & Earn',
      value: 2,
      disabled: !NETWORK(selectedChainId).stakingModel //Only available for chains where the Uniswap LPs are balanced 
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setDepositAmount(NETWORK(selectedChainId).defaultPay.toString());
  }, []);

  const handleImgError = () => {
    return true;
  };

  const selectModel = ({ target: { value } }) => {
    if (value === 1) {
      setDepositAmount(NETWORK(selectedChainId).defaultPay.toString());
    } else {
      setDepositAmount('');
    }
    setModel(value);
  };

  return (
    
    <> 
      <div style={{marginTop: 30}}> 
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={6}>
            <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
              <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                  <h2>Beneficiaries</h2>
              </Row>
              <Row style={{display:'flex', justifyContent:'center'}}> 
                  <h2>{beneficiaries.length}</h2>
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
              <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                  <h2>Trustees</h2>
              </Row>
              <Row style={{display:'flex', justifyContent:'center'}}>
                  <h2>{trustees.length}</h2>
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
              <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                  <h2>Cryptocurrency</h2>
              </Row>
              <Row style={{display:'flex', justifyContent:'center'}}> 
                { selectedERC20.length !== 0 ?
                  <>
                    <Avatar.Group      
                      maxCount={3}
                      maxPopoverTrigger="click"
                      maxStyle={{
                        color: '#f56a00',
                        backgroundColor: '#fde3cf',
                        cursor: 'pointer',
                      }}
                    >
                      {selectedERC20.map((item, index) => (
                      <Tooltip key={index} title={item.symbol} placement="top">
                        <Avatar src={item.logo}/>
                      </Tooltip>
                      ))}
                    </Avatar.Group>
                  </> : <Text type="secondary">No cryptocurrency selected</Text>
                }
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <div className="ant-div-carapace" style={{height:160, marginBottom: 20}}>
              <Row style={{marginBottom: 15, display:'flex', justifyContent:'center'}}>
                  <h2>NFT</h2>
              </Row>
              <Row style={{display:'flex', justifyContent:'center'}}>
                { selectedERC721.length !== 0 ?
                  <>
                    <Avatar.Group    
                      maxCount={3}
                      maxPopoverTrigger="click"
                      maxStyle={{
                        color: '#ffffff',
                        backgroundColor: '#bfbfbf',
                        cursor: 'pointer',
                      }} 
                    >
                      {selectedERC721.map((subItem) => <Avatar key={subItem.address} src={subItem.image} shape="square" onError={handleImgError}/>)}
                    </Avatar.Group>
                  </> : <Text type="secondary">No NFT selected</Text>
                }
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      
      <div> 
        <Row>
          <Col span={24} >
            <div className="ant-div-carapace">
              <div className="div-header">
                <Row>
                  <Avatar shape="square" size="large" style={{marginRight: 10}} icon={<BankOutlined />} />
                  <h2>Processing Fee</h2>
                  <AdditionalInfo info={
                    NETWORK(selectedChainId)?.stakingModel
                    ? <>
                        <p>Choose which model fits you better: "One Time Payment" or "Deposit & Earn"</p>
                        <p><b>One Time Payment:</b> you pay a fixed amount to create the vault and access all features.</p>
                        <p><b>Deposit & Earn:</b> you deposit a minimum value to use the service. This is the only value that will be locked, but you can always withdraw the deposit.
                          This amount will generate a recurring yield that will be rewarded to you.</p>
                        <p>The deposited amount is also one way to protect your network's native token.</p>
                        <p><b>Incentive:</b> share of the deposit value that trustee, who triggers the rules, will receive after a successful execution. Value must be between 0-100%.</p>
                      </>
                    : <>
                        <p>Choose which model fits you better: "One Time Payment" or "Deposit & Earn" <i>(coming soon for the current selected network)</i></p>
                        <p><b>One Time Payment:</b> you pay a fixed amount to create the vault and access all features.</p>
                        <p><b>Deposit & Earn:</b> you deposit a minimum value to use the service. This is the only value that will be locked, but you can always withdraw the deposit.
                          This amount will generate a recurring yield that will be rewarded to you.</p>
                        <p>The deposited amount is also one way to protect your network's native token.</p>
                        <p><b>Incentive:</b> share of the deposit value that trustee, who triggers the rules, will receive after a successful execution. Value must be between 0-100%.</p>
                      </>
                  }/>
                </Row>
                <Row style={{marginTop:30}}>
                  <Space>
                    <Text type="secondary" style={{marginTop: 10}}>Available in wallet:</Text>
                    <Text type="primary" style={{marginTop: 10}}>{parseFloat(utils.formatEther(localBalance,  {commify: true})).toFixed(6)} {NETWORK(selectedChainId)?.symbol}</Text>
                  </Space>
                </Row>
              </div>
              <Row style={{display:'flex', justifyContent:'center', marginTop:20}}>
                <Radio.Group 
                  options={modelOptions}
                  optionType="button"
                  defaultValue={1}
                  buttonStyle="solid" 
                  onChange={selectModel} 
                  value={model}>
                </Radio.Group>
              </Row>

              { model === 2 ?
              <Row style={{marginTop: 50}}>
                <Col flex='auto' style={{display:'flex', justifyContent:'center'}}>
                  <Row >
                    <Col>
                      <Tooltip title="Trustee incentive" placement="top">
                        <Avatar shape="square" size="large" style={{marginRight: 10}} icon={<SketchOutlined />} />
                      </Tooltip>
                    </Col>
                    <Col>
                      <InputNumber
                        value={trusteeIncentive}
                        min={0} 
                        max={100} 
                        addonAfter="%"
                        style={{ marginTop: 5, width: 100}}
                        onChange={selectedTrusteeIncentive}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col flex='auto'>
                <Row style={{marginTop: 5}}>
                  <Space>
                    <Input 
                      addonAfter={NETWORK(selectedChainId)?.symbol}                   
                      placeholder='Amount' 
                      value={depositAmount}
                      onChange={event => setDepositAmount(event.target.value)}
                      />
                      <Button 
                            type='primary' 
                            ghost
                            onClick={() => setDepositAmount(NETWORK(selectedChainId).minDepositAmount.toString())}
                      >
                        MIN
                      </Button>
                      <Button 
                        type='danger' 
                        ghost
                        onClick={() => setDepositAmount(utils.formatEther(localBalance) > NETWORK(selectedChainId).maxDepositAmount ? NETWORK(selectedChainId).maxDepositAmount.toString() : utils.formatEther(localBalance))}
                      >
                        MAX
                      </Button>
                  </Space>
                </Row>
                </Col>
              </Row>
              : <>
                  <Row style={{display:'flex', justifyContent:'center', marginTop: 20}}>
                    <Text>Currently there is no minimum fee required to mint the Smart Vault NFT</Text>
                  </Row>
                  <Row style={{display:'flex', justifyContent:'center', marginTop: 10}}>
                    <Text>However you are free to donate and support the team's work effort</Text>
                  </Row>
                  <Row>
                  <Col flex='auto' >
                    <Input
                      style={{width:'200px',  marginTop: 20}}
                      addonAfter={NETWORK(selectedChainId)?.symbol}                   
                      placeholder='Amount' 
                      value={depositAmount}
                      onChange={event => setDepositAmount(event.target.value)}
                      />
                      </Col>
                  </Row>
                </>
            }
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}